import React, { useEffect, useState } from 'react';
import LawsuitsView from './LawsuitsView';
import { connect } from 'react-redux';
import {
  deleteDebt,
  addDebt,
  addDebtWithDocuments,
  getDebtsWithDebtor,
  filterDebts,
} from '../../redux/modules/debts';
import { getLawsuits, addLawsuit } from '../../redux/modules/lawsuits';
import { getBailiffs } from '../../redux/modules/user';

const LawsuitsContainer = ({
  debts,
  deleteDebt,
  addDebt,
  addDebtWithDocuments,
  getDebtsWithDebtor,
  filterDebts,
  currentDebt,
  debtors,
  paginationMetadata,
  businessPartners,
  debtWallets,
  bailiffs,
  getBailiffs,
  lawsuits,
  getLawsuits,
  addLawsuit,
}) => {
  const [isAddLawsuitModal, setAddLawsuitModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
  
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
  
    return debouncedValue;
  }

  const [debtInputValue,setDebtInputValue] = useState('')
  const debouncedInputValue = useDebounce(debtInputValue, 200);

  useEffect(
    () => {
      getDebtsWithDebtor(1, 20, debouncedInputValue)
    },
    [debouncedInputValue] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (debts?.length === 0) {
      getDebtsWithDebtor(page + 1, 20, searchTerm);
    }
    getLawsuits(page + 1, rowsPerPage, searchTerm);
    getBailiffs();
  }, []);

  useEffect(() => {
    if (paginationMetadata?.totalCount) {
      setCount(paginationMetadata.totalCount);
    }
  }, [paginationMetadata]);
  
  useEffect(() => {
    if (!debts || debts?.length === 0) {
      setPage(0);
      if (paginationMetadata?.totalCount) {
        setCount(paginationMetadata.totalCount);
      } else {
        setCount(0);
      }
    }
  }, [debts]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (searchTermInput) => {
    setSearchTerm(searchTermInput);
  };

  const shapeRequestFormData = (values) => {
    let debtWithDocumentsFormData = new FormData();
    const debtDocumentFiles = values?.debtDocuments?.map(
      (debtDocument) => debtDocument.debtDocumentFile,
    );
    debtDocumentFiles.forEach((debtDocumentFile) =>
      debtWithDocumentsFormData.append('debt-documents', debtDocumentFile),
    );
    const debt = values;
    debt.debtDocuments.forEach(
      (debtDocument) => delete debtDocument.debtDocumentFile,
    );
    debtWithDocumentsFormData.append('debt', JSON.stringify(debt));
    return debtWithDocumentsFormData;
  };

  return (
    <LawsuitsView
      debts={debts}
      lawsuits={lawsuits}
      deleteDebt={deleteDebt}
      addDebt={addDebt}
      addDebtWithDocuments={addDebtWithDocuments}
      isAddLawsuitModal={isAddLawsuitModal}
      setAddLawsuitModal={setAddLawsuitModal}
      filterDebts={filterDebts}
      debtors={debtors}
      handleSearchInputChange={handleSearchInputChange}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      count={count}
      rowsPerPage={rowsPerPage}
      shapeRequestFormData={shapeRequestFormData}
      businessPartners={businessPartners}
      debtWallets={debtWallets}
      bailiffs={bailiffs}
      addLawsuit={addLawsuit}
      setDebtInputValue={setDebtInputValue}
    />
  );
};

const mapStateToProps = (state) => ({
  debts: state.debts.data,
  lawsuits: state.lawsuits.data,
  paginationMetadata: state.debts.paginationMetadata,
  debtors: state.debtors.data,
  businessPartners: state.businessPartners.data,
  debtWallets: state.debtWallets.data,
  bailiffs: state.user.bailiffs,
});

const mapDispatchToProps = (dispatch) => ({
  deleteDebt: (debtID) => dispatch(deleteDebt(debtID)),
  addDebt: (debtBody) => dispatch(addDebt(debtBody)),
  addDebtWithDocuments: (debtWithDocumentsFormData) =>
    dispatch(addDebtWithDocuments(debtWithDocumentsFormData)),
  getDebtsWithDebtor: (pageNumber, pageSize, searchTerm) =>
    dispatch(getDebtsWithDebtor(pageNumber, pageSize, searchTerm)),
  filterDebts: (search) => dispatch(filterDebts(search)),
  getBailiffs: () => dispatch(getBailiffs()),
  getLawsuits: (pageNumber, pageSize, searchTerm) =>
    dispatch(getLawsuits(pageNumber, pageSize, searchTerm)),
  addLawsuit: (lawsuitBody) => dispatch(addLawsuit(lawsuitBody))
});

export default connect(mapStateToProps, mapDispatchToProps)(LawsuitsContainer);
