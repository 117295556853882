import { pow, multiply } from 'mathjs';
import moment from 'moment';
import {DEBT_TYPES, DEBT_TYPES_TEXTS} from './const'

export const countInterest = (principal, years, interestRate) => {
  return multiply(pow(interestRate, years), principal).toFixed(2);
};

export const countBalanceSum = (balance) => {
  let sum = 0;
  balance
    .filter((event) => event.valueSign === '-')
    .forEach((debt) => {
      if (debt.startedRepayment) {
        sum -= countInterest(
          debt.principalLeft + debt.interestsLeft,
          moment()
            .startOf('day')
            .diff(debt?.lastPaymentDate?.slice(0, 10), 'years', true),
          debt.interestRate,
        );
      } else {
        sum -= countInterest(
          debt.principal,
          moment()
            .startOf('day')
            .diff(debt.eventDate.slice(0, 10), 'years', true),
          debt.interestRate,
        );
      }
    });
  return sum.toFixed(2);
};

export const countSumOfInterests = (balance) => {
  let principalSum = 0;
  balance.forEach((balanceEvent) => {
    if (balanceEvent.valueSign === '+') {
      principalSum += Number(balanceEvent.value);
    } else if (balanceEvent.valueSign === '-') {
      principalSum -= balanceEvent.principal;
    }
  });
  const difference = Number(countBalanceSum(balance)) - principalSum;
  return difference.toFixed(2);
};

export const debtTypeDictionary = (type) => {
  switch(type){ 
    case DEBT_TYPES.NONE:
      return DEBT_TYPES_TEXTS.NONE
    case DEBT_TYPES.TELECOM:
      return DEBT_TYPES_TEXTS.TELECOM
    case DEBT_TYPES.RATE_AGREEMENT:
      return DEBT_TYPES_TEXTS.RATE_AGREEMENT
    case DEBT_TYPES_TEXTS.CREDIT_AGREEMENT:
      return DEBT_TYPES_TEXTS.CREDIT_AGREEMENT
    case DEBT_TYPES.SENTENCE:
      return DEBT_TYPES_TEXTS.SENTENCE
    case DEBT_TYPES.PROCESS_COST:
      return DEBT_TYPES_TEXTS.PROCESS_COST
    case DEBT_TYPES.BAILIFF_COST:
      return DEBT_TYPES_TEXTS.BAILIFF_COST
    default:
        return type;
  }
}