import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TablePagination from '@material-ui/core/TablePagination';
import AddPaymentModal from './modals/AddPaymentModal';
import EditPaymentModal from './modals/EditPaymentModal';
import { Link } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const PaymentsPageView = ({
  isAddPaymentModal,
  setAddPaymentModal,
  isEditPaymentModal,
  setEditPaymentModal,
  payments,
  addPayment,
  setCurrentPayment,
  currentPayment,
  editPayment,
  debts,
  useDebounce,
  getDebtsWithDebtor,
  debtInputValue,
  setDebtInputValue,
  handleSearchInputChange,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  count,
}) => {
  return (
    <>
      <AddPaymentModal
        isAddPaymentModal={isAddPaymentModal}
        setAddPaymentModal={setAddPaymentModal}
        addPayment={addPayment}
        debts={debts}
        useDebounce={useDebounce}
        getDebtsWithDebtor={getDebtsWithDebtor}
        setDebtInputValue={setDebtInputValue}
      />
      <EditPaymentModal
        isEditPaymentModal={isEditPaymentModal}
        setEditPaymentModal={setEditPaymentModal}
        editPayment={editPayment}
        currentPayment={currentPayment}
        debts={debts}
        useDebounce={useDebounce}
        getDebtsWithDebtor={getDebtsWithDebtor}
        setDebtInputValue={setDebtInputValue}
      />
      <DebtorsDbContainer>
        <FiltersContainer>
          <TextField
            id="outlined-basic"
            label="Wyszukaj"
            onChange={(event) => handleSearchInputChange(event.target.value)}
          />
        </FiltersContainer>
        <DebtorsList>
          <PageSubTitle>Baza Płatności</PageSubTitle>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <HeadTableCell>Wartość</HeadTableCell>
                  <HeadTableCell align="right">Konto Bankowe</HeadTableCell>
                  <HeadTableCell align="right">Data</HeadTableCell>
                  <HeadTableCell align="right">Opis</HeadTableCell>
                  <HeadTableCell align="right">Przypisany Dług</HeadTableCell>
                  <HeadTableCell align="right">
                    <AddDebtBtn
                      startIcon={<AddIcon />}
                      onClick={() => setAddPaymentModal(true)}
                    >
                      Dodaj
                    </AddDebtBtn>
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.length > 0 &&
                  payments?.map((payment) => {
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {`${payment.value} ${payment.currency}`}
                        </TableCell>
                        <TableCell align="right">
                          {payment.accountNumber}
                        </TableCell>
                        <TableCell align="right">
                          {payment.date.slice(0, 10)}
                        </TableCell>
                        <TableCell align="right">
                          {payment.description}
                        </TableCell>
                        <TableCell align="right">
                          {payment?.debt
                            ? `${payment?.debt?.debtor?.firstName} ${payment?.debt?.debtor?.lastName}, ${payment?.debt?.debtor?.pesel}, ${payment?.debt?.description}`
                            : 'Brak przypisanego długu'}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="link"
                            component={Link}
                            to={`/payments/${payment?._id}`}
                          >
                            <ArrowForwardIosRoundedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </DebtorsList>
      </DebtorsDbContainer>
    </>
  );
};

export default PaymentsPageView;

const AddDebtBtn = styled(Button)`
  /* position: absolute;
    right: 20px;
    top: 20px; */
  /* margin-top: 10px; */
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;

const HeadTableCell = styled(TableCell)`
  font-weight: 600;
`;
const PageSubTitle = styled.h2`
  margin: 0;
  margin-bottom: 20px;
`;

const DebtorsDbContainer = styled(Paper)`
  width: calc(100vw - 105px);
  height: calc(100vh - 40px);
  margin: 20px auto;
  margin-left: 85px;
`;

const FiltersContainer = styled.div`
  padding: 20px;
`;

const DebtorsList = styled.div`
  padding: 20px;
  position: relative;
`;

const StyledEditIcon = styled(EditOutlinedIcon)`
  color: #4c5bac;
`;
