import React from 'react';
import Modal from '../../../shared/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SearchSelect from '../../../shared/Select';

const EditDebtModal = ({
  isEditDebtModal,
  setEditDebtModal,
  debtWallets,
  currentWallet,
  selectedDebts,
  editDebts,
}) => {
  const initialValues = {
    wallet: '',
  };

  const EditDebtModal = Yup.object().shape({
    wallet: Yup.string().required('Portfel jest wymagany'),
  });

  return (
    <Modal
      title="Edycja długu"
      show={isEditDebtModal}
      exitButton={true}
      onCancel={() => {
        setEditDebtModal(false);
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={EditDebtModal}
        onSubmit={async (values) => {
          await editDebts(selectedDebts, values.wallet);
          setEditDebtModal(false);
        }}
      >
        {({ errors, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {`Zaznaczone długi: ${selectedDebts.length}`}
            <NewDocumentSelect
              label="Portfel"
              name="wallet"
              placeholder="Wybierz portfel..."
              options={debtWallets?.map((wallet) => {
                return {
                  label: `${wallet.name}`,
                  value: wallet._id,
                };
              })}
              onChange={({ value }) => setFieldValue('wallet', value)}
              defaultValue={{
                label: currentWallet.name,
                value: currentWallet._id,
              }}
              noOptionsMessage={() => 'Nie ma takiego portfela'}
              error={errors.wallet}
            />

            <CenterBox>
              <Button type="submit" variant="primary">
                Potwierdź
              </Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditDebtModal;

const Form = styled.form`
  padding-top: 20px;
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const NewDocumentSelect = styled(SearchSelect)`
  width: 100%;
  margin-right: 10px;
`;
