import axios from "axios";

const BUSINESS_PARTNER_REQUEST = "BUSINESS_PARTNER_REQUEST";
const BUSINESS_PARTNER_FAIL = "BUSINESS_PARTNER_FAIL";
const GET_BUSINESS_PARTNERS_SUCCESS = "GET_BUSINESS_PARTNERS_SUCCESS";
const GET_BUSINESS_PARTNERS_WITH_PAGINATION_SUCCESS = "GET_BUSINESS_PARTNERS_WITH_PAGINATION_SUCCESS";
const ADD_BUSINESS_PARTNER_SUCCESS = "ADD_BUSINESS_PARTNER_SUCCESS";
const SET_CURRENT_BUSINESS_PARTNER = "SET_CURRENT_BUSINESS_PARTNER";
const EDIT_BUSINESS_PARTNER_SUCCESS = "EDIT_BUSINESS_PARTNER_SUCCESS";
const GET_BUSINESS_PARTNER_SUCCESS = "GET_BUSINESS_PARTNER_SUCCESS";

const businessPartnerRequest = () => ({
    type: BUSINESS_PARTNER_REQUEST,
});

const businessPartnerFail = (err) => ({
    type: BUSINESS_PARTNER_FAIL,
    payload: err,
});

export const getBusinessPartners = () => async (dispatch) => {
    dispatch(businessPartnerRequest());
    try {
        const response = await axios.get(`/business-partners`);
        dispatch(getBusinessPartnersSuccess(response.data));
    } catch (err) {
        dispatch(businessPartnerFail(err));
    }
};

export const getBusinessPartnerById = (id) => async (dispatch) => {
    dispatch(businessPartnerRequest());
    try {
        const response = await axios.get(`/business-partners/${id}`);
        dispatch(getBusinessPartnerSuccess(response.data));
    } catch (err) {
        dispatch(businessPartnerFail(err));
    }
};

const getBusinessPartnerSuccess = (businessPartner) => ({
    type: GET_BUSINESS_PARTNER_SUCCESS,
    payload: businessPartner
})

export const getBusinessPartnersWithPagination = (pageNumber, pageSize, searchTerm = "") => async (dispatch) => {
    dispatch(businessPartnerRequest());
    try {
        const response = await axios.get(
            `/business-partners?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}`
        );
        let paginationMetadata;
        if (response?.headers?.pagination) {
            paginationMetadata = JSON.parse(response.headers.pagination);
        }
        dispatch(getBusinessPartnersWithPaginationSuccess(response.data, paginationMetadata));
    } catch (err) {
        dispatch(businessPartnerFail(err));
    }
};

const getBusinessPartnersSuccess = (businessPartners) => ({
    type: GET_BUSINESS_PARTNERS_SUCCESS,
    payload: businessPartners,
});

const getBusinessPartnersWithPaginationSuccess = (businessPartners, paginationMetadata) => ({
    type: GET_BUSINESS_PARTNERS_WITH_PAGINATION_SUCCESS,
    payload: businessPartners,
    paginationMetadata: paginationMetadata
})

export const addBusinessPartner = (businessPartner) => async (dispatch) => {
    dispatch(businessPartnerRequest());
    try {
        const response = await axios.post(
            `/business-partners`,
            businessPartner
        );
        dispatch(addBusinessPartnerSuccess(response.data));
    } catch (err) {
        dispatch(businessPartnerFail(err));
    }
};

const addBusinessPartnerSuccess = (businessPartner) => ({
    type: ADD_BUSINESS_PARTNER_SUCCESS,
    payload: businessPartner,
});

export const setCurrentBusinessPartner = (businessPartnerId) => ({
    type: SET_CURRENT_BUSINESS_PARTNER,
    payload: businessPartnerId
})

export const editBusinessPartner = (businessPartnerBody, businessPartnerID) => async (dispatch) => {
    dispatch(businessPartnerRequest());
    try {
        const response = await axios.patch(
            `/business-partners/${businessPartnerID}`,
            businessPartnerBody
        );
        dispatch(editBusinessPartnerSuccess(response.data));
    } catch (err) {
        dispatch(businessPartnerFail(err));
    }
};

const editBusinessPartnerSuccess = (businessPartner) => ({
    type: EDIT_BUSINESS_PARTNER_SUCCESS,
    payload: businessPartner,
});

const initialState = {
    data: [],
    loading: false,
    error: null,
    current: null,
    paginationMetadata: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BUSINESS_PARTNER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BUSINESS_PARTNER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_BUSINESS_PARTNERS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case GET_BUSINESS_PARTNERS_WITH_PAGINATION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                paginationMetadata: action.paginationMetadata,
                loading: false,
            };
        case ADD_BUSINESS_PARTNER_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
                loading: false,
            }
        case SET_CURRENT_BUSINESS_PARTNER:
            return {
                ...state,
                current: state.data.find((businessPartner) => businessPartner._id === action.payload),
            };
        case EDIT_BUSINESS_PARTNER_SUCCESS:
            return {
                ...state,
                data: state.data.map((businessPartner) =>
                    businessPartner._id === action.payload._id ? action.payload : businessPartner
                ),
                loading: false,
            }
        case GET_BUSINESS_PARTNER_SUCCESS:
            return {
                ...state,
                current: action.payload
            }
        default:
            return state;
    }
};