import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

const style = {
  border: '1px dashed gray',
  height: '100%',
  width: '100%',
  color: 'white',
  padding: '1px',
  fontSize: '13px',
};

export const DropBox = ({
  accept,
  lastDroppedItem,
  onDrop,
  lastDroppedItems,
  index,
  setLastDroppedItems,
}) => {
  const [{ isOver, canDrop, dragItem }, drop] = useDrop({
    accept,
    drop: (item) => {
      if (typeof lastDroppedItems[index] === 'undefined') {
        onDrop(item);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      dragItem: monitor.getItem(),
    }),
  });

  const [highlight, setHighlight] = useState('');
  const [dropError, setDropError] = useState(null);

  useEffect(() => {
    if (lastDroppedItems[index] !== undefined) {
    }
    if (highlight !== '') {
      setHighlight('');
      setDropError(null);
    }
  }, [canDrop, isOver, dragItem]);

  const isActive = isOver && canDrop;

  let backgroundColor = 'white';

  useEffect(() => {
    if (isActive && typeof lastDroppedItems[index] === 'undefined') {
      setHighlight('#9fffab');
    } else if (canDrop && typeof lastDroppedItems[index] === 'undefined') {
      setHighlight('#b9ddff');
    } else if (canDrop && typeof lastDroppedItems[index] !== 'undefined') {
      setHighlight('#ff868a');
    }
  }, [isActive, canDrop, dragItem, lastDroppedItems]);

  return (
    <>
      <BoxContainer ref={drop} style={{ ...style, background: highlight }}>
        {typeof lastDroppedItem !== 'undefined' && (
          <FlexIconButton
            size={'small'}
            onClick={(event) => {
              let bufferArray = [...lastDroppedItems];
              bufferArray[index] = undefined;
              setLastDroppedItems(bufferArray);
            }}
          >
            <ClearOutlinedIcon />
          </FlexIconButton>
        )}
        {canDrop && typeof lastDroppedItems[index] === 'undefined' && (
          <DropInfo>{'Upuść element'}</DropInfo>
        )}
        {lastDroppedItem && <p>{lastDroppedItem}</p>}
      </BoxContainer>
    </>
  );
};

const BoxContainer = styled.div`
  min-height: 72px;
  min-width: 110px;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  border: 2px dashed #d9d9d9;
  border-radius: 5px;
`;

const FlexIconButton = styled(IconButton)`
  align-self: flex-end;
  padding: 0px;
`;

const DropInfo = styled.div`
  margin-top: 24px;
`;
