import React, { useEffect, useState } from 'react';
import SingleDebtView from './SingleDebtView';
import {
  getDebtById,
  editDebt,
  addDebtDocumentsToDebt,
} from '../../redux/modules/debts';
import { getBusinessPartners } from '../../redux/modules/businessPartners';
import { getDebtWallets } from '../../redux/modules/debtWallets';
import { getDebtors } from '../../redux/modules/debtors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const SingleDebtContainer = ({
  currentDebt,
  getDebtById,
  editDebt,
  debtors,
  businessPartners,
  debtWallets,
  getDebtors,
  getDebtWallets,
  getBusinessPartners,
  addDebtDocumentsToDebt,
  match: {
    params: { id },
  },
}) => {
  const [isEditDebtModal, setEditDebtModal] = useState(false);
  useEffect(() => {
    getDebtById(id);
    getDebtors();
    getDebtWallets();
    getBusinessPartners();
  }, []);

  const checkIfImage = (filename) => {
    return /\.(gif|jpe?g|tiff?|png|webp|bmp)$/gi.test(filename);
  };

  const checkIfPdf = (filename) => {
    return /\.(pdf)$/gi.test(filename);
  };

  return (
    <SingleDebtView
      currentDebt={currentDebt}
      isEditDebtModal={isEditDebtModal}
      setEditDebtModal={setEditDebtModal}
      editDebt={editDebt}
      getDebtById={getDebtById}
      checkIfImage={checkIfImage}
      checkIfPdf={checkIfPdf}
      debtors={debtors}
      businessPartners={businessPartners}
      debtWallets={debtWallets}
      addDebtDocumentsToDebt={addDebtDocumentsToDebt}
    />
  );
};

const mapStateToProps = (state) => ({
  currentDebt: state.debts.current,
  debtors: state.debtors.data,
  businessPartners: state.businessPartners.data,
  debtWallets: state.debtWallets.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    editDebt: (debtBody, debtID) => dispatch(editDebt(debtBody, debtID)),
    getDebtById: (debtID) => dispatch(getDebtById(debtID)),
    getDebtors: () => dispatch(getDebtors()),
    getBusinessPartners: () => dispatch(getBusinessPartners()),
    getDebtWallets: () => dispatch(getDebtWallets()),
    addDebtDocumentsToDebt: (debtId, debtDocuments) =>
      dispatch(addDebtDocumentsToDebt(debtId, debtDocuments)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleDebtContainer),
);
