import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
::-moz-selection { background: #1890FF; }
::selection { background: #1890FF; }
::-webkit-scrollbar {
    background-color: #fff0;
    width: 12px;
}
::-webkit-scrollbar-button {
    display: none;
}
::-webkit-scrollbar-thumb {
    background-color: #dadce0;
    border-radius: 16px;
}
::-webkit-scrollbar-track {
    background-color: #fff0;
}
html {
    scroll-behavior: smooth;
}
body{
    background: white;
    margin: 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
h1,h2,h3,h4,h5 {
    font-family: "Lora", sans-serif;
    color: #484848;
}
input,textarea, button {
    font-family: "Open Sans",sans-serif;
}
button {
    outline: none;
    color: #fff;
}
a, label, div {
    font-family: "Open Sans", sans-serif;
}
span {
    font-family: "Open Sans", sans-serif;
    margin: 0;
}
p, li {
    font-family: "Open Sans", sans-serif;
    color: #484848;
    margin: 0;
}
.tox-notification {
    display: none;
}
.Toastify__toast--success {
    background-color: rgb(69,193,91);
}
.Toastify__toast--warning {
    background-color: #4C5BAC;
}
.Toastify__toast--error {
    background-color: rgb(228,82,121);
}

`;
export default GlobalStyle;