import React, { useEffect, useState } from 'react';
import AddDebtView from './AddDebtView';
import {
  addDebt,
  addDebtWithDocuments,
  setCurrentDebt,
  getDebtsByDebtorId
} from '../../redux/modules/debts';
import { getDebtors } from '../../redux/modules/debtors';
import { getDebtWallets } from '../../redux/modules/debtWallets';
import { getBusinessPartnersWithPagination } from '../../redux/modules/businessPartners';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const AddDebtContainer = ({
  addDebt,
  addDebtWithDocuments,
  debtors,
  getDebtors,
  getBusinessPartnersWithPagination,
  businessPartners,
  getDebtWallets,
  debtWallets,
  currentDebtor,
  currentDebt,
  history,
  setCurrentDebt,
  getDebtsByDebtorId,
  debts
}) => {
  const [debtReseted, setDebtReseted] = useState(false);
  const [debtorsSearchTerm, setDebtorsSearchTerm] = useState('');
  const [businessPartnersSearchTerm, setBusinessPartnersSearchTerm] = useState(
    '',
  );
  const [debtWalletsSearchTerm, setDebtWalletsSearchTerm] = useState('');
  const [debtSearchTerm,setDebtSearchTerm] = useState('');

  useEffect(() => {
    setCurrentDebt(null);
    getDebtors(1, 20, debtorsSearchTerm);
    getBusinessPartnersWithPagination(1, 20, businessPartnersSearchTerm);
    getDebtWallets(1, 20, debtWalletsSearchTerm);
    setDebtReseted(true);
  }, []);

  useEffect(() => {
    if (currentDebt && debtReseted) {
      history.push(`/debts/${currentDebt?._id}`);
    }
  }, [currentDebt]);

  useEffect(() => {
    getDebtors(1, 20, debtorsSearchTerm);
  }, [debtorsSearchTerm]);

  const handleDebtorsSearchInputChange = (searchTermInput) => {
    setDebtorsSearchTerm(searchTermInput);
  };

  useEffect(() => {
    getBusinessPartnersWithPagination(1, 20, businessPartnersSearchTerm);
  }, [businessPartnersSearchTerm]);

  const handleBusinessPartnersSearchInputChange = (searchTermInput) => {
    setBusinessPartnersSearchTerm(searchTermInput);
  };


  useEffect(() => {
    getDebtWallets(1, 20, debtWalletsSearchTerm);
  }, [debtWalletsSearchTerm]);

  const handleDebtWalletsSearchInputChange = (searchTermInput) => {
    setDebtWalletsSearchTerm(searchTermInput);
  };
  const handleDebtSearchInputChange = (searchTermInput) => {
    setDebtSearchTerm(searchTermInput);
  };

  const shapeRequestFormData = (values) => {
    let debtWithDocumentsFormData = new FormData();
    const debtDocumentFiles = values?.debtDocuments?.map(
      (debtDocument) => debtDocument.debtDocumentFile,
    );
    debtDocumentFiles.forEach((debtDocumentFile) =>
      debtWithDocumentsFormData.append('debt-documents', debtDocumentFile),
    );
    const debt = values;
    debt.debtDocuments.forEach(
      (debtDocument) => delete debtDocument.debtDocumentFile,
    );
    debtWithDocumentsFormData.append('debt', JSON.stringify(debt));
    return debtWithDocumentsFormData;
  };

  return (
    <AddDebtView
      addDebt={addDebt}
      addDebtWithDocuments={addDebtWithDocuments}
      debtors={debtors}
      businessPartners={businessPartners}
      debtWallets={debtWallets}
      currentDebtor={currentDebtor}
      handleDebtWalletsSearchInputChange={handleDebtWalletsSearchInputChange}
      handleBusinessPartnersSearchInputChange={
        handleBusinessPartnersSearchInputChange
      }
      handleDebtorsSearchInputChange={handleDebtorsSearchInputChange}
      shapeRequestFormData={shapeRequestFormData}
      getDebtsByDebtorId={getDebtsByDebtorId}
      debts={debts}
      handleDebtSearchInputChange={handleDebtSearchInputChange}
    />
  );
};

const mapStateToProps = (state) => ({
  debtors: state.debtors.data,
  businessPartners: state.businessPartners.data,
  debtWallets: state.debtWallets.data,
  currentDebtor: state.debtors.current,
  currentDebt: state.debts.current,
  debts: state.debts.data
});

const mapDispatchToProps = (dispatch) => ({
  addDebt: (debtBody) => dispatch(addDebt(debtBody)),

  setCurrentDebt: (debt) => dispatch(setCurrentDebt(debt)),
  getDebtors: (pageNumber, pageSize, searchTerm) =>
    dispatch(getDebtors(pageNumber, pageSize, searchTerm)),
  getDebtsByDebtorId:(debtorId) => dispatch(getDebtsByDebtorId(debtorId)),
  getBusinessPartnersWithPagination: (pageNumber, pageSize, searchTerm) =>
    dispatch(
      getBusinessPartnersWithPagination(pageNumber, pageSize, searchTerm),
    ),
  getDebtWallets: (pageNumber, pageSize, searchTerm) =>
    dispatch(getDebtWallets(pageNumber, pageSize, searchTerm)),
  addDebtWithDocuments: (debtWithDocumentsFormData) =>
    dispatch(addDebtWithDocuments(debtWithDocumentsFormData)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddDebtContainer),
);
