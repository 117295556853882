import React, { useState } from 'react';
import Modal from '../../../shared/Modal';
import { DndContainer } from '../dnd/DndContainer';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import getKeyByValue from '../../../utils/get-key-by-value';

const CombineColumnNamesModal = ({
  isImportReady,
  debtsForImport,
  setIsImportReady,
  selectedFile,
  importDebtsToDebtWallet,
  currentWallet,
  isCombineColumnNamesModal,
  setIsCombineColumnNamesModal,
  getDebtWalletDebts,
  page,
  rowsPerPage,
}) => {
  const [isDropboxesFilled, setIsDropboxesFilled] = useState(false);
  const [lastDroppedItems, setLastDroppedItems] = useState([]);
  const properBoxNames = {
    pesel: 'Pesel',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    principal: 'Kwota',
    date: 'Data Wierzytelności',
    description: 'Podstawa Wierzytelności',
  };

  const calculateColumnNameIndexes = (lastDroppedItems) => {
    const columnNameIndexes = [];
    lastDroppedItems.forEach((lastDroppedItem, index) => {
      if (lastDroppedItem) {
        columnNameIndexes.push({
          columnIndex: index,
          propertyName: getKeyByValue(properBoxNames, lastDroppedItem),
        });
      }
    });
    return columnNameIndexes;
  };

  return (
    <Modal
      title="Połącz nazwy z odpowiednimi kolumnami"
      show={isCombineColumnNamesModal}
      exitButton={true}
      onCancel={() => {
        setIsCombineColumnNamesModal(false);
        setLastDroppedItems([]);
      }}
    >
      <Wrapper>
        <DndContainer
          debtsForImport={debtsForImport}
          setIsDropboxesFilled={setIsDropboxesFilled}
          lastDroppedItems={lastDroppedItems}
          setLastDroppedItems={setLastDroppedItems}
          properBoxNames={properBoxNames}
        />
        <StyledButton
          variant="contained"
          color="primary"
          disabled={!isDropboxesFilled}
          onClick={async () => {
            if (isDropboxesFilled) {
              const formData = new FormData();
              const columnNameIndexes = calculateColumnNameIndexes(
                lastDroppedItems,
              );
              formData.append('debts', selectedFile);
              formData.append(
                'columnNameIndexes',
                JSON.stringify(columnNameIndexes),
              );
              await importDebtsToDebtWallet(formData, currentWallet._id);
              await getDebtWalletDebts(
                currentWallet._id,
                page + 1,
                rowsPerPage,
              );
              setIsCombineColumnNamesModal(false);
              setLastDroppedItems([]);
            }
          }}
        >
          ZAIMPORTUJ
        </StyledButton>
      </Wrapper>
    </Modal>
  );
};

export default CombineColumnNamesModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
`;
