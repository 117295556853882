import React from 'react';
import styled from 'styled-components';
import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from '../../shared/Select';
import Paper from '@material-ui/core/Paper';

const AddDocumentView = ({
  debtors,
  documentTypes,
  addDocument,
  currentDebtor,
  handleSearchInputChange,
  businessPartners,
  handleSearchBPInputChange,
  handleSearchCourtInputChange,
  courts,
}) => {
  const initialValues = {
    name: '',
    debtor: currentDebtor ? currentDebtor._id : '',
    documentType: '',
    legalCounsel: '',
    court: '',
  };

  const AddDebtorSchema = Yup.object().shape({
    name: Yup.string().required('Nazwa jest wymagana'),
    debtor: Yup.string().required('Dluznik jest wymagany'),
    legalCounsel: Yup.string().required('Radca prawny jest wymagany'),
    documentType: Yup.string().required('Szablon jest wymagany'),
    court: Yup.string().when('documentType', {
      is: (value) =>
        documentTypes.find((dt) => dt._id === value)?.isCourt === true,
      then: Yup.string().required('Sąd jest wymagany'),
    }),
  });

  return (
    <Wrapper>
      <Title>Tworzenie dokumentu</Title>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AddDebtorSchema}
        onSubmit={async (values) => {
          if (values.court === '') {
            delete values.court;
          }
          addDocument({
            ...values,
            content: documentTypes.find(
              (documentType) => documentType._id === values.documentType,
            ).content,
          });
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          setFieldError,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <PaperWrapper>
              <DocumentNameInput
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                error={errors.name}
                label="Nazwa"
              />
              <NewDocumentSelect
                label="Szablon dokumentu"
                defaultValue={values.documentType}
                name="documentType"
                placeholder="Wybierz szablon dokumentu..."
                options={documentTypes?.map((documentType) => {
                  return { label: documentType.name, value: documentType._id };
                })}
                onChange={({ value }) => setFieldValue('documentType', value)}
                noOptionsMessage={() => 'Nie ma takiego dokumentu'}
                error={errors.documentType}
              />
              <NewDocumentSelect
                label="Dłużnik"
                value={debtors
                  ?.filter((debtor) => debtor._id === values.debtor)
                  .map((debtor) => {
                    return {
                      label: `${debtor.firstName} ${debtor.lastName}, ${debtor.pesel}`,
                      value: debtor._id,
                    };
                  })}
                name="debtor"
                placeholder="Wybierz dłużnika..."
                options={debtors?.map((debtor) => {
                  return {
                    label: `${debtor.firstName} ${debtor.lastName}, ${debtor.pesel}`,
                    value: debtor._id,
                  };
                })}
                onChange={({ value }) => setFieldValue('debtor', value)}
                onInputChange={(value) => handleSearchInputChange(value)}
                noOptionsMessage={() => 'Nie ma takiego dłużnika'}
                error={errors.debtor}
              />
              <NewDocumentSelect
                label="Radca prawny"
                value={businessPartners
                  ?.filter((bp) => bp._id === values.legalCounsel)
                  .map((bp) => {
                    return {
                      label: `${bp.name}, ${
                        bp.PESEL !== '' ? bp.PESEL : bp.NIP
                      }`,
                      value: bp._id,
                    };
                  })}
                name="legalCounsel"
                placeholder="Wybierz radce prawnego..."
                options={businessPartners?.map((bp) => {
                  return {
                    label: `${bp.name}, ${bp.PESEL !== '' ? bp.PESEL : bp.NIP}`,
                    value: bp._id,
                  };
                })}
                onChange={({ value }) => setFieldValue('legalCounsel', value)}
                onInputChange={(value) => handleSearchBPInputChange(value)}
                noOptionsMessage={() => 'Nie ma takiego radcy'}
                error={errors.legalCounsel}
              />
              <NewDocumentSelect
                label="Sąd"
                value={courts
                  ?.filter((court) => court._id === values.court)
                  .map((court) => {
                    return { label: `${court.name}`, value: court._id };
                  })}
                name="court"
                placeholder="Wybierz sąd..."
                options={courts?.map((court) => {
                  return { label: `${court.name}`, value: court._id };
                })}
                onChange={({ value }) => setFieldValue('court', value)}
                onInputChange={(value) => handleSearchCourtInputChange(value)}
                noOptionsMessage={() => 'Nie ma takiego sądu'}
                error={errors.court}
                isHidden={
                  !documentTypes.find((dt) => dt._id === values.documentType)
                    ?.isCourt
                }
              />

              <CenterBox>
                <Button type="submit" variant="primary">
                  Potwierdź
                </Button>
              </CenterBox>
            </PaperWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default AddDocumentView;

const DocumentNameInput = styled(Input)`
  width: 500px;
  margin-bottom: 20px;
`;

const NewDocumentSelect = styled(Select)`
  width: 500px;
  ${({ isHidden }) =>
    isHidden &&
    `
  display: none;
  `}
`;

const Title = styled.h1`
  font-size: 24px;
`;

const Wrapper = styled.div`
  margin: 20px auto;
  height: calc(100vh - 40px);
`;

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const PaperWrapper = styled(Paper)`
  padding: 20px;
`;
