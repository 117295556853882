import React from "react";
import Modal from "../../../shared/Modal";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import Input from "@material-ui/core/TextField";
import * as Yup from "yup";
import moment from 'moment'

const AddDebtorAddress = ({
    isAddDebtorBalanceEvent,
    setAddDebtorBalanceEvent,
    addDebtorBalanceEvent,
    debtorId
}) => {


    const initialValues = {
        value: 0,
        currency: "PLN",
        eventDate: moment(new Date()).format("yyyy-MM-DD"),
        description: "",
    };

    const AddDebtorSchema = Yup.object().shape({
        value: Yup.number()
            .required("Wartość jest wymagana"),
        eventDate: Yup.string()
            .required("Data jest wymagana"),
        description: Yup.string()
            .required("Opis jest wymagany")
        });

    return (
        <Modal
            title="Dodawanie Wydarzenia Salda"
            show={isAddDebtorBalanceEvent}
            exitButton={true}
            onCancel={() => {
                setAddDebtorBalanceEvent(false);
            }}
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={AddDebtorSchema}
                onSubmit={values => {
                    addDebtorBalanceEvent(values,debtorId)
                    setAddDebtorBalanceEvent(false);
                }}
            >
                {({
                    values,
                    errors,
                    setFieldValue,
                    setFieldError,
                    handleChange,
                    handleBlur,
                    handleSubmit
                }) => (
                        <Form onSubmit={handleSubmit}>
                            <Input
                                    name="value"
                                    type="number"
                                    onChange={handleChange}
                                    value={values.value}
                                    error={errors.value}
                                    label="Wartość"
                                />
                            <Input
                                    name="eventDate"
                                    type="date"
                                    onChange={handleChange}
                                    value={values.eventDate}
                                    error={errors.eventDate}
                                    label="Data"
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                                />
                            <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                value={values.description}
                                error={errors.description}
                                label="Opis"
                            />
                            <CenterBox>
                                <Button type="submit" variant="primary">
                                    Potwierdź
              </Button>
                            </CenterBox>
                        </Form>
                    )}
            </Formik>
        </Modal>
    );
};

export default AddDebtorAddress;

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const AddingNewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin: 10px auto;
`;
const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    width: 48%;
  }
`;
const UserWrapper = styled.div`
     background-color: #c4c4c4;
    color: #fff;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    margin: 5px 0;
    `
const UserInfo = styled.p`
color: #fff;
margin: 0 5px;
`

const AddPatientNoOption = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const AddPatientButton = styled.button`
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  padding: 0.25em 0.65em;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.p`
cursor: pointer;
    color: red;
    font-size: 16px;
`