import React from 'react';
import Modal from '../../../shared/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import Select from '../../../shared/Select';

const BailiffModal = ({
  modalState,
  setModalState,
  bailiffs,
  updateLawsuit,
}) => {
  const initialValues = {
    bailiffId: null,
  };

  const bailiffsOptions = bailiffs.map(({ name, surname, _id }) => ({
    value: _id,
    label: `${name} ${surname}`,
  }));

  return (
    <Modal
      title="Przydziel komornika"
      show={modalState.isOpen}
      exitButton={true}
      onCancel={() => {
        setModalState({ isOpen: false, lawsuit: null });
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={({ bailiffId }) => {
          const { _id, ...laysuitRest } = modalState.lawsuit;
          updateLawsuit(
            modalState.lawsuit._id,
            { bailiffId, ...laysuitRest },
            true,
          );
          setModalState({ isOpen: false, lawsuit: null });
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <BailiffSelect
              value={bailiffsOptions.find(
                ({ value }) => value === values.bailiffId,
              )}
              options={bailiffsOptions}
              onChange={({ value }) => {
                setFieldValue('bailiffId', value);
              }}
            />
            <CenterBox>
              <SubmitBtn type="submit">Przydziel</SubmitBtn>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default BailiffModal;

const Form = styled.form`
  padding-top: 30px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BailiffSelect = styled(Select)`
  width: 300px;
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const SubmitBtn = styled(Button)`
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;
