import React from 'react';
import { HashRouter as Router, Switch } from 'react-router-dom';
import DashboardPageContainer from '../DashboardPage/DashboardPageContainer';
import GlobalStyle from '../../shared/GlobalStyle';
import NavigationContainer from '../Navigation/NavigationContainer';
import DebtorsDbContainer from '../DebtorsDb/DebtorsDbContainer';
import DocumentsContainer from '../AddDocumentType/DocumentsContainer';
import AddDocumentTypeContainer from '../AddDocumentType/AddDocumentTypeContainer';
import DebtWalletImportContainer from '../DebtWalletImport/DebtWalletImportContainer';
import SingleDebtorContainer from '../SingleDebtor/SingleDebtorContainer';
import AddDocumentContainer from '../AddDocument/AddDocumentContainer';
import DebtsContainer from '../DebtsPage/DebtsContainer';
import DocumentContainer from '../Document/DocumentContainer';
import BusinessPartnersContainer from '../BusinessPartners/BusinessPartnersContainer';
import AddDebtContainer from '../AddDebt/AddDebtContainer';
import SingleDebtContainer from '../SingleDebt/SingleDebtContainer';
import AddWallet from '../AddWallet/AddWalletContainer';
import PaymentsContainer from '../PaymentsPage/PaymentsPageContainer';
import PaymentsImportContainer from '../PaymentsImport/PaymentsImportContainer';
import DebtWalletsContainer from '../DebtWalletsPage/DebtWalletsContainer';
import SingleWalletContainer from '../SingleWallet/SingleWalletContainer';
import AddPaymentContainer from '../AddPayment/AddPaymentContainer';
import SinglePaymentContainer from '../SinglePayment/SinglePaymentContainer';
import DocumentStatusContainer from '../DocumentStatus/DocumentStatusContainer';
import UsersManagementContainer from '../UsersManagement/UsersManagementContainer';
import BailiffAssignContainer from '../BailiffAssign/BailiffAssignContainer';
import LawsuitsContainer from '../LawsuitsPage/LawsuitsContainer';
import SingleLawsuitContainer from '../SingleLawsuit/SingleLawsuitContainer';
import BailiffDebtsContainer from '../BailiffDebts/BailiffDebtsContainer';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import LoginContainer from '../LoginPage/LoginContainer';
import { connect } from 'react-redux';

function AppRouter({ isAuthenticated, loading, user }) {
  return (
    <Router>
      <GlobalStyle />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#f8f9fd',
          minHeight: 'calc(100vh)',
        }}
      >
        {isAuthenticated && <NavigationContainer user={user} />}
        <Switch>
          <AuthenticatedRoute
            path="/"
            exact
            component={DashboardPageContainer}
            isAuthenticated={isAuthenticated}
            loading={loading}
            user={user}
          />
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/debtors-db"
              exact
              component={DebtorsDbContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/debtors-db/:id"
              exact
              component={SingleDebtorContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/documents"
              exact
              component={DocumentsContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/documents/:id"
              exact
              component={AddDocumentTypeContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/debt-wallet-import"
              exact
              component={DebtWalletImportContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          <AuthenticatedRoute
            path="/add-document"
            exact
            component={AddDocumentContainer}
            isAuthenticated={isAuthenticated}
            loading={loading}
          />
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/debts"
              exact
              component={DebtsContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/debts/:id"
              exact
              component={SingleDebtContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/add-debt"
              exact
              component={AddDebtContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/document/:id"
              exact
              component={DocumentContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/business-partners"
              exact
              component={BusinessPartnersContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/add-wallet"
              exact
              component={AddWallet}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/payments"
              exact
              component={PaymentsContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/payments-import"
              exact
              component={PaymentsImportContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/wallets"
              exact
              component={DebtWalletsContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/wallets/:id"
              exact
              component={SingleWalletContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/add-payment"
              exact
              component={AddPaymentContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          {user?.role !== 'Intern' && (
            <AuthenticatedRoute
              path="/payments/:id"
              exact
              component={SinglePaymentContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          <AuthenticatedRoute
            path="/bailiff-assign"
            exact
            component={BailiffAssignContainer}
            isAuthenticated={isAuthenticated}
            loading={loading}
          />
          <AuthenticatedRoute
            path="/lawsuits"
            exact
            component={LawsuitsContainer}
            isAuthenticated={isAuthenticated}
            loading={loading}
          />
          <AuthenticatedRoute
            path="/lawsuits/:id"
            exact
            component={SingleLawsuitContainer}
            isAuthenticated={isAuthenticated}
            loading={loading}
          />
          <AuthenticatedRoute
            path="/documents-status"
            exact
            component={DocumentStatusContainer}
            isAuthenticated={isAuthenticated}
            loading={loading}
          />
          <AuthenticatedRoute
            path="/bailiff-debts"
            exact
            component={BailiffDebtsContainer}
            isAuthenticated={isAuthenticated}
            loading={loading}
          />
          {user?.role === 'Admin' && (
            <AuthenticatedRoute
              path="/users-management"
              exact
              component={UsersManagementContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
            />
          )}
          <UnauthenticatedRoute
            path="/login"
            exact
            component={LoginContainer}
            isAuthenticated={isAuthenticated}
            loading={loading}
          />
        </Switch>
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  loading: state.user.loading,
});

export default connect(mapStateToProps, null)(AppRouter);
