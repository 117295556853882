import React, { useEffect, useState } from 'react';
import UsersManagementView from './UsersManagementView';
import {
  getAllUsers,
  addUser,
  updateAuthenticatedUser,
} from '../../redux/modules/user';
import { connect } from 'react-redux';

const UsersManagementContainer = ({
  getAllUsers,
  users,
  addUser,
  updateAuthenticatedUser,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const [isAddUserModal, setAddUserModal] = useState(false);
  const [isEditUserModal, setEditUserModal] = useState({
    isOpen: false,
    user: null,
  });

  useEffect(() => {
    setTimeout(() => {
      getAllUsers();
    }, 100);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <UsersManagementView
      users={users}
      page={page}
      rowsPerPage={rowsPerPage}
      count={count}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      addUser={addUser}
      isAddUserModal={isAddUserModal}
      setAddUserModal={setAddUserModal}
      updateAuthenticatedUser={updateAuthenticatedUser}
      isEditUserModal={isEditUserModal}
      setEditUserModal={setEditUserModal}
    />
  );
};

const mapStateToProps = (state) => ({
  users: state.user.users,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: () => dispatch(getAllUsers()),
    addUser: (userBody, role) => dispatch(addUser(userBody, role)),
    updateAuthenticatedUser: (userData, id) =>
      dispatch(updateAuthenticatedUser(userData, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersManagementContainer);
