import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getDebtWallets } from '../../redux/modules/debtWallets';
import { getBusinessPartners } from '../../redux/modules/businessPartners';
import DebtWalletView from './DebtWalletsView';
import { withRouter } from 'react-router';

const DebtWalletsContainer = ({
  debtWallets,
  getDebtWallets,
  getBusinessPartners,
  businessPartners,
  history,
  paginationMetadata
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (debtWallets?.length === 0) {
      getDebtWallets(page + 1, rowsPerPage, searchTerm)
    }
    getBusinessPartners();
  }, []);

  useEffect(() => {
    if (paginationMetadata?.totalCount) {
      setCount(paginationMetadata.totalCount)
    }
  }, [paginationMetadata])

  useEffect(() => {
    getDebtWallets(page + 1, rowsPerPage, searchTerm)
  }, [page, rowsPerPage, searchTerm])

  useEffect(() => {
    if (!debtWallets || debtWallets?.length === 0) {
      setPage(0)
      if (paginationMetadata?.totalCount) {
        setCount(paginationMetadata.totalCount)
      } else {
        setCount(0)
      }
    }
  }, [debtWallets])

  const handleSearchInputChange = (searchTermInput) => {
    setSearchTerm(searchTermInput);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <DebtWalletView
      debtWallets={debtWallets}
      handleSearchInputChange={handleSearchInputChange}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      businessPartners={businessPartners}
      history={history}
    />
  );
};

const mapStateToProps = (state) => ({
  debtWallets: state.debtWallets.data,
  businessPartners: state.businessPartners.data,
  paginationMetadata: state.debtWallets.paginationMetadata
});

const mapDispatchToProps = (dispatch) => ({
  getDebtWallets: (pageNumber, pageSize, searchTerm) => dispatch(getDebtWallets(pageNumber, pageSize, searchTerm)),
  getBusinessPartners: () => dispatch(getBusinessPartners()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DebtWalletsContainer),
);
