import axios from 'axios';
import moment from 'moment';

const LAWSUIT_REQUEST = 'LAWSUIT_REQUEST';

const GET_LAWSUITS_SUCCESS = 'GET_LAWSUITS_SUCCESS';
const GET_LAWSUIT_SUCCESS = 'GET_LAWSUIT_SUCCESS';
const ADD_LAWSUITS_SUCCESS = 'ADD_LAWSUITS_SUCCESS';
const UPDATE_LAWSUITS_SUCCESS = 'UPDATE_LAWSUITS_SUCCESS';

const LAWSUIT_FAIL = 'LAWSUIT_FAIL';

const ADD_LAWSUIT_LOG_SUCCESS = "ADD_LAWSUIT_LOG_SUCCESS"

export const addLawsuitLog = (lawsuitId, logBody) => async (dispatch) => {
  dispatch(lawsuitRequest());
  try {
    const response = await axios.post(`/log/lawsuit/${lawsuitId}`, logBody);
    dispatch(addLawsuitLogSuccess(response.data));
  } catch (err) {
    dispatch(lawsuitFail(err));
  }
};

const addLawsuitLogSuccess = (log) => ({
  type: ADD_LAWSUIT_LOG_SUCCESS,
  payload: log,
});

export const editLawsuitLog = (logId, logBody) => async (dispatch) => {
  dispatch(lawsuitRequest());
  try {
    const response = await axios.patch(`/log/${logId}`, logBody);
    dispatch(editLawsuitLogSuccess(response.data));
  } catch (err) {
    dispatch(lawsuitFail(err));
  }
};

const EDIT_LAWSUIT_LOG_SUCCESS = "EDIT_LAWSUIT_LOG_SUCCESS"

const editLawsuitLogSuccess = (log) => ({
  type: EDIT_LAWSUIT_LOG_SUCCESS,
  payload: log,
});

export const getLawsuitLogs = (lawsuitId, limit = 4, skip = 0) => async (
  dispatch,
) => {
  dispatch(lawsuitRequest());
  try {
    const response = await axios.get(
      `/log/lawsuit/${lawsuitId}?limit=${limit}&skip=${skip}`,
    );
    dispatch(getLawsuitLogsSuccess(response.data.logs));
  } catch (err) {
    dispatch(lawsuitFail(err));
  }
};

const GET_LAWSUIT_LOGS_SUCCESS = "GET_LAWSUIT_LOGS_SUCCESS"

const getLawsuitLogsSuccess = (logs) => ({
  type: GET_LAWSUIT_LOGS_SUCCESS,
  payload: logs,
});


export const getLawsuits = (pageNumber, pageSize, searchTerm = '') => async (
  dispatch,
) => {
  dispatch(lawsuitRequest());
  try {
    const response = await axios.get(
      `/lawsuit?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}&populateField=lawsuitExtend`,
    );
    let paginationMetadata;
    if (response?.headers?.pagination) {
      paginationMetadata = JSON.parse(response.headers.pagination);
    }
    dispatch(getLawsuitsSuccess(response.data, paginationMetadata));
  } catch (err) {
    dispatch(lawsuitFail(err));
  }
};

const lawsuitRequest = () => ({
  type: LAWSUIT_REQUEST,
});

const getLawsuitsSuccess = (lawsuits, paginationMetadata) => ({
  type: GET_LAWSUITS_SUCCESS,
  payload: { lawsuits, paginationMetadata },
});

const lawsuitFail = (error) => ({
  type: LAWSUIT_FAIL,
  payload: error,
});

export const getLawsuitById = (lawsuitId) => async (dispatch) => {
  dispatch(lawsuitRequest());
  try {
    const response = await axios.get(`/lawsuit/${lawsuitId}`);
    dispatch(getLawsuitByIdSuccess(response.data));
    dispatch(getLawsuitLogs(response.data._id))
  } catch (err) {
    dispatch(lawsuitFail(err));
  }
};

const getLawsuitByIdSuccess = (lawsuits) => ({
  type: GET_LAWSUIT_SUCCESS,
  payload: lawsuits,
});

export const getLawsuitsWithoutBailiffs = () => async (dispatch) => {
  dispatch(lawsuitRequest());
  try {
    const response = await axios.get(`/lawsuit/without-bailiff`);
    dispatch(getLawsuitsSuccess(response.data));
  } catch (err) {
    dispatch(lawsuitFail(err));
  }
};

export const addLawsuit = (lawsuitBody) => async (dispatch) => {
  dispatch(lawsuitRequest());
  try {
    const response = await axios.post(`/lawsuit`, lawsuitBody);
    dispatch(addLawsuitsSuccess(response.data));
  } catch (err) {
    dispatch(lawsuitFail(err));
  }
};

const addLawsuitsSuccess = (lawsuit) => ({
  type: ADD_LAWSUITS_SUCCESS,
  payload: lawsuit,
});

export const updateLawsuit = (
  lawsuitId,
  lawsuitBody,
  removeFromStateAfterUpdate = false,
) => async (dispatch) => {
  dispatch(lawsuitRequest());
  try {
    const response = await axios.patch(`/lawsuit/${lawsuitId}`, lawsuitBody);
    dispatch(
      updateLawsuitsSuccess(
        removeFromStateAfterUpdate ? { lawsuitId } : response.data,
      ),
    );
  } catch (err) {
    dispatch(lawsuitFail(err));
  }
};

const updateLawsuitsSuccess = (lawsuit) => ({
  type: UPDATE_LAWSUITS_SUCCESS,
  payload: lawsuit,
});

export const getLawsuitsByBailiff = (bailiffID) => async (dispatch) => {
  dispatch(lawsuitRequest());
  try {
    const response = await axios.get(
      `/lawsuit/bailiff-cases?bailiffId=${bailiffID}`,
    );
    dispatch(getLawsuitsSuccess(response.data));
  } catch (err) {
    dispatch(lawsuitFail(err));
  }
};

const initialState = {
  data: [],
  loading: false,
  error: null,
  current: null,
  paginationMetadata: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LAWSUIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LAWSUIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_LAWSUITS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.lawsuits,
        paginationMetadata: action.payload.paginationMetadata,
      };
    case ADD_LAWSUITS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
      };
    case UPDATE_LAWSUITS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.lawsuitId
          ? state.data.filter((el) => el._id !== action.payload.lawsuitId)
          : state.data.map((el) =>
              el._id === action.payload._id ? action.payload : el,
            ),
        current: action.payload.lawsuitId ? state.current : action.payload,
      };
    case GET_LAWSUIT_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.payload,
      };
    case GET_LAWSUIT_LOGS_SUCCESS:
        return {
          ...state,
          current: {
            ...state.current,
            logs: action.payload,
          },
    };
    case EDIT_LAWSUIT_LOG_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          logs: state.current.logs.map((el) =>
            el._id === action.payload._id ? action.payload : el,
          ),
        },
      }
      case ADD_LAWSUIT_LOG_SUCCESS:
        return {
          ...state,
          current: {
            ...state.current,
            logs: [action.payload, ...state.current.logs],
          },
        };
    default:
      return state;
  }
};
