export const DEBT_TYPES_TEXTS = {
    NONE: 'Brak',
    TELECOM: 'Usługi telekomunikacyjne',
    SENTENCE: 'Nakaz zapłaty/Wyrok',
    RATE_AGREEMENT:'Umowa Ratalna',
    CREDIT_AGREEMENT: 'Umowa Kredytowa',
    PROCESS_COST: 'Koszty procesu',
    BAILIFF_COST: 'Koszty komornicze'
}

export const DEBT_TYPES = {
    NONE: 'none',
    TELECOM: 'telecom',
    SENTENCE: 'sentence',
    RATE_AGREEMENT:'rateAgreement',
    CREDIT_AGREEMENT: 'creditAgreement',
    PROCESS_COST: 'costOfProcess',
    BAILIFF_COST: 'costOfBailiff'
}