import axios from 'axios';

const DEBT_WALLET_REQUEST = 'DEBT_WALLET_REQUEST';
const DEBT_WALLET_FAIL = 'DEBT_WALLET_FAIL';
const IMPORT_DEBT_WALLET_SUCCESS = 'IMPORT_DEBT_WALLET_SUCCESS';
const IMPORT_DEBTS_TO_DEBT_WALLET_SUCCESS =
  'IMPORT_DEBTS_TO_DEBT_WALLET_SUCCESS';
const IMPORT_DEBTS_TO_DEBT_WALLET_FAIL = 'IMPORT_DEBTS_TO_DEBT_WALLET_FAIL';
const GET_DEBT_WALLETS_SUCCESS = 'GET_DEBT_WALLETS_SUCCESS';
const ADD_DEBT_WALLET_SUCCESS = 'ADD_DEBT_WALLET_SUCCESS';
const SET_CURRENT_DEBT_WALLET = 'SET_CURRENT_DEBT_WALLET';
const EDIT_DEBT_WALLET_SUCCESS = 'EDIT_DEBT_WALLET_SUCCESS';
const GET_DEBT_WALLET_SUCCESS = 'GET_DEBT_WALLET_SUCCESS';
const GET_DEBT_WALLET_DEBTS_REQUEST = 'GET_DEBT_WALLET_DEBTS_REQUEST';
const GET_DEBT_WALLET_DEBTS_SUCCESS = 'GET_DEBT_WALLET_DEBTS_SUCCESS';
const GET_DEBT_WALLET_DEBTS_FAILED = 'GET_DEBT_WALLET_DEBTS_FAILED';

const getDebtWalletDebtsRequest = () => ({
  type: GET_DEBT_WALLET_DEBTS_REQUEST,
});

const getDebtWalletDebtsSuccess = (debts, paginationMetadata) => ({
  type: GET_DEBT_WALLET_DEBTS_SUCCESS,
  payload: debts,
  debtsPaginationMetadata: paginationMetadata,
});

const getDebtWalletDebtsFailed = (err) => ({
  type: GET_DEBT_WALLET_DEBTS_FAILED,
  payload: err,
});

export const getDebtWalletDebts = (walletID, pageNumber, pageSize) => async (
  dispatch,
) => {
  dispatch(getDebtWalletDebtsRequest());
  try {
    const response = await axios.get(
      `/debt-wallets/${walletID}/debts?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
    let paginationMetadata;
    if (response?.headers?.pagination) {
      paginationMetadata = JSON.parse(response.headers.pagination);
    }
    dispatch(getDebtWalletDebtsSuccess(response.data, paginationMetadata));
  } catch (err) {
    dispatch(getDebtWalletDebtsFailed(err));
  }
};

const debtWalletRequest = () => ({
  type: DEBT_WALLET_REQUEST,
});

const debtWalletFail = (err) => ({
  type: DEBT_WALLET_FAIL,
  payload: err,
});

export const importDebtWallet = (debtWalletFile) => async (dispatch) => {
  dispatch(debtWalletRequest());
  try {
    const response = await axios.post(`/debt-wallets/import`, debtWalletFile);
    dispatch(importDebtWalletSuccess(response.data));
  } catch (err) {
    dispatch(debtWalletFail(err));
  }
};

const importDebtWalletSuccess = (debtBody) => ({
  type: IMPORT_DEBT_WALLET_SUCCESS,
  payload: debtBody,
});

export const getDebtWallets = (pageNumber, pageSize, searchTerm = '') => async (
  dispatch,
) => {
  dispatch(debtWalletRequest());
  try {
    const response = await axios.get(
      `/debt-wallets?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
    );
    let paginationMetadata;
    if (response?.headers?.pagination) {
      paginationMetadata = JSON.parse(response.headers.pagination);
    }
    dispatch(getDebtWalletsSuccess(response.data, paginationMetadata));
  } catch (err) {
    dispatch(debtWalletFail(err));
  }
};

const getDebtWalletsSuccess = (debtWallets, paginationMetadata) => ({
  type: GET_DEBT_WALLETS_SUCCESS,
  payload: debtWallets,
  paginationMetadata: paginationMetadata,
});

export const getDebtWalletById = (walletID) => async (dispatch) => {
  dispatch(debtWalletRequest());
  try {
    const response = await axios.get(`/debt-wallets/${walletID}`);
    dispatch(getDebtWalletSuccess(response.data));
  } catch (err) {
    dispatch(debtWalletFail(err));
  }
};

const getDebtWalletSuccess = (wallet) => ({
  type: GET_DEBT_WALLET_SUCCESS,
  payload: wallet,
});

export const addDebtWallet = (debtWallet) => async (dispatch) => {
  dispatch(debtWalletRequest());
  try {
    const response = await axios.post(`/debt-wallets`, debtWallet);
    dispatch(addDebtWalletSuccess(response.data));
  } catch (err) {
    dispatch(debtWalletFail(err));
  }
};

const addDebtWalletSuccess = (debtWallet) => ({
  type: ADD_DEBT_WALLET_SUCCESS,
  payload: debtWallet,
});

export const setCurrentDebtWallet = (debtWalletId) => ({
  type: SET_CURRENT_DEBT_WALLET,
  payload: debtWalletId,
});

export const editDebtWallet = (debtWallet, debtWalletID) => async (
  dispatch,
) => {
  dispatch(debtWalletRequest());
  try {
    const response = await axios.patch(
      `/debt-wallets/${debtWalletID}`,
      debtWallet,
    );
    dispatch(editDebtWalletSuccess(response.data));
  } catch (err) {
    dispatch(debtWalletFail(err));
  }
};

const editDebtWalletSuccess = (debtWallet) => ({
  type: EDIT_DEBT_WALLET_SUCCESS,
  payload: debtWallet,
});

export const importDebtsToDebtWallet = (debtsFile, debtWalletId) => async (
  dispatch,
) => {
  dispatch(debtWalletRequest());
  try {
    const response = await axios.post(
      `/debt-wallets/${debtWalletId}/debts/import`,
      debtsFile,
    );
    dispatch(importDebtsToDebtWalletSuccess(response.data));
  } catch (err) {
    dispatch(importDebtsToDebtWalletFail(err));
  }
};

const importDebtsToDebtWalletSuccess = (debtBody) => ({
  type: IMPORT_DEBTS_TO_DEBT_WALLET_SUCCESS,
  payload: debtBody,
});

const importDebtsToDebtWalletFail = (debtBody) => ({
  type: IMPORT_DEBTS_TO_DEBT_WALLET_FAIL,
  payload: debtBody,
});

const initialState = {
  data: [],
  loading: false,
  error: null,
  importWalletDebtsError: null,
  current: null,
  walletDebts: [],
  importedWalletDebts: [],
  paginationMetadata: null,
  debtsPaginationMetadata: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DEBT_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DEBT_WALLET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case IMPORT_DEBT_WALLET_SUCCESS:
      return {
        ...state,
        data: state.data,
        loading: false,
      };
    case IMPORT_DEBTS_TO_DEBT_WALLET_SUCCESS:
      return {
        ...state,
        importedWalletDebts: action.payload,
        loading: false,
      };
    case IMPORT_DEBTS_TO_DEBT_WALLET_FAIL:
      return {
        ...state,
        importWalletDebtsError: action.payload,
        loading: false,
      };
    case GET_DEBT_WALLETS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        paginationMetadata: action.paginationMetadata,
        loading: false,
      };
    case GET_DEBT_WALLET_SUCCESS:
      return {
        ...state,
        current: action.payload,
      };
    case ADD_DEBT_WALLET_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
      };
    case SET_CURRENT_DEBT_WALLET:
      return {
        ...state,
        current: state.data.find(
          (debtWallet) => debtWallet._id === action.payload,
        ),
      };
    case EDIT_DEBT_WALLET_SUCCESS:
      return {
        ...state,
        data: state.data.map((debtWallet) =>
          debtWallet._id === action.payload._id ? action.payload : debtWallet,
        ),
        loading: false,
      };
    case GET_DEBT_WALLET_DEBTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEBT_WALLET_DEBTS_SUCCESS:
      return {
        ...state,
        walletDebts: action.payload,
        debtsPaginationMetadata: action.debtsPaginationMetadata,
        loading: false,
      };
    case GET_DEBT_WALLET_DEBTS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
