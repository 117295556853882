import React, { useState } from "react";
import Modal from "../../../shared/Modal";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import Input from "@material-ui/core/TextField";
import * as Yup from "yup";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const PrimarySwitch = withStyles({
    switchBase: {
      color: '#949dcd',
      '&$checked': {
        color: '#4C5BAC',
      },
      '&$checked + $track': {
        backgroundColor: '#949dcd',
      },
    },
    checked: {},
    track: {},
  })(Switch);


const AddBusinessPartnerModal = ({ isAddBusinessPartnerModal, setAddBusinessPartnerModal, addBusinessPartner }) => {
    const [privatePersonSwitch, setPrivatePersonSwitch] = useState(false)
    const initialValues = {
        name: "",
        NIP: "",
        REGON: "",
        KRS: "",
        PESEL: "",
        address: {
            street: "",
            city: "",
            country: "Polska",
            postalCode: ""
        },
        accountNumber: ""
    };

    const AddBusinessPartnerSchema = Yup.object().shape({
        name: Yup.string().required("Nazwa jest wymagana")
    });

    return (
        <Modal
            title="Dodawanie Kontrahenta"
            show={isAddBusinessPartnerModal}
            exitButton={true}
            onCancel={() => {
                setAddBusinessPartnerModal(false);
            }}
        >


            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={AddBusinessPartnerSchema}
                onSubmit={async (values) => {
                    addBusinessPartner(values);
                    setAddBusinessPartnerModal(false);
                }}
            >
                {({
                    values,
                    errors,
                    setFieldValue,
                    setFieldError,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                        <Form onSubmit={handleSubmit}>

                            <InputRow>
                            <SwitchContainer>
                <span>Firma</span>
                <PrimarySwitch
                    checked={privatePersonSwitch}
                    onChange={(event) => setPrivatePersonSwitch(event.target.checked)}
                    name="privatePersonSwitch"
                />
                <span>Osoba Fizyczna</span>
            </SwitchContainer>
                            </InputRow>
                            <InputRow>
                                <Input
                                    name="name"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.name}
                                    error={errors.name}
                                    label="Nazwa Kontrahenta"
                                />
                            </InputRow>
                            <InputRow hidden={privatePersonSwitch}>
                                <Input
                                    name="NIP"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.NIP}
                                    error={errors.NIP}
                                    label="NIP"
                                />
                                <Input
                                    name="REGON"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.REGON}
                                    error={errors.REGON}
                                    label="REGON"
                                />
                                <Input
                                    name="KRS"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.KRS}
                                    error={errors.KRS}
                                    label="KRS"
                                />
                            </InputRow>
                            <InputRow hidden={!privatePersonSwitch}>
                                <Input
                                    name="PESEL"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.PESEL}
                                    error={errors.PESEL}
                                    label="PESEL"
                                />
                            </InputRow>
                            <InputRow>
                                <Input
                                    name="address.street"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.address?.street}
                                    error={errors.address?.street}
                                    label="Ulica"
                                />
                                <Input
                                    name="address.city"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.address?.city}
                                    error={errors.address?.city}
                                    label="Miasto"
                                />
                                <Input
                                    name="address.postalCode"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.address?.postalCode}
                                    error={errors.address?.postalCode}
                                    label="Kod Pocztowy"
                                />
                            </InputRow>
                            <InputRow>
                                <Input
                                    name="accountNumber"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.accountNumber}
                                    error={errors.accountNumber}
                                    label="Konto Bankowe"
                                />
                            </InputRow>
                            <CenterBox>
                                <Button type="submit" variant="primary">
                                    Potwierdź
              </Button>
                            </CenterBox>
                        </Form>
                    )}
            </Formik>
        </Modal>
    );
};

export default AddBusinessPartnerModal;

const SwitchContainer = styled.div`
width: 100%;
justify-content: center;
display: flex;
align-items: center;
/* margin-top: 50px; */
`

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const AddingNewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin: 10px auto;
`;
const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  > div {
    width: 48%;
  }
  ${({hidden})=> hidden && `display: none`}
`;
const UserWrapper = styled.div`
  background-color: #c4c4c4;
  color: #fff;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin: 5px 0;
`;
const UserInfo = styled.p`
  color: #fff;
  margin: 0 5px;
`;

const AddPatientNoOption = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const AddPatientButton = styled.button`
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  padding: 0.25em 0.65em;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.p`
  cursor: pointer;
  color: red;
  font-size: 16px;
`;
