import React from 'react';
import styled from 'styled-components';
import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchSelect from '../../shared/Select';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import FileUpload from '../../shared/FileUpload';
import IconButton from '@material-ui/core/IconButton';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import {DEBT_TYPES} from '../../redux/const';

const AddDebtView = ({
  addDebt,
  debtors,
  businessPartners,
  debtWallets,
  handleDebtorsSearchInputChange,
  handleBusinessPartnersSearchInputChange,
  handleDebtWalletsSearchInputChange,
  currentDebtor,
  addDebtWithDocuments,
  shapeRequestFormData,
  handleDebtSearchInputChange,
  debts,
  getDebtsByDebtorId
}) => {
  const initialValues = {
    debtor: currentDebtor ? currentDebtor._id : '',
    lawsuitNumber: '',
    principal: 0,
    currency: 'PLN',
    type: 'none',
    repaid: 'false',
    description: '',
    date: moment(new Date()).format('yyyy-MM-DD'),
    interestRate: 7.0,
    debtDocuments: [],
    linkedTo: ''
  };

  const AddDebtSchema = Yup.object().shape({
    lawsuitNumber: Yup.string(),
    principal: Yup.number().required('Wartość jest wymagana'),
    currency: Yup.string().required('Waluta jest wymagana'),
    type: Yup.string().required('Typ jest wymagany'),
    description: Yup.string(),
  });

  return (
    <Wrapper>
      <Title>Dodawanie długu</Title>
      <PaperWrapper>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={AddDebtSchema}
          onSubmit={async (values) => {
            if (values?.debtDocuments?.length > 0) {
              const debtWithDocumentsFormData = shapeRequestFormData(values);
              await addDebtWithDocuments(debtWithDocumentsFormData);
            } else {
              // console.log(values)
              await addDebt(values);
            }
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            setFieldError,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <InputRow>
                <NewDocumentSelect
                  label="Dłużnik"
                  value={debtors
                    .filter((debtor) => debtor._id === values.debtor)
                    .map((debtor) => {
                      return {
                        label: `${debtor.firstName} ${debtor.lastName}, ${debtor.pesel}`,
                        value: debtor._id,
                      };
                    })}
                  name="debtor"
                  placeholder="Wybierz dłużnika..."
                  options={debtors?.map((debtor) => {
                    return {
                      label: `${debtor.firstName} ${debtor.lastName}, ${debtor.pesel}`,
                      value: debtor._id,
                    };
                  })}
                  onChange={({ value }) =>{
                    setFieldValue('debtor', value)
                    getDebtsByDebtorId(value)
                    setFieldValue('linkedTo', '')
                    // handleDebtSearchInputChange(value)
                  }}
                  onInputChange={(value) =>
                    handleDebtorsSearchInputChange(value)
                  }
                  noOptionsMessage={() => 'Nie ma takiego dłużnika'}
                  error={errors.debtor}
                />
              </InputRow>
              <InputRow>
                <NewDocumentSelect
                  label="Wierzyciel"
                  // value={}
                  name="debtOwner"
                  placeholder="Wybierz wierzyciela..."
                  options={businessPartners?.map((bp) => {
                    return {
                      label: `${bp.name}, ${
                        bp.PESEL !== '' ? bp.PESEL : bp.NIP
                      }`,
                      value: bp._id,
                    };
                  })}
                  onChange={({ value }) => setFieldValue('debtOwner', value)}
                  onInputChange={(value) =>
                    handleBusinessPartnersSearchInputChange(value)
                  }
                  noOptionsMessage={() => 'Nie ma takiego właściciela'}
                  error={errors.debtOwner}
                />
              </InputRow>
              <InputRow>
                <NewDocumentSelect
                  label="Portfel długu"
                  // value={}
                  name="debtWallet"
                  placeholder="Wybierz portfel długu..."
                  options={debtWallets.map((dw) => {
                    return { value: dw._id, label: dw.name };
                  })}
                  onChange={({ value }) => setFieldValue('debtWallet', value)}
                  onInputChange={(value) =>
                    handleDebtWalletsSearchInputChange(value)
                  }
                  noOptionsMessage={() => 'Nie ma takiego portfela'}
                  error={errors.debtWallet}
                />
              </InputRow>
              <FullWidthInput
                name="description"
                type="text"
                onChange={handleChange}
                value={values.description}
                error={errors.description}
                label="Podstawa Wierzytelności"
              />
              <InputRow>
                <Input
                  name="principal"
                  type="text"
                  onChange={handleChange}
                  value={values.principal}
                  error={errors.principal}
                  label="Kwota"
                />
                <FormControl>
                  <InputLabel id="currency-label">Waluta</InputLabel>
                  <Select
                    labelId="currency-label"
                    id="currency"
                    value={values.currency}
                    onChange={(e) => setFieldValue('currency', e.target.value)}
                  >
                    <MenuItem value="PLN">PLN</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="GPB">GPB</MenuItem>
                  </Select>
                </FormControl>
                <Input
                  name="date"
                  type="date"
                  onChange={handleChange}
                  value={values.date}
                  error={errors.date}
                  label="Data Wierzytelności"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </InputRow>
              <InputRowDoubleInput>
                <FormControl>
                  <InputLabel id="type-label">Typ</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    value={values.type}
                    onChange={(e) => {
                      setFieldValue('type', e.target.value)
                      setFieldValue('linkedTo', '')
                      }}>
                    <MenuItem value="none">Brak</MenuItem>
                    <MenuItem value="telecom">Usługi telekomunikacyjne</MenuItem>
                    <MenuItem value="sentence">Nakaz zapłaty/Wyrok</MenuItem>
                    <MenuItem value="rateAgreement">Umowa Ratalna</MenuItem>
                    <MenuItem value="creditAgreement">Umowa Kredytowa</MenuItem>
                    <MenuItem value="costOfProcess">Koszty procesu</MenuItem>
                    <MenuItem value="costOfBailiff">Koszty komornicze</MenuItem>
                  </Select>
                </FormControl>
                <Input
                  name="interestRate"
                  type="number"
                  onChange={handleChange}
                  value={values.interestRate}
                  error={errors.interestRate}
                  label="Odsetki %"
                />
              </InputRowDoubleInput>
              <NewDocumentSelect
                isDisabled={!values.debtor}
                notDisplay={values.type === DEBT_TYPES.BAILIFF_COST || values.type === DEBT_TYPES.PROCESS_COST ? false : true}
                  label="Powiązany Dług"
                  name="linkedTo"
                  placeholder="Wybierz powiązany dług..."
                  options={debts?.map((debt) => {
                    return {
                      label: `${debt?.description}`,
                      value: debt?._id,
                    };
                  })}
                  onChange={({ value }) => setFieldValue('linkedTo', value)}
                  onInputChange={(value) => handleDebtSearchInputChange(value)}
                  noOptionsMessage={() => 'Nie ma takiego długu'}
                  error={errors.linkedTo}
                  value={{value: values.linkedTo, label: debts?.find(debt => debt._id === values.linkedTo)?.description}}
                />
                <ErrorText notDisplay={(values.type === DEBT_TYPES.BAILIFF_COST || values.type === DEBT_TYPES.PROCESS_COST) && !values.debtor ? false : true}>Wybierz dłużnika żeby powiązać dług</ErrorText>
              <FileUpload
                testId="tabs_dropfile"
                label="Dokumenty powiązane z długiem"
                onDrop={(files) => {
                  const debtDocumentsBuffer = [];
                  for (const i in files) {
                    const file = files[i];
                    const debtDocument = {
                      debtDocumentFile: file,
                      description: '',
                      originalFilename: file.name,
                    };
                    debtDocumentsBuffer.push(debtDocument);
                  }
                  setFieldValue('debtDocuments', [
                    ...values.debtDocuments,
                    ...debtDocumentsBuffer,
                  ]);
                }}
              />
              {values?.debtDocuments?.map((debtDocumentFile, index) => (
                <FileRow>
                  <ButtonWithFilename>
                    <StyledIconButton
                      type="button"
                      onClick={() => {
                        values.debtDocuments.splice(index, 1);
                        setFieldValue('debtDocuments', values.debtDocuments);
                      }}
                    >
                      <StyledClearOutlinedIcon />
                    </StyledIconButton>
                    <div>{debtDocumentFile.originalFilename}</div>
                  </ButtonWithFilename>
                  <StyledInput
                    name={`debtDocuments[${index}].description`}
                    type="text"
                    onChange={handleChange}
                    label="Opis dokumentu"
                    value={values.debtDocuments[index].description}
                  />
                </FileRow>
              ))}
              <CenterBox>
                <Button type="submit" variant="primary">
                  Potwierdź
                </Button>
              </CenterBox>
            </Form>
          )}
        </Formik>
      </PaperWrapper>
    </Wrapper>
  );
};

export default AddDebtView;

const ErrorText = styled.span`
color : red;
${({notDisplay}) => notDisplay && 'display: none;'}
`

const Wrapper = styled.div`
  margin: 20px auto;
  height: calc(100vh - 40px);
`;

const PaperWrapper = styled(Paper)`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 650px;
}`}
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const NewDocumentSelect = styled(SearchSelect)`
  width: 650px;
  ${({notDisplay}) => notDisplay && 'display: none;'}
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  > div {
    width: 32%;
  }
`;

const InputRowDoubleInput = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  > div {
    width: 49%;
  }
`;

const FullWidthInput = styled(Input)`
  width: 100%;
`;
const FileRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
`;

const ButtonWithFilename = styled.div`
  display: flex;
  align-items: center;
  width: 48%;
`;

const StyledIconButton = styled(IconButton)`
  position: relative;
  top: 2px;
`;

const StyledClearOutlinedIcon = styled(ClearOutlinedIcon)`
  color: #4c5bac;
`;

const StyledInput = styled(Input)`
  width: 48%;
`;
