import React, { useEffect, useState, useRef } from 'react';
import DocumentView from './DocumentView';
import {
  getDocument,
  editCurrentDocument,
  getCourtByID,
} from '../../redux/modules/document';
import { getDebtorById } from '../../redux/modules/debtors';
import { getBusinessPartnerById } from '../../redux/modules/businessPartners';
import { connect } from 'react-redux';
import jsbarcode from 'jsbarcode';

const DocumentContainer = ({
  match,
  getDocument,
  currentDocument,
  editCurrentDocument,
  getDebtorById,
  currentDebtor,
  currentLegalCounsel,
  getBusinessPartnerById,
  getCourtByID,
  currentCourt,
  userRole
}) => {
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [status, setStatus] = useState(null);
  const barcodeRef = useRef();
  const [documentDataLoaded, setDocumentDataLoaded] = useState(false);

  const buildVariablesContent = () => {
    let content = currentDocument.content;
    jsbarcode('#barcode', currentDocument.barcode, {
      width: 1,
      height: 50,
      fontSize: 12,
      margin: 0,
    });

    content = content.replace(
      /{{miasto_dłużnik}}/g,
      currentDebtor?.addresses[0]?.city,
    );
    content = content.replace(
      /{{kraj_dłużnik}}/g,
      currentDebtor?.addresses[0]?.country,
    );
    content = content.replace(
      /{{kod_pocztowy_dłużnik}}/g,
      currentDebtor?.addresses[0]?.postalCode,
    );
    content = content.replace(
      /{{ulica_dłużnik}}/g,
      currentDebtor?.addresses[0]?.street,
    );
    content = content.replace(/{{pesel_dłużnik}}/g, currentDebtor?.pesel);
    content = content.replace(
      /{{godność_dłużnik}}/g,
      `${currentDebtor?.firstName} ${currentDebtor?.lastName}`,
    );

    content = content.replace(
      /{{numer_konta_radca}}/g,
      `${currentLegalCounsel?.accountNumber}`,
    );
    content = content.replace(
      /{{godność_radca}}/g,
      `${currentLegalCounsel?.name}`,
    );
    content = content.replace(
      /{{kraj_radca}}/g,
      `${currentLegalCounsel?.address.country}`,
    );
    content = content.replace(
      /{{miasto_radca}}/g,
      `${currentLegalCounsel?.address.city}`,
    );
    content = content.replace(
      /{{ulica_radca}}/g,
      `${currentLegalCounsel?.address.street}`,
    );
    content = content.replace(
      /{{kod_pocztowy_radca}}/g,
      `${currentLegalCounsel?.address.postalCode}`,
    );
    content = content.replace(/{{NIP_radca}}/g, `${currentLegalCounsel?.NIP}`);

    content = content.replace(/{{nazwa_sąd}}/g, `${currentCourt?.name}`);

    content = content.replace(
      /{{numer_kontaktowy_sąd}}/g,
      `${currentCourt?.phoneNumber}`,
    );
    content = content.replace(/{{fax_sąd}}/g, `${currentCourt?.fax}`);

    content = content.replace(
      /{{kod_pocztowy_sąd}}/g,
      `${currentCourt?.postalCode}`,
    );
    content = content.replace(/{{ulica_sąd}}/g, `${currentCourt?.street}`);

    content = content.replace(
      /{{kod_kreskowy}}/g,
      `<p><img src="${barcodeRef.current.src}" width="189" height="64" alt="" /></p>`,
    );

    return content;
  };


  useEffect(() => {
    if (userRole && !(currentDocument?._id === match.params.id)) {
      getDocument(match.params.id);
    }
  }, [userRole, currentDocument])

  // useEffect(() => {
  //   if (!(currentDocument?._id === match.params.id)) {
  //     getDocument(match.params.id);
  //   }
  // }, []);

  useEffect(() => {
    if (currentDocument) {
      getDebtorById(currentDocument.debtor);
      getBusinessPartnerById(currentDocument.legalCounsel);
      currentDocument.court && getCourtByID(currentDocument.court);
      setName(currentDocument.name);
      setStatus(currentDocument.status);
    }
  }, [currentDocument]);

  useEffect(() => {
    if (currentDocument && currentDebtor && currentLegalCounsel) {
      if(currentDocument.court){
        if(currentCourt){
          setDocumentDataLoaded(true)
        }
      } else {
        setDocumentDataLoaded(true)
      }
    }
  }, [currentDebtor, currentDocument, currentLegalCounsel, currentCourt]);

  useEffect(() => {
    if(documentDataLoaded){
      setContent(buildVariablesContent());
    }
  },[documentDataLoaded])

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  return (
    <>
      <img id="barcode" ref={barcodeRef} style={{ visibility: 'hidden' }} />
      {documentDataLoaded &&
        <DocumentView
          currentDocument={currentDocument}
          name={name}
          content={content}
          setContent={setContent}
          setName={setName}
          editCurrentDocument={editCurrentDocument}
          status={status}
          setStatus={setStatus}
          handleEditorChange={handleEditorChange}
        />
      }

    </>
  );
};

const mapStateToProps = (state) => ({
  currentDocument: state.documents.current,
  currentDebtor: state.debtors.current,
  currentLegalCounsel: state.businessPartners.current,
  currentCourt: state.documents.currentCourt,
  userRole: state.user.user.role
});

const mapDispatchToProps = (dispatch) => ({
  getDocument: (id) => dispatch(getDocument(id)),
  editCurrentDocument: (newDocument, documentId) =>
    dispatch(editCurrentDocument(newDocument, documentId)),
  getDebtorById: (id) => dispatch(getDebtorById(id)),
  getBusinessPartnerById: (id) => dispatch(getBusinessPartnerById(id)),
  getCourtByID: (id) => dispatch(getCourtByID(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentContainer);
