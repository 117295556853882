import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIndIcon from '@material-ui/icons/AssignmentIndOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import PersonIcon from '@material-ui/icons/Person';
import AddDebtorAddressModal from './modals/AddDebtorAddressModal';
import AddDebtorAssetModal from './modals/AddDebtorAssetModal';
import AddDebtorPhoneModal from './modals/AddDebtorPhoneModal';
import AddDebtorBankAccountModal from './modals/AddDebtorBankAccountModal';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import WarningModal from './modals/WarningModal';
import EditDebtorAddressModal from './modals/EditDebtorAddressModal';
import EditDebtorPhoneModal from './modals/EditDebtorPhoneModal';
import EditDebtorBankAccountModal from './modals/EditDebtorBankAccountModal';
import EditDebtorAssetModal from './modals/EditDebtorAssetModal';
import AddBalanceEventModal from './modals/AddBalanceEventModal';
import EditDebtorBalanceEventModal from './modals/EditBalanceEventModal';
import AssignDebtorModal from './modals/AssignDebtorModal';
import LogModal from './modals/LogModal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { countBalanceSum } from '../../redux/utils';
import { TableHead } from '@material-ui/core';
import Barcode from 'react-barcode';
import { countInterest, countSumOfInterests } from '../../redux/utils';
import moment from 'moment';

const SingleDebtorView = ({
  debtor,
  isAddressModalOpen,
  setAddressModalOpen,
  isPhoneModalOpen,
  setPhoneModalOpen,
  isBankAccountModalOpen,
  setBankAccountModalOpen,
  isAssetModalOpen,
  setAssetModalOpen,
  addDebtorAddress,
  addDebtorPhone,
  addBankAccount,
  addDebtorAsset,
  deleteDebtorAddress,
  deleteDebtorPhone,
  deleteDebtorBankAccount,
  deleteDebtorAsset,
  warningModal,
  setWarningModal,
  isAddressEditModalOpen,
  setAddressEditModalOpen,
  selectedAddress,
  setSelectedAddress,
  editDebtorAddress,
  isPhoneEditModalOpen,
  setPhoneEditModalOpen,
  selectedPhone,
  setSelectedPhone,
  editDebtorPhone,
  editDebtorAsset,
  editDebtorBankAccount,
  isEditBankAccountModalOpen,
  setEditBankAccountModalOpen,
  selectedBankAccount,
  setSelectedBankAccount,
  isEditAssetModalOpen,
  setEditAssetModalOpen,
  selectedAsset,
  setSelectedAsset,
  changeDebtorSubdocument,
  isBalanceModalOpen,
  setBalanceModalOpen,
  isEditBalanceModalOpen,
  setEditBalanceModalOpen,
  selectedBalanceEvent,
  setSelectedBalanceEvent,
  addDebtorBalanceEvent,
  deleteDebtorBalanceEvent,
  editDebtorBalanceEvent,
  rightTabsValue,
  setRightTabsValue,
  debtorDocuments,
  history,
  debtorDebts,
  debtorPayments,
  isAssignModalOpen,
  setAssignModalOpen,
  users,
  logModal,
  setLogModal,
  addDebtorLog,
  userId,
  editDebtorLog,
  editDebtorAssignment,
  logPage,
  setLogPage,
}) => {
  const worker = users?.find(({ _id }) => _id === debtor?.workerId) ?? {};
  return (
    <SingleDebtorContainer>
      <AddBalanceEventModal
        isAddDebtorBalanceEvent={isBalanceModalOpen}
        setAddDebtorBalanceEvent={setBalanceModalOpen}
        addDebtorBalanceEvent={addDebtorBalanceEvent}
        debtorId={debtor?._id}
      />
      <AddDebtorAddressModal
        isAddDebtorAddress={isAddressModalOpen}
        setAddDebtorAddress={setAddressModalOpen}
        addDebtorAddress={addDebtorAddress}
        debtorId={debtor?._id}
      />
      <AddDebtorPhoneModal
        isAddDebtorPhone={isPhoneModalOpen}
        setAddDebtorPhone={setPhoneModalOpen}
        addDebtorPhone={addDebtorPhone}
        debtorId={debtor?._id}
      />
      <AddDebtorBankAccountModal
        isAddDebtorBankAccount={isBankAccountModalOpen}
        setAddDebtorBankAccount={setBankAccountModalOpen}
        addDebtorBankAccount={addBankAccount}
        debtorId={debtor?._id}
      />
      <AddDebtorAssetModal
        isAddDebtorAsset={isAssetModalOpen}
        setAddDebtorAsset={setAssetModalOpen}
        addDebtorAsset={addDebtorAsset}
        debtorId={debtor?._id}
      />
      <EditDebtorAddressModal
        isEditDebtorAddress={isAddressEditModalOpen}
        setEditDebtorAddress={setAddressEditModalOpen}
        editDebtorAddress={editDebtorAddress}
        debtorId={debtor?._id}
        selectedAddress={selectedAddress}
        changeDebtorSubdocument={changeDebtorSubdocument}
      />
      <EditDebtorPhoneModal
        isEditDebtorPhone={isPhoneEditModalOpen}
        setEditDebtorPhone={setPhoneEditModalOpen}
        editDebtorPhone={editDebtorPhone}
        debtorId={debtor?._id}
        selectedPhone={selectedPhone}
        changeDebtorSubdocument={changeDebtorSubdocument}
      />
      <EditDebtorBankAccountModal
        isEditDebtorBankAccount={isEditBankAccountModalOpen}
        setEditDebtorBankAccount={setEditBankAccountModalOpen}
        editDebtorBankAccount={editDebtorBankAccount}
        debtorId={debtor?._id}
        selectedBankAccount={selectedBankAccount}
        changeDebtorSubdocument={changeDebtorSubdocument}
      />
      <EditDebtorAssetModal
        isEditDebtorAsset={isEditAssetModalOpen}
        setEditDebtorAsset={setEditAssetModalOpen}
        editDebtorAsset={editDebtorAsset}
        debtorId={debtor?._id}
        selectedAsset={selectedAsset}
        changeDebtorSubdocument={changeDebtorSubdocument}
      />
      <EditDebtorBalanceEventModal
        isEditDebtorBalanceEvent={isEditBalanceModalOpen}
        setEditDebtorBalanceEvent={setEditBalanceModalOpen}
        editDebtorBalanceEvent={editDebtorBalanceEvent}
        debtorId={debtor?._id}
        selectedBalanceEvent={selectedBalanceEvent}
      />
      <WarningModal
        show={warningModal.shown}
        question={warningModal.question}
        onAccept={warningModal.onAccept}
        preventClosing={warningModal.preventClosing}
        setModalState={setWarningModal}
        questionTitle={warningModal.questionTitle}
      />
      <AssignDebtorModal
        isAssignModalOpen={isAssignModalOpen}
        setAssignModalOpen={setAssignModalOpen}
        users={users}
        editDebtorAssignment={editDebtorAssignment}
        debtor={debtor}
      />
      <LogModal
        logModal={logModal}
        setLogModal={setLogModal}
        addDebtorLog={addDebtorLog}
        editDebtorLog={editDebtorLog}
        debtorId={debtor?._id}
        userId={userId}
      />
      <FlexContainer>
        <PageSubTitle>{`${debtor?.firstName} ${debtor?.lastName}`}</PageSubTitle>
        <PeselAndBalanceContainer>
          <DebtorPesel>
            <StyledPeselIcon />
            PESEL: <BoldPeselText>{debtor?.pesel}</BoldPeselText>
          </DebtorPesel>
          <DebtorBalance>
            <BoldBalanceText>
              {debtor?.balance[0]
                ? `${countBalanceSum(debtor.balance)} PLN`
                : 'Brak wpisów salda'}
            </BoldBalanceText>
          </DebtorBalance>
        </PeselAndBalanceContainer>
      </FlexContainer>
      <TablesContainer>
        <Column>
          <StyledTable aria-label="simple table">
            <StyledTableBody>
              <StyledTableRow key={'Adresy'}>
                <TableCellKey scope="row">
                  <StyledHomeIcon />
                  Adresy:
                </TableCellKey>
                <TableCellValue align="left">
                  {debtor?.addresses.length > 0
                    ? debtor?.addresses?.map((address) => (
                        <TableCellArrayElement>
                          <DataSpan defaultData={address.defaultAddress}>
                            {address?.street +
                              ', ' +
                              address?.city +
                              ', ' +
                              address?.postalCode}
                          </DataSpan>
                          <EditDeleteButtonsContainer>
                            <AddItemButton
                              padding={false}
                              onClick={() =>
                                setWarningModal({
                                  shown: true,
                                  question: 'Czy na pewno chcesz usunąć adres?',
                                  onAccept: () => {
                                    deleteDebtorAddress(
                                      debtor._id,
                                      address._id,
                                    );
                                  },
                                })
                              }
                            >
                              <StyledDeleteIcon />
                            </AddItemButton>
                            <AddItemButton
                              padding={false}
                              onClick={() => {
                                setSelectedAddress(address);
                                setAddressEditModalOpen(true);
                              }}
                            >
                              <StyledEditIcon />
                            </AddItemButton>
                          </EditDeleteButtonsContainer>
                        </TableCellArrayElement>
                      ))
                    : 'Brak Informacji'}
                </TableCellValue>
                <ButtonTableCell>
                  <AddItemButton onClick={() => setAddressModalOpen(true)}>
                    <StyledPlusIcon />
                  </AddItemButton>
                </ButtonTableCell>
              </StyledTableRow>

              <StyledTableRow key={'Telefony'}>
                <TableCellKey scope="row">
                  <StyledPhoneIcon />
                  Telefony:
                </TableCellKey>
                <TableCellValue align="left">
                  {debtor?.phones.length > 0
                    ? debtor?.phones?.map((phone) => (
                        <TableCellArrayElement>
                          <DataSpan defaultData={phone.defaultPhoneNumber}>
                            {phone?.phoneNumber}
                          </DataSpan>
                          <EditDeleteButtonsContainer>
                            <AddItemButton
                              padding={false}
                              onClick={() =>
                                setWarningModal({
                                  shown: true,
                                  question:
                                    'Czy na pewno chcesz usunąć telefon?',
                                  onAccept: () => {
                                    deleteDebtorPhone(debtor._id, phone._id);
                                  },
                                })
                              }
                            >
                              <StyledDeleteIcon />
                            </AddItemButton>
                            <AddItemButton
                              padding={false}
                              onClick={() => {
                                setSelectedPhone(phone);
                                setPhoneEditModalOpen(true);
                              }}
                            >
                              <StyledEditIcon />
                            </AddItemButton>
                          </EditDeleteButtonsContainer>
                        </TableCellArrayElement>
                      ))
                    : 'Brak Informacji'}
                </TableCellValue>
                <ButtonTableCell>
                  <AddItemButton onClick={() => setPhoneModalOpen(true)}>
                    <StyledPlusIcon />
                  </AddItemButton>
                </ButtonTableCell>
              </StyledTableRow>
              <StyledTableRow key={'Konta Bankowe'}>
                <TableCellKey scope="row">
                  <StyledBankAccountIcon />
                  Konta Bankowe:
                </TableCellKey>
                <TableCellValue align="left">
                  {debtor?.bankAccounts.length > 0
                    ? debtor?.bankAccounts?.map((bankAccount) => (
                        <TableCellArrayElement>
                          <DataSpan
                            defaultData={bankAccount.defaultBankAccount}
                          >
                            {bankAccount?.accountNumber}
                          </DataSpan>
                          <EditDeleteButtonsContainer>
                            <AddItemButton
                              padding={false}
                              onClick={() =>
                                setWarningModal({
                                  shown: true,
                                  question:
                                    'Czy na pewno chcesz usunąć konto bankowe?',
                                  onAccept: () => {
                                    deleteDebtorBankAccount(
                                      debtor._id,
                                      bankAccount._id,
                                    );
                                  },
                                })
                              }
                            >
                              <StyledDeleteIcon />
                            </AddItemButton>
                            <AddItemButton
                              padding={false}
                              onClick={() => {
                                setSelectedBankAccount(bankAccount);
                                setEditBankAccountModalOpen(true);
                              }}
                            >
                              <StyledEditIcon />
                            </AddItemButton>
                          </EditDeleteButtonsContainer>
                        </TableCellArrayElement>
                      ))
                    : 'Brak Informacji'}
                </TableCellValue>
                <ButtonTableCell>
                  <AddItemButton onClick={() => setBankAccountModalOpen(true)}>
                    <StyledPlusIcon />
                  </AddItemButton>
                </ButtonTableCell>
              </StyledTableRow>
              <StyledTableRow key={'Aktywa'}>
                <TableCellKey scope="row">
                  <StyledMoneyIcon />
                  Majątek:
                </TableCellKey>
                <TableCellValue align="left">
                  {debtor?.assets.length > 0
                    ? debtor?.assets?.map((asset) => (
                        <TableCellArrayElement>
                          <DataSpan defaultData={asset.defaultAsset}>
                            {asset?.name + ': ' + asset?.description}
                            <br />
                            {asset?.value + ' ' + asset?.currency}
                          </DataSpan>
                          <EditDeleteButtonsContainer>
                            <AddItemButton
                              padding={false}
                              onClick={() =>
                                setWarningModal({
                                  shown: true,
                                  question:
                                    'Czy na pewno chcesz element majątku?',
                                  onAccept: () => {
                                    deleteDebtorAsset(debtor._id, asset._id);
                                  },
                                })
                              }
                            >
                              <StyledDeleteIcon />
                            </AddItemButton>
                            <AddItemButton
                              padding={false}
                              onClick={() => {
                                setSelectedAsset(asset);
                                setEditAssetModalOpen(true);
                              }}
                            >
                              <StyledEditIcon />
                            </AddItemButton>
                          </EditDeleteButtonsContainer>
                        </TableCellArrayElement>
                      ))
                    : 'Brak Informacji'}
                </TableCellValue>
                <ButtonTableCell>
                  <AddItemButton onClick={() => setAssetModalOpen(true)}>
                    <StyledPlusIcon />
                  </AddItemButton>
                </ButtonTableCell>
              </StyledTableRow>
              <StyledTableRow key={'Aktywa'}>
                <TableCellKey scope="row">
                  <StyledPersonIcon />
                  Przypisany do:
                </TableCellKey>
                <TableCellValue align="left">
                  {debtor?.workerId
                    ? `${worker.name} ${worker.surname}`
                    : 'Brak'}
                </TableCellValue>
                <ButtonTableCell>
                  <AddItemButton onClick={() => setAssignModalOpen(true)}>
                    <StyledEditIcon />
                  </AddItemButton>
                </ButtonTableCell>
              </StyledTableRow>
            </StyledTableBody>
          </StyledTable>
          <Activity>
            <SectionHeader>Zadania</SectionHeader>
            <StyledTable aria-label="simple table">
              <TableHead>
                <TableCell>Data</TableCell>
                <TableCell>Treść</TableCell>
                <TableCell>
                  <AddItemButton
                    onClick={() =>
                      setLogModal({ isOpen: true, currentLog: null })
                    }
                  >
                    <StyledPlusIcon />
                  </AddItemButton>
                </TableCell>
              </TableHead>
              {debtor?.logs?.length > 0 ? (
                <StyledTableBody>
                  {debtor.logs
                    .filter(({ _id }) => _id)
                    .map(({ _id, text, status, date }) => (
                      <ActivityRow key={_id} status={status}>
                        <TableCellValue align="left">
                          {moment(date).format('DD.MM.YYYY')}
                        </TableCellValue>
                        <TableCellValue align="left">{text}</TableCellValue>
                        <ButtonTableCell>
                          <AddItemButton
                            padding={false}
                            onClick={() =>
                              setLogModal({
                                isOpen: true,
                                currentLog: { _id, text, status, date },
                              })
                            }
                          >
                            <StyledEditIcon />
                          </AddItemButton>
                        </ButtonTableCell>
                      </ActivityRow>
                    ))}
                </StyledTableBody>
              ) : (
                <NoItems colSpan={3}>Brak aktywności</NoItems>
              )}
            </StyledTable>
            <StyledTablePagination
              component="div"
              page={logPage}
              onChangePage={(e, newPage) => setLogPage(newPage)}
              disableNext={debtor?.logs?.length === 0}
            />
          </Activity>
        </Column>
        <Column>
          <BalanceHistoryContainer>
            <Tabs
              value={rightTabsValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, value) => setRightTabsValue(value)}
              aria-label="disabled tabs example"
            >
              <Tab label="Historia Salda" />
              <Tab label="Dokumenty" />
              <Tab label="Długi" />
              <Tab label="Wpłaty" />
            </Tabs>
            <TabPanel index={0} hidden={rightTabsValue !== 0}>
              {debtor?.balance.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableCell>Kwota</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Opis</TableCell>
                  </TableHead>
                  <TableBody>
                    {debtor?.balance
                      ?.sort((a, b) => {
                        if (new Date(b.eventDate) - new Date(a.eventDate) > 0) {
                          return -1;
                        }
                        if (new Date(a.eventDate) - new Date(b.eventDate) < 0) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((balanceEvent, i) => (
                        <StyledTableRow>
                          <BalanceHistoryTableCell
                            isNegative={
                              balanceEvent.valueSign === '-' ? true : false
                            }
                          >
                            {`${balanceEvent.valueSign}${
                              balanceEvent.valueSign === '-'
                                ? balanceEvent.principal
                                : balanceEvent.value
                            } ${balanceEvent.currency}`}
                          </BalanceHistoryTableCell>
                          <BalanceHistoryTableCell
                            isNegative={
                              balanceEvent.valueSign === '-' ? true : false
                            }
                          >
                            {balanceEvent.eventDate.slice(0, 10)}
                          </BalanceHistoryTableCell>
                          <BalanceHistoryTableCell
                            isNegative={
                              balanceEvent.valueSign === '-' ? true : false
                            }
                          >
                            {balanceEvent.description}
                          </BalanceHistoryTableCell>
                        </StyledTableRow>
                      ))}
                    <StyledTableRow>
                      <BalanceHistoryTableCell isNegative={true}>
                        {`${countSumOfInterests(debtor?.balance)} PLN`}
                      </BalanceHistoryTableCell>
                      <BalanceHistoryTableCell isNegative={true}>
                        {new Date().toISOString().slice(0, 10)}
                      </BalanceHistoryTableCell>
                      <BalanceHistoryTableCell isNegative={true}>
                        Stan Odsetek
                      </BalanceHistoryTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              ) : (
                <BalanceNoItemsContainer>
                  <span>Brak Informacji</span>
                </BalanceNoItemsContainer>
              )}
            </TabPanel>
            <TabPanel index={1} hidden={rightTabsValue !== 1}>
              {debtorDocuments?.length > 0 ? (
                <Table>
                  <TableBody>
                    {debtorDocuments?.map((document, i) => (
                      <StyledTableRow>
                        <TableCell>{`${document.name}`}</TableCell>
                        <TableCell>
                          {document.status === 'CREATED'
                            ? 'Utworzony'
                            : document.status === 'SENT'
                            ? 'Wysłany'
                            : document.status === 'RECEIVED'
                            ? 'Odebrany'
                            : 'Odrzucony'}
                        </TableCell>
                        <TableCell>{document.description}</TableCell>
                        <TableCell>
                          <Barcode
                            width={1}
                            height={50}
                            fontSize={12}
                            margin={0}
                            value={document.barcode}
                          />
                        </TableCell>
                        <TableCell>{document.date.slice(0, 10)}</TableCell>
                        <TableCell>
                          <EditDeleteButtonsContainer>
                            <AddItemButton
                              padding={false}
                              onClick={() => {
                                history.push(`/document/${document._id}`);
                              }}
                            >
                              <StyledEditIcon />
                            </AddItemButton>
                          </EditDeleteButtonsContainer>
                        </TableCell>
                        {i === 0 ? (
                          <TableCell>
                            <AddItemButton
                              onClick={() => history.push(`/add-document`)}
                            >
                              <StyledPlusIcon />
                            </AddItemButton>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <BalanceNoItemsContainer>
                  <span>Brak Informacji</span>
                  <AddItemButton onClick={() => history.push(`/add-document`)}>
                    <StyledPlusIcon />
                  </AddItemButton>
                </BalanceNoItemsContainer>
              )}
            </TabPanel>
            <TabPanel index={2} hidden={rightTabsValue !== 2}>
              {debtorDebts?.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableCell>Pozostała kwota i odsetki</TableCell>
                    <TableCell>Podstawa </TableCell>
                    <TableCell>Data</TableCell>
                    <ShortTableCell></ShortTableCell>
                    <ShortTableCell></ShortTableCell>
                  </TableHead>
                  <TableBody>
                    {debtorDebts?.map((debt, i) => (
                      <StyledTableRow>
                        <TableCell>
                          {debt
                            ? debt?.startedRepayment
                              ? `${countInterest(
                                  debt?.principalLeft + debt?.interestsLeft,
                                  moment()
                                    .startOf('day')
                                    .diff(
                                      debt?.lastPaymentDate?.slice(0, 10),
                                      'years',
                                      true,
                                    ),
                                  debt?.interestRate,
                                )} ${debt.currency}`
                              : `${countInterest(
                                  debt?.principal,
                                  moment()
                                    .startOf('day')
                                    .diff(
                                      debt?.date?.slice(0, 10),
                                      'years',
                                      true,
                                    ),
                                  debt?.interestRate,
                                )} ${debt.currency}`
                            : null}
                        </TableCell>
                        <TableCell>{debt.description}</TableCell>
                        <TableCell>{debt?.date?.slice(0, 10)}</TableCell>
                        <ShortTableCell>
                          <EditDeleteButtonsContainer>
                            <AddItemButton
                              padding={false}
                              onClick={() => {
                                history.push(`/debts/${debt._id}`);
                              }}
                            >
                              <StyledEditIcon />
                            </AddItemButton>
                          </EditDeleteButtonsContainer>
                        </ShortTableCell>
                        {i === 0 ? (
                          <ShortTableCell>
                            <AddItemButton
                              onClick={() => history.push(`/add-debt`)}
                            >
                              <StyledPlusIcon />
                            </AddItemButton>
                          </ShortTableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <BalanceNoItemsContainer>
                  <span>Brak Informacji</span>
                  <AddItemButton onClick={() => history.push(`/add-debt`)}>
                    <StyledPlusIcon />
                  </AddItemButton>
                </BalanceNoItemsContainer>
              )}
            </TabPanel>
            <TabPanel index={3} hidden={rightTabsValue !== 3}>
              {debtorPayments?.length > 0 ? (
                <Table>
                  <TableBody>
                    {debtorPayments?.map((payment, i) => (
                      <StyledTableRow>
                        <TableCell>
                          {`${payment.value} ${payment.currency}`}
                        </TableCell>
                        <TableCell>{payment.description}</TableCell>
                        <TableCell>{payment.date.slice(0, 10)}</TableCell>
                        <TableCell>
                          <EditDeleteButtonsContainer>
                            <AddItemButton
                              padding={false}
                              onClick={() => {
                                history.push(`/payments/${payment?._id}`);
                              }}
                            >
                              <StyledEditIcon />
                            </AddItemButton>
                          </EditDeleteButtonsContainer>
                        </TableCell>
                        {i === 0 ? (
                          <TableCell>
                            <AddItemButton
                              onClick={() => history.push(`/add-payment`)}
                            >
                              <StyledPlusIcon />
                            </AddItemButton>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <BalanceNoItemsContainer>
                  <span>Brak Informacji</span>
                  <AddItemButton onClick={() => history.push(`/add-payment`)}>
                    <StyledPlusIcon />
                  </AddItemButton>
                </BalanceNoItemsContainer>
              )}
            </TabPanel>
          </BalanceHistoryContainer>
        </Column>
      </TablesContainer>
    </SingleDebtorContainer>
  );
};

export default SingleDebtorView;

const DarkColorText = styled.span`
  color: rgba(0, 0, 0, 0.87);
`;

const TabPanel = styled.div``;

const BalanceNoItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DataSpan = styled.span`
  ${({ defaultData }) =>
    defaultData &&
    `background: #4C5BAC;
    color: white;
    padding: 2px 4px;
    border-radius: 5px;`}
`;

const EditDeleteButtonsContainer = styled.div`
  width: 48px;
  display: flex;
`;

const ShortTableCell = styled(TableCell)`
  width: 50px;
`;

const ButtonTableCell = styled(TableCell)`
  display: flex;
  border: none;
`;

const StyledDeleteIcon = styled(DeleteForeverOutlinedIcon)`
  color: rgb(228, 82, 121);
`;

const StyledEditIcon = styled(EditOutlinedIcon)`
  color: #4c5bac;
`;

const StyledPlusIcon = styled(AddCircleOutlineOutlinedIcon)`
  color: rgb(69, 193, 91);
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 1px solid rgba(224, 224, 224);
  position: relative;
`;

const AddItemButton = styled(IconButton)`
  padding: 5px;
  ${({ padding }) => padding === false && `padding: 0`}
`;

const TablesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Column = styled.div`
  width: calc(50% - 20px);
`;

const BalanceHistoryContainer = styled.div``;

const StyledTable = styled(Table)``;

const StyledMoneyIcon = styled(MonetizationOnOutlinedIcon)`
  margin-right: 5px;
`;

const StyledBankAccountIcon = styled(AccountBalanceOutlinedIcon)`
  margin-right: 5px;
`;

const StyledPhoneIcon = styled(PhoneAndroidOutlinedIcon)`
  margin-right: 5px;
`;

const StyledHomeIcon = styled(HomeOutlinedIcon)`
  margin-right: 5px;
`;

const StyledPersonIcon = styled(PersonIcon)`
  margin-right: 5px;
`;

const StyledTableBody = styled(TableBody)``;

const PeselAndBalanceContainer = styled.div``;

const DebtorBalance = styled.div`
  text-align: right;
`;

const BoldPeselText = styled.span`
  font-weight: bold;
  margin-left: 5px;
  font-size: 20px;
`;
const BoldBalanceText = styled.span`
  font-weight: bold;
  margin-left: 5px;
  color: rgb(228, 82, 121);
  font-size: 24px;
`;
const StyledPeselIcon = styled(AssignmentIndIcon)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const DebtorPesel = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SingleDebtorContainer = styled(Paper)`
  width: calc(100vw - 105px);
  margin: 20px auto;
  padding: 60px;
  position: relative;
  margin-left: 85px;
`;

const PageSubTitle = styled.h2`
  margin: 0;
  margin-bottom: 35px;
  width: 50%;
`;

const TableCellKey = styled(TableCell)`
  width: 220px;
  border-bottom: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  margin: 0;
  padding-left: 0;
`;

const TableCellValue = styled(TableCell)`
  width: 9999px;
  border: none;
`;

const TableCellArrayElement = styled.div`
  margin: 5px 0px 5px 0px;
  display: flex;
  justify-content: space-between;
`;

const BalanceHistoryTableCell = styled(TableCell)`
  font-size: 15px;
  ${({ isNegative }) =>
    isNegative ? 'color: rgb(228,82,121)' : 'color: rgb(69,193,91)'}
`;

const Activity = styled.div``;

const SectionHeader = styled.h3`
  margin: 30px 0 10px;
`;

const ActivityRow = styled(TableRow)`
  vertical-align: top;
  td {
    color: inherit;
    &:nth-child(2) {
      width: 70%;
    }
    &:last-child {
      display: flex;
      justify-content: center;
    }
  }
  ${({ status, theme }) =>
    status === 'INFO'
      ? `
      color:${theme.info};
    `
      : status === 'DANGER'
      ? `
      color:${theme.danger};
    `
      : status === 'WARNING'
      ? `
      color:${theme.warning};
    `
      : status === 'SUCCESS'
      ? `
      color:${theme.green};
    `
      : ``}
`;

const NoItems = styled(TableCellValue)`
  text-align: center;
  padding: 30px 0;
`;

const StyledTablePagination = styled(TablePagination)`
  > div {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    p {
      display: none;
    }
    > div:not(:last-child) {
      display: none;
    }
    > div:last-child button:last-child {
      ${({ disableNext }) =>
        disableNext &&
        `
      color: rgba(0, 0, 0, 0.26);
      `}
    }
  }
`;
