import { combineReducers } from 'redux';
//import authentication from './modules/authentication';
import debtors from './modules/debtors';
import documentTypes from './modules/documentTypes';
import debts from './modules/debts';
import documents from './modules/document';
import businessPartners from './modules/businessPartners';
import debtWallets from './modules/debtWallets';
import payments from './modules/payments';
import user from './modules/user';
import lawsuits from './modules/lawsuits';

export default combineReducers({
  debtors,
  documentTypes,
  debts,
  documents,
  businessPartners,
  debtWallets,
  payments,
  user,
  lawsuits,
});
