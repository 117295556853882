import React, { useState, useEffect } from 'react';
import DebtorsDbView from './DebtorsDbView';
import { connect } from 'react-redux';
import {
  addDebtor,
  getDebtors,
  getWorkerDebtors,
} from '../../redux/modules/debtors';

const DebtorsDbContainer = ({
  addDebtor,
  getDebtors,
  debtors,
  paginationMetadata,
  getWorkerDebtors,
  user,
}) => {
  const [addDebtorModalOpen, setAddDebtorModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [displaySwitch, setDisplaySwitch] = useState(0);

  useEffect(() => {
    if (user?._id) {
      if (displaySwitch) {
        getWorkerDebtors(user?._id, page + 1, rowsPerPage, searchTerm, true);
      } else {
        getDebtors(page + 1, rowsPerPage, searchTerm, true);
      }
    }
  }, [displaySwitch]);

  useEffect(() => {
    if (paginationMetadata?.totalCount) {
      setCount(paginationMetadata.totalCount);
    }
  }, [paginationMetadata]);

  useEffect(() => {
    if (displaySwitch) {
      getWorkerDebtors(user?._id, page + 1, rowsPerPage, searchTerm, true);
    } else {
      getDebtors(page + 1, rowsPerPage, searchTerm, true);
    }
  }, [page, rowsPerPage, searchTerm]);

  useEffect(() => {
    if (!debtors || debtors?.length === 0) {
      setPage(0);
      if (paginationMetadata?.totalCount) {
        setCount(paginationMetadata.totalCount);
      } else {
        setCount(0);
      }
    }
  }, [debtors]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (searchTermInput) => {
    setSearchTerm(searchTermInput);
  };

  return (
    <DebtorsDbView
      addDebtor={addDebtor}
      debtors={debtors}
      addDebtorModalOpen={addDebtorModalOpen}
      setAddDebtorModalOpen={setAddDebtorModalOpen}
      handleSearchInputChange={handleSearchInputChange}
      handleChangePage={handleChangePage}
      page={page}
      count={count}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      rowsPerPage={rowsPerPage}
      displaySwitch={displaySwitch}
      setDisplaySwitch={setDisplaySwitch}
    />
  );
};

const mapStateToProps = (state) => ({
  debtors: state.debtors.data,
  paginationMetadata: state.debtors.paginationMetadata,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  addDebtor: (name, surname, pesel) =>
    dispatch(addDebtor(name, surname, pesel)),
  getDebtors: (pageNumber, pageSize, searchTerm, populateNewestLog) =>
    dispatch(getDebtors(pageNumber, pageSize, searchTerm, populateNewestLog)),
  getWorkerDebtors: (
    workerId,
    pageNumber,
    pageSize,
    searchTerm,
    populateNewestLog,
  ) =>
    dispatch(
      getWorkerDebtors(
        workerId,
        pageNumber,
        pageSize,
        searchTerm,
        populateNewestLog,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DebtorsDbContainer);
