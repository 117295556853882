import React, { useEffect } from 'react';
import AppRouter from './features/AppRouter/AppRouter';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  StylesProvider,
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { checkToken } from './redux/modules/user';
import { ThemeProvider } from 'styled-components';
import { theme } from './shared/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      // light: '#ed4b82',
      main: '#4C5BAC',
      // dark: '#a31545',
      contrastText: '#fff',
    },
    secondary: {
      light: '#834bff',
      main: '#651fff',
      dark: '#4615b2',
      contrastText: '#fff',
    },
  },
});

const App = ({ checkToken }) => {
  useEffect(() => {
    checkToken();
  }, []);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Debtable</title>
            <link
              href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap"
              rel="stylesheet"
            />
            <script
              src="https://cdn.tiny.cloud/1/no-api-key/tinymce/5/tinymce.min.js"
              referrerpolicy="origin"
            ></script>
          </Helmet>
          <DndProvider backend={HTML5Backend}>
            <AppRouter />
            <ToastContainer
              position={'bottom-center'}
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              limit={3}
              closeButton={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </DndProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken: () => dispatch(checkToken(sessionStorage.getItem('jwtToken'))),
  };
};

export default connect(null, mapDispatchToProps)(App);
