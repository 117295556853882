import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DocumentsView from './DocumentsView';
import { getDocumentTypes } from '../../redux/modules/documentTypes';

const DocumentsContainer = ({ getDocumentTypes, documents }) => {
  useEffect(() => {
    getDocumentTypes();
  }, []);
  return <DocumentsView documents={documents} />;
};

const mapStateToProps = (state) => ({
  documents: state.documentTypes.data,
});

const mapDispatchToProps = (dispatch) => ({
  getDocumentTypes: () => dispatch(getDocumentTypes()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsContainer);
