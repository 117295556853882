import React, { useState } from 'react';
import DocumentStatusView from './DocumentStatusView';
import { editDocumentStatusByBarcode } from '../../redux/modules/document';
import { connect } from 'react-redux';

const DocumentStatusContainer = ({ editDocumentStatusByBarcode }) => {
  const [barcode, setBarcode] = useState('');
  const [status, setStatus] = useState('CREATED');

  return (
    <DocumentStatusView
      barcode={barcode}
      setBarcode={setBarcode}
      status={status}
      setStatus={setStatus}
      editDocumentStatusByBarcode={editDocumentStatusByBarcode}
    />
  );
};

const mapStateToProps = (state) => ({
  currentDocument: state.documents.current,
  currentDebtor: state.debtors.current,
  currentLegalCounsel: state.businessPartners.current,
  currentCourt: state.documents.currentCourt,
});

const mapDispatchToProps = (dispatch) => ({
  editDocumentStatusByBarcode: (statusBody, barcode) =>
    dispatch(editDocumentStatusByBarcode(statusBody, barcode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentStatusContainer);
