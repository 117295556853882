import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loader from '../../shared/Loader';

export default ({ component: C, isAuthenticated, loading, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loading ? (
        <Loader />
      ) : isAuthenticated ? (
        <C {...props} isAuthenticated {...rest} />
      ) : (
        <Redirect to={`/login`} />
      )
    }
  />
);
