import React from 'react';
import Modal from '../../../shared/Modal';
import styled from 'styled-components';
import { TextField, MenuItem, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

const logStatusOptions = [
  {
    label: 'Normalna',
    value: 'NORMAL',
  },
  {
    label: 'Pozytywna',
    value: 'SUCCESS',
  },
  {
    label: 'Ostrzegająca',
    value: 'WARNING',
  },
  {
    label: 'Pilna',
    value: 'DANGER',
  },
  {
    label: 'Informacyjna',
    value: 'INFO',
  },
];

const LogModal = ({
  logModal: { isOpen, currentLog },
  setLogModal,
  addDebtorLog,
  editDebtorLog,
  debtorId,
  userId,
}) => {
  const edit = currentLog !== null;

  const initialValues = {
    status: edit ? currentLog?.status : 'NORMAL',
    date: edit
      ? moment(currentLog?.date).format('yyyy-MM-DD')
      : moment().format('yyyy-MM-DD'),
    text: edit ? currentLog?.text : '',
  };

  const AddDebtorSchema = Yup.object().shape({
    date: Yup.string().required('Numer konta jest wymagany'),
    status: Yup.string().required('Numer konta jest wymagany'),
    text: Yup.string().required('Numer konta jest wymagany'),
  });

  return (
    <Modal
      title={edit ? 'Edycja aktywności' : 'Dodawanie aktywności'}
      show={isOpen}
      exitButton={true}
      onCancel={() => {
        setLogModal(false);
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AddDebtorSchema}
        onSubmit={async ({ text, date, status }) => {
          if (edit) {
            await editDebtorLog(currentLog._id, {
              text,
              date : date + new Date().toISOString().slice(10),
              status,
            });
          } else {
            await addDebtorLog(debtorId, {
              text,
              date : date + new Date().toISOString().slice(10),
              status,
              createdBy: userId,
            });
          }
          setLogModal({ isOpen: false, currentLog: null });
        }}
      >
        {({ values, setFieldValue, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <InputRow>
              <TextField
                name="date"
                defaultValue={moment().format('yyyy-MM-DD')}
                type="date"
                onChange={handleChange}
                value={values.date}
                label="Data aktywności"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                select
                name="status"
                label="Status"
                value={values.status}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {logStatusOptions.map(({ value, label }) => (
                  <MenuItem value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </InputRow>
            <TextFieldTextarea
              multiline
              rows={5}
              label="Treść"
              name="text"
              value={values.text}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <CenterBox>
              <Button type="submit" variant="primary">
                Potwierdź
              </Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default LogModal;

const Form = styled.form`
  padding-top: 30px;
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 350px;
}`}
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const TextFieldTextarea = styled(TextField)`
  width: 100%;
  margin-top: 20px;
`;
