import axios from 'axios';
import { countInterest } from '../utils';
import moment from 'moment';

const ADD_DEBTOR_SUCCESS = 'ADD_DEBTOR_SUCCESS';
const GET_DEBTORS_SUCCESS = 'GET_DEBTORS_SUCCESS';
const GET_DEBTOR_BY_ID_SUCCESS = 'GET_DEBTOR_BY_ID_SUCCESS';
const ADD_DEBTOR_ADDRESS_SUCCESS = 'ADD_DEBTOR_ADDRESS_SUCCESS';
const ADD_DEBTOR_BANK_ACCOUNT_SUCCESS = 'ADD_DEBTOR_BANK_ACCOUNT_SUCCESS';
const ADD_DEBTOR_PHONE_SUCCESS = 'ADD_DEBTOR_PHONE_SUCCESS';
const ADD_DEBTOR_ASSET_SUCCESS = 'ADD_DEBTOR_ASSET_SUCCESS';
const DELETE_DEBTOR_ADDRESS_SUCCESS = 'DELETE_DEBTOR_ADDRESS_SUCCESS';
const DELETE_DEBTOR_PHONE_SUCCESS = 'DELETE_DEBTOR_PHONE_SUCCESS';
const DELETE_DEBTOR_BANK_ACCOUNT_SUCCESS = 'DELETE_DEBTOR_BANK_ACCOUNT_SUCCESS';
const DELETE_DEBTOR_ASSET_SUCCESS = 'DELETE_DEBTOR_ASSET_SUCCESS';
const EDIT_DEBTOR_ADDRESS_SUCCESS = 'EDIT_DEBTOR_ADDRESS_SUCCESS';
const EDIT_DEBTOR_PHONE_SUCCESS = 'EDIT_DEBTOR_PHONE_SUCCESS';
const EDIT_DEBTOR_BANK_ACCOUNT_SUCCESS = 'EDIT_DEBTOR_BANK_ACCOUNT_SUCCESS';
const EDIT_DEBTOR_ASSET_SUCCESS = 'EDIT_DEBTOR_ASSET_SUCCESS';
const CHANGE_DEBTOR_DEFAULT_SUBDOCUMENT_SUCCESS =
  'CHANGE_DEBTOR_DEFAULT_SUBDOCUMENT_SUCCESS';
const ADD_DEBTOR_BALANCE_EVENT_SUCCESS = 'ADD_DEBTOR_BALANCE_EVENT_SUCCESS';
const DELETE_DEBTOR_BALANCE_EVENT_SUCCESS =
  'DELETE_DEBTOR_BALANCE_EVENT_SUCCESS';
const EDIT_DEBTOR_BALANCE_EVENT_SUCCESS = 'EDIT_DEBTOR_BALANCE_EVENT_SUCCESS';

const DEBTOR_REDUCER_REQUEST = 'DEBTOR_REDUCER_REQUEST';
const DEBTOR_REDUCER_FAIL = 'DEBTOR_REDUCER_FAIL';

const GET_DEBTOR_LOGS_SUCCESS = 'GET_DEBTOR_LOGS_SUCCESS';
const ADD_DEBTOR_LOG_SUCCESS = 'ADD_DEBTOR_LOG_SUCCESS';
const EDIT_DEBTOR_LOG_SUCCESS = 'EDIT_DEBTOR_LOG_SUCCESS';
const EDIT_DEBTOR_ASSIGMENT_SUCCESS = 'EDIT_DEBTOR_ASSIGMENT_SUCCESS';

const debtorReducerRequest = () => ({
  type: DEBTOR_REDUCER_REQUEST,
});

const debtorReducerFail = (err) => ({
  type: DEBTOR_REDUCER_FAIL,
  payload: err,
});

export const addDebtorBalanceEvent = (balanceEvent, debtorId) => async (
  dispatch,
) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.post(`/debtors/${debtorId}/balance`, {
      value: `${Math.abs(balanceEvent.value)}`,
      valueSign: balanceEvent.value >= 0 ? '+' : '-',
      description: balanceEvent.description,
      currency: balanceEvent.currency,
      eventDate: balanceEvent.eventDate,
    });
    dispatch(addDebtorBalanceEventSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const addDebtorBalanceEventSuccess = (balanceEvent) => ({
  type: ADD_DEBTOR_BALANCE_EVENT_SUCCESS,
  payload: balanceEvent,
});

export const deleteDebtorBalanceEvent = (debtorId, balanceEventId) => async (
  dispatch,
) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.delete(
      `/debtors/${debtorId}/balance/${balanceEventId}`,
    );
    dispatch(deleteDebtorBalanceEventSuccess(balanceEventId));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const deleteDebtorBalanceEventSuccess = (balanceEventId) => ({
  type: DELETE_DEBTOR_BALANCE_EVENT_SUCCESS,
  payload: balanceEventId,
});

export const changeDebtorSubdocument = (
  subdocumentType,
  debtorId,
  subdocumentId,
) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.patch(
      `/debtors/${debtorId}/${subdocumentType}/${subdocumentId}/change-default`,
    );
    dispatch(changeDebtorSubdocumentSuccess(subdocumentType, subdocumentId));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const changeDebtorSubdocumentSuccess = (subdocumentType, subdocumentId) => ({
  type: CHANGE_DEBTOR_DEFAULT_SUBDOCUMENT_SUCCESS,
  payload: {
    type: subdocumentType,
    id: subdocumentId,
  },
});

export const deleteDebtorAddress = (debtorId, addressId) => async (
  dispatch,
) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.delete(
      `/debtors/${debtorId}/addresses/${addressId}`,
    );
    dispatch(deleteDebtorAddressSuccess(addressId));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const deleteDebtorAddressSuccess = (addressId) => ({
  type: DELETE_DEBTOR_ADDRESS_SUCCESS,
  payload: addressId,
});

export const editDebtorBalanceEvent = (
  balanceEvent,
  debtorId,
  balanceEventId,
) => async (dispatch) => {
  console.log('To jest balance event: ', balanceEvent);
  dispatch(debtorReducerRequest());
  try {
    const balanceEventToSend = {
      ...balanceEvent,
      value: `${Math.abs(balanceEvent.value)}`,
      valueSign: balanceEvent.value >= 0 ? '+' : '-',
    };
    const response = await axios.patch(
      `/debtors/${debtorId}/balance/${balanceEventId}`,
      balanceEventToSend,
    );
    dispatch(editDebtorBalanceEventSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const editDebtorBalanceEventSuccess = (balanceEvent) => ({
  type: EDIT_DEBTOR_BALANCE_EVENT_SUCCESS,
  payload: balanceEvent,
});

export const deleteDebtorPhone = (debtorId, phoneId) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.delete(
      `/debtors/${debtorId}/phones/${phoneId}`,
    );
    dispatch(deleteDebtorPhoneSuccess(phoneId));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const deleteDebtorPhoneSuccess = (phoneId) => ({
  type: DELETE_DEBTOR_PHONE_SUCCESS,
  payload: phoneId,
});

export const deleteDebtorBankAccount = (debtorId, bankAccountId) => async (
  dispatch,
) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.delete(
      `/debtors/${debtorId}/bank-accounts/${bankAccountId}`,
    );
    dispatch(deleteDebtorBankAccountSuccess(bankAccountId));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const deleteDebtorBankAccountSuccess = (bankAccountId) => ({
  type: DELETE_DEBTOR_BANK_ACCOUNT_SUCCESS,
  payload: bankAccountId,
});

export const deleteDebtorAsset = (debtorId, assetId) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.delete(
      `/debtors/${debtorId}/assets/${assetId}`,
    );
    dispatch(deleteDebtorAssetSuccess(assetId));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const deleteDebtorAssetSuccess = (assetId) => ({
  type: DELETE_DEBTOR_ASSET_SUCCESS,
  payload: assetId,
});

export const addDebtorAsset = (asset, id) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.post(`/debtors/${id}/assets`, asset);
    dispatch(addDebtorAssetSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const addDebtorAssetSuccess = (asset) => ({
  type: ADD_DEBTOR_ASSET_SUCCESS,
  payload: asset,
});

export const editDebtorAsset = (asset, debtorId, assetId) => async (
  dispatch,
) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.patch(
      `/debtors/${debtorId}/assets/${assetId}`,
      asset,
    );
    dispatch(editDebtorAssetSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const editDebtorAssetSuccess = (asset) => ({
  type: EDIT_DEBTOR_ASSET_SUCCESS,
  payload: asset,
});

export const addDebtorPhone = (phoneNumber, id) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.post(`/debtors/${id}/phones`, {
      phoneNumber: phoneNumber,
    });
    dispatch(addDebtorPhoneSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const addDebtorPhoneSuccess = (phoneNumber) => ({
  type: ADD_DEBTOR_PHONE_SUCCESS,
  payload: phoneNumber,
});

export const addDebtorAddress = (address, id) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.post(`/debtors/${id}/addresses`, address);
    dispatch(addDebtorAddressSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const addDebtorAddressSuccess = (address) => ({
  type: ADD_DEBTOR_ADDRESS_SUCCESS,
  payload: address,
});

export const editDebtorAddress = (address, debtorId, addressId) => async (
  dispatch,
) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.patch(
      `/debtors/${debtorId}/addresses/${addressId}`,
      address,
    );
    dispatch(editDebtorAddressSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const editDebtorAddressSuccess = (address) => ({
  type: EDIT_DEBTOR_ADDRESS_SUCCESS,
  payload: address,
});

export const editDebtorPhone = (phone, debtorId, phoneId) => async (
  dispatch,
) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.patch(
      `/debtors/${debtorId}/phones/${phoneId}`,
      {
        phoneNumber: phone,
      },
    );
    dispatch(editDebtorPhoneSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const editDebtorPhoneSuccess = (phone) => ({
  type: EDIT_DEBTOR_PHONE_SUCCESS,
  payload: phone,
});

export const addBankAccount = (accountNumber, id) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.post(`/debtors/${id}/bank-accounts`, {
      accountNumber: accountNumber,
      IBAN: `PL${accountNumber}`,
    });
    dispatch(addBankAccountSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const addBankAccountSuccess = (bankAccount) => ({
  type: ADD_DEBTOR_BANK_ACCOUNT_SUCCESS,
  payload: bankAccount,
});

export const editDebtorBankAccount = (
  accountNumber,
  debtorId,
  accountId,
) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.patch(
      `/debtors/${debtorId}/bank-accounts/${accountId}`,
      {
        accountNumber: accountNumber,
        IBAN: `PL${accountNumber}`,
      },
    );
    dispatch(editBankAccountSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const editBankAccountSuccess = (bankAccount) => ({
  type: EDIT_DEBTOR_BANK_ACCOUNT_SUCCESS,
  payload: bankAccount,
});

export const addDebtor = (name, surname, pesel) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.post('/debtors', {
      firstName: name,
      lastName: surname,
      pesel: pesel,
    });
    dispatch(addDebtorSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const addDebtorSuccess = (debtor) => ({
  type: ADD_DEBTOR_SUCCESS,
  payload: debtor,
});

export const getDebtors = (
  pageNumber,
  pageSize,
  searchTerm = '',
  populateNewestLog,
) => async (dispatch) => {
  let populateQuery = populateNewestLog
    ? '&populateField=logs&populateLimit=1&populateOrder=-date'
    : '';
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.get(
      `/debtors?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}${populateQuery}`,
    );
    let paginationMetadata;
    if (response?.headers?.pagination) {
      paginationMetadata = JSON.parse(response.headers.pagination);
    }
    dispatch(getDebtorsSuccess(response.data, paginationMetadata));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

export const getWorkerDebtors = (
  workerId,
  pageNumber,
  pageSize,
  searchTerm = '',
  populateNewestLog,
) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  let populateQuery = populateNewestLog
    ? '&populateField=logs&populateLimit=1&populateOrder=-date'
    : '';
  try {
    const response = await axios.get(
      `/debtors/worker/${workerId}?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}${populateQuery}`,
    );
    let paginationMetadata;
    if (response?.headers?.pagination) {
      paginationMetadata = JSON.parse(response.headers.pagination);
    }
    dispatch(getDebtorsSuccess(response.data, paginationMetadata));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const getDebtorsSuccess = (debtors, paginationMetadata) => ({
  type: GET_DEBTORS_SUCCESS,
  payload: debtors,
  paginationMetadata: paginationMetadata,
});

export const getDebtorById = (debtorId, populateLogs = false) => async (
  dispatch,
) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.get(
      `/debtors/${debtorId}${populateLogs && '?populateLogs'}`,
    );
    dispatch(getDebtorByIdSuccess(response.data));
    dispatch(getDebtorLogs(response.data._id))
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const getDebtorByIdSuccess = (debtor) => ({
  type: GET_DEBTOR_BY_ID_SUCCESS,
  payload: debtor,
});

export const getDebtorLogs = (debtorId, limit = 4, skip = 0) => async (
  dispatch,
) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.get(
      `/log/debtor/${debtorId}?limit=${limit}&skip=${skip}`,
    );
    dispatch(getDebtorLogsSuccess(response.data.logs));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const getDebtorLogsSuccess = (logs) => ({
  type: GET_DEBTOR_LOGS_SUCCESS,
  payload: logs,
});

export const addDebtorLog = (debtorId, logBody) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.post(`/log/debtor/${debtorId}`, logBody);
    dispatch(addDebtorLogSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const addDebtorLogSuccess = (log) => ({
  type: ADD_DEBTOR_LOG_SUCCESS,
  payload: log,
});

export const editDebtorLog = (logId, logBody) => async (dispatch) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.patch(`/log/${logId}`, logBody);
    dispatch(editDebtorLogSuccess(response.data));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const editDebtorLogSuccess = (log) => ({
  type: EDIT_DEBTOR_LOG_SUCCESS,
  payload: log,
});

export const editDebtorAssignment = (debtorId, workerId) => async (
  dispatch,
) => {
  dispatch(debtorReducerRequest());
  try {
    const response = await axios.patch(`/debtors/${debtorId}`, { workerId });
    dispatch(editDebtorAssignmentSuccess(response.data.workerId));
  } catch (err) {
    dispatch(debtorReducerFail(err));
  }
};

const editDebtorAssignmentSuccess = (workerId) => ({
  type: EDIT_DEBTOR_ASSIGMENT_SUCCESS,
  payload: workerId,
});

const initialState = {
  data: [],
  loading: false,
  error: null,
  current: null,
  paginationMetadata: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DEBTOR_REDUCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DEBTOR_REDUCER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_DEBTOR_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
      };
    case GET_DEBTORS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        paginationMetadata: action.paginationMetadata,
        loading: false,
      };
    case GET_DEBTOR_BY_ID_SUCCESS:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case ADD_DEBTOR_BALANCE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          balance: [...state.current.balance, action.payload],
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                balance: [...state.current.balance, action.payload],
              }
            : debtor,
        ),
      };
    case ADD_DEBTOR_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          addresses: [...state.current.addresses, action.payload],
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                addresses: [...state.current.addresses, action.payload],
              }
            : debtor,
        ),
      };

    case ADD_DEBTOR_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          bankAccounts: [...state.current.bankAccounts, action.payload],
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                bankAccounts: [...state.current.bankAccounts, action.payload],
              }
            : debtor,
        ),
      };

    case ADD_DEBTOR_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          phones: [...state.current.phones, action.payload],
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                phones: [...state.current.phones, action.payload],
              }
            : debtor,
        ),
      };

    case ADD_DEBTOR_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          assets: [...state.current.assets, action.payload],
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                assets: [...state.current.assets, action.payload],
              }
            : debtor,
        ),
      };

    case DELETE_DEBTOR_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          addresses: state.current.addresses.filter(
            (address) => address._id !== action.payload,
          ),
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                addresses: state.current.addresses.filter(
                  (address) => address._id !== action.payload,
                ),
              }
            : debtor,
        ),
      };

    case DELETE_DEBTOR_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          phones: state.current.phones.filter(
            (phone) => phone._id !== action.payload,
          ),
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                phones: state.current.phones.filter(
                  (phone) => phone._id !== action.payload,
                ),
              }
            : debtor,
        ),
      };

    case DELETE_DEBTOR_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          bankAccounts: state.current.bankAccounts.filter(
            (bankAccount) => bankAccount._id !== action.payload,
          ),
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                bankAccounts: state.current.bankAccounts.filter(
                  (bankAccount) => bankAccount._id !== action.payload,
                ),
              }
            : debtor,
        ),
      };

    case DELETE_DEBTOR_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          assets: state.current.assets.filter(
            (asset) => asset._id !== action.payload,
          ),
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                assets: state.current.assets.filter(
                  (asset) => asset._id !== action.payload,
                ),
              }
            : debtor,
        ),
      };
    case DELETE_DEBTOR_BALANCE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          balance: state.current.balance.filter(
            (balanceEvent) => balanceEvent._id !== action.payload,
          ),
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                balance: state.current.balance.filter(
                  (balanceEvent) => balanceEvent._id !== action.payload,
                ),
              }
            : debtor,
        ),
      };
    case EDIT_DEBTOR_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          addresses: state.current.addresses.map((address) =>
            address._id === action.payload._id ? action.payload : address,
          ),
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                addresses: state.current.addresses.map((address) =>
                  address._id === action.payload._id ? action.payload : address,
                ),
              }
            : debtor,
        ),
      };

    case EDIT_DEBTOR_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          phones: state.current.phones.map((phone) =>
            phone._id === action.payload._id ? action.payload : phone,
          ),
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                phones: state.current.phones.map((phone) =>
                  phone._id === action.payload._id ? action.payload : phone,
                ),
              }
            : debtor,
        ),
      };

    case EDIT_DEBTOR_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          bankAccounts: state.current.bankAccounts.map((bankAccount) =>
            bankAccount._id === action.payload._id
              ? action.payload
              : bankAccount,
          ),
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                bankAccounts: state.current.bankAccounts.map((bankAccount) =>
                  bankAccount._id === action.payload._id
                    ? action.payload
                    : bankAccount,
                ),
              }
            : debtor,
        ),
      };
    case EDIT_DEBTOR_BALANCE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          balance: state.current.balance.map((balanceEvent) =>
            balanceEvent._id === action.payload._id
              ? action.payload
              : balanceEvent,
          ),
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                balance: state.current.balance.map((balanceEvent) =>
                  balanceEvent._id === action.payload._id
                    ? action.payload
                    : balanceEvent,
                ),
              }
            : debtor,
        ),
      };
    case EDIT_DEBTOR_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          assets: state.current.assets.map((asset) =>
            asset._id === action.payload._id ? action.payload : asset,
          ),
        },
        data: state.data.map((debtor) =>
          debtor._id === state.current._id
            ? {
                ...state.current,
                assets: state.current.assets.map((asset) =>
                  asset._id === action.payload._id ? action.payload : asset,
                ),
              }
            : debtor,
        ),
      };

    case CHANGE_DEBTOR_DEFAULT_SUBDOCUMENT_SUCCESS:
      if (action.payload.type === 'assets') {
        return {
          ...state,
          loading: false,
          current: {
            ...state.current,
            assets: state.current.assets.map((asset) =>
              asset._id === action.payload.id
                ? { ...asset, defaultAsset: true }
                : { ...asset, defaultAsset: false },
            ),
          },
          data: state.data.map((debtor) =>
            debtor._id === state.current._id
              ? {
                  ...state.current,
                  assets: state.current.assets.map((asset) =>
                    asset._id === action.payload.id
                      ? { ...asset, defaultAsset: true }
                      : { ...asset, defaultAsset: false },
                  ),
                }
              : debtor,
          ),
        };
      } else if (action.payload.type === 'phones') {
        return {
          ...state,
          loading: false,
          current: {
            ...state.current,
            phones: state.current.phones.map((phone) =>
              phone._id === action.payload.id
                ? { ...phone, defaultPhoneNumber: true }
                : { ...phone, defaultPhoneNumber: false },
            ),
          },
          data: state.data.map((debtor) =>
            debtor._id === state.current._id
              ? {
                  ...state.current,
                  phones: state.current.phones.map((phone) =>
                    phone._id === action.payload.id
                      ? { ...phone, defaultPhoneNumber: true }
                      : { ...phone, defaultPhoneNumber: false },
                  ),
                }
              : debtor,
          ),
        };
      } else if (action.payload.type === 'addresses') {
        return {
          ...state,
          loading: false,
          current: {
            ...state.current,
            addresses: state.current.addresses.map((address) =>
              address._id === action.payload.id
                ? { ...address, defaultAddress: true }
                : { ...address, defaultAddress: false },
            ),
          },
          data: state.data.map((debtor) =>
            debtor._id === state.current._id
              ? {
                  ...state.current,
                  addresses: state.current.addresses.map((address) =>
                    address._id === action.payload.id
                      ? { ...address, defaultAddress: true }
                      : { ...address, defaultAddress: false },
                  ),
                }
              : debtor,
          ),
        };
      } else if (action.payload.type === 'bank-accounts') {
        return {
          ...state,
          loading: false,
          current: {
            ...state.current,
            bankAccounts: state.current.bankAccounts.map((bankAccount) =>
              bankAccount._id === action.payload.id
                ? { ...bankAccount, defaultBankAccount: true }
                : { ...bankAccount, defaultBankAccount: false },
            ),
          },
          data: state.data.map((debtor) =>
            debtor._id === state.current._id
              ? {
                  ...state.current,
                  bankAccounts: state.current.bankAccounts.map((bankAccount) =>
                    bankAccount._id === action.payload.id
                      ? { ...bankAccount, defaultBankAccount: true }
                      : { ...bankAccount, defaultBankAccount: false },
                  ),
                }
              : debtor,
          ),
        };
      }
    case GET_DEBTOR_LOGS_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          logs: action.payload,
        },
      };
    case ADD_DEBTOR_LOG_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          logs: [action.payload, ...state.current.logs],
        },
      };
    case EDIT_DEBTOR_LOG_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          logs: state.current.logs.map((el) =>
            el._id === action.payload._id ? action.payload : el,
          ),
        },
      };
    case EDIT_DEBTOR_ASSIGMENT_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          workerId: action.payload,
        },
      };
    default:
      return state;
  }
};
