export const theme = {
  primary: '#4C5BAC',
  secondary: '#3E64FF',
  danger: '#C75454',
  lightDanger: '#ffc4c4',
  green: '#57C754',
  lightGreen: '#F0FFF3',
  black: '#484848',
  lightBlack: '#595959',
  grey: '#f0f0f0',
  darkGrey: '#aeaeae',
  info: '#00a8e8',
  warning: '#fcbf49',
  mq: {
    tablet: `@media (min-width: 768px)`,
    desktop: `@media (min-width: 1024px)`,
    huge: `@media(min-width: 1440px)`,
  },
};
