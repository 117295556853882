import React, { useState } from "react";
import DebtWalletImportView from "./DebtWalletImportView";
import { connect } from "react-redux";
import { importDebtWallet } from "../../redux/modules/debtWallets";

const DebtWalletImportContainer = ({ importDebtWallet }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onClickHandler = () => {
    if (selectedFile) {
      const data = new FormData();
      data.append("debt-wallet", selectedFile);
      importDebtWallet(data);
    }
  };

  return (
    <DebtWalletImportView
      onChangeHandler={onChangeHandler}
      onClickHandler={onClickHandler}
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  importDebtWallet: (file) => dispatch(importDebtWallet(file)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebtWalletImportContainer);
