import React from 'react';
import Modal from '../../../shared/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import Input from '@material-ui/core/TextField';
import * as Yup from 'yup';

const EditUserModal = ({
  isEditUserModal,
  setEditUserModal,
  updateAuthenticatedUser,
}) => {
  const initialValues = {
    name: isEditUserModal.user?.name,
    surname: isEditUserModal.user?.surname,
    email: isEditUserModal.user?.email,
  };

  const AddDebtorSchema = Yup.object().shape({
    name: Yup.string().required('Imię jest wymagane'),
    surname: Yup.string().required('Nazwisko jest wymagane'),
    email: Yup.string().email().required('Email jest wymagany'),
  });

  return (
    <Modal
      title="Edycja użytkownika"
      show={isEditUserModal.isOpen}
      exitButton={true}
      onCancel={() => {
        setEditUserModal({ isOpen: false, user: null });
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AddDebtorSchema}
        onSubmit={async (values) => {
          updateAuthenticatedUser(values, isEditUserModal.user._id);
          setEditUserModal({ isOpen: false, user: null });
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          setFieldError,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InputRow>
              <Input
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                error={errors.name}
                label="Imię"
              />
              <Input
                name="surname"
                type="text"
                onChange={handleChange}
                value={values.surname}
                error={errors.surname}
                label="Nazwisko"
              />
            </InputRow>
            <InputRow>
              <Input
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                error={errors.email}
                label="Email"
              />
            </InputRow>
            <CenterBox>
              <Button type="submit" variant="primary">
                Potwierdź
              </Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditUserModal;

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    width: 48%;
  }
`;
