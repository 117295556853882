import React from "react";
import styled, { css } from "styled-components";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DescriptionIcon from '@material-ui/icons/Description';

const DocumentsView = ({ documents }) => {
  return (
    <Wrapper>
      <Title>Dokumenty</Title>
    <Documents>
      <StyledLink to="/documents/new">
        <ServiceCardContainer>
          <StyledAddCircleIcon />
          <Description>Dodaj...</Description>
        </ServiceCardContainer>
      </StyledLink>
      {documents.map((doc) => (
        <StyledLink key={doc._id} to={`/documents/${doc._id}`}>
          <ServiceCardContainer>
            <StyledDescriptionIcon />
            <Description>{doc.name}</Description>
          </ServiceCardContainer>
        </StyledLink>
      ))}
      </Documents>
    </Wrapper>
  );
};

export default DocumentsView;

const Title = styled.h1``;

const Wrapper = styled.div`
  margin: 20px;
  margin-left: 85px;
  height: calc(100vh - 40px);
`;

const ServiceCardContainer = styled(Paper)`
  margin-right: 20px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const iconsStyles = css`
  width: 100px;
  height: 100px;
  color: ${({ theme }) => theme.primary};
`;

const StyledAddCircleIcon = styled(AddCircleIcon)`
 ${iconsStyles};
`;

const StyledDescriptionIcon = styled(DescriptionIcon)`
 ${iconsStyles};
`;

const Description = styled.p``;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 10px;
`;

const Documents = styled.div`
display: flex;
flex-wrap: wrap;
`;


