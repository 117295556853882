import axios from 'axios';
import { countInterest } from '../utils';
import moment from 'moment';

const DEBT_REQUEST = 'DEBT_REQUEST';
const DEBT_FAIL = 'DEBT_FAIL';
const ADD_DEBT_SUCCESS = 'ADD_DEBT_SUCCESS';
const ADD_DEBT_WITH_DOCUMENTS_SUCCESS = 'ADD_DEBT_WITH_DOCUMENTS_SUCCESS';
const EDIT_DEBT_SUCCESS = 'EDIT_DEBT_SUCCESS';
const DELETE_DEBT_SUCCESS = 'DELETE_DEBT_SUCCESS';
const GET_DEBTS_SUCCESS = 'GET_DEBTS_SUCCESS';
const FILTER_DEBTS_SUCCESS = 'FILTER_DEBTS_SUCCESS';
const SET_CURRENT_DEBT = 'SET_CURRENT_DEBT';
const GET_DEBT_SUCCESS = 'GET_DEBT_SUCCESS';
const EDIT_DEBTS_SUCCESS = 'EDIT_DEBTS_SUCCESS';
const ADD_DEBT_DOCUMENTS_TO_DEBT_SUCCESS = 'ADD_DEBT_DOCUMENTS_TO_DEBT_SUCCESS';
const ASSIGN_BAILIFF_SUCCESS = 'ASSIGN_BAILIFF_SUCCESS';

const debtRequest = () => ({
  type: DEBT_REQUEST,
});

const debtFail = (err) => ({
  type: DEBT_FAIL,
  payload: err,
});

export const setCurrentDebt = (debtID) => ({
  type: SET_CURRENT_DEBT,
  payload: debtID,
});

export const editDebt = (debtBody, debtID) => async (dispatch) => {
  dispatch(debtRequest());
  try {
    const response = await axios.patch(`/debts/${debtID}`, debtBody);
    dispatch(editDebtSuccess(response.data));
  } catch (err) {
    dispatch(debtFail(err));
  }
};

const editDebtSuccess = (debtBody) => ({
  type: EDIT_DEBT_SUCCESS,
  payload: debtBody,
});

export const editDebts = (debtsBody, debtWallet) => async (dispatch) => {
  dispatch(debtRequest());
  try {
    const response = await axios.patch(
      `/debts/?debtWallet=${debtWallet}`,
      debtsBody,
    );
    dispatch(editsDebtSuccess(response.data));
  } catch (err) {
    dispatch(debtFail(err));
  }
};

const editsDebtSuccess = (debtBody) => ({
  type: EDIT_DEBTS_SUCCESS,
  payload: debtBody,
});

export const deleteDebt = (debtID) => async (dispatch) => {
  dispatch(debtRequest());
  try {
    await axios.delete(`/debts/${debtID}`);
    dispatch(deleteDebtSuccess(debtID));
  } catch (err) {
    dispatch(debtFail(err));
  }
};

const deleteDebtSuccess = (debtID) => ({
  type: DELETE_DEBT_SUCCESS,
  payload: debtID,
});

export const addDebt = (debtBody) => async (dispatch) => {
  dispatch(debtRequest());
  try {
    const response = await axios.post(`/debts`, debtBody);
    dispatch(addDebtSuccess(response.data));
    return response.data;
  } catch (err) {
    dispatch(debtFail(err));
  }
};

const addDebtSuccess = (debtBody) => ({
  type: ADD_DEBT_SUCCESS,
  payload: debtBody,
});

export const addDebtWithDocuments = (debtWithDocumentsFormData) => async (
  dispatch,
) => {
  dispatch(debtRequest());
  try {
    const response = await axios.post(
      `/debts/with-documents`,
      debtWithDocumentsFormData,
    );
    dispatch(addDebtWithDocumentsSuccess(response.data));
    return response.data;
  } catch (err) {
    dispatch(debtFail(err));
  }
};

const addDebtWithDocumentsSuccess = (debtResponseBody) => ({
  type: ADD_DEBT_WITH_DOCUMENTS_SUCCESS,
  payload: debtResponseBody,
});

export const addDebtDocumentsToDebt = (debtId, debtDocuments) => async (
  dispatch,
) => {
  dispatch(debtRequest());
  try {
    const response = await axios.post(
      `/debts/${debtId}/debt-documents/collection`,
      debtDocuments,
    );
    dispatch(addDebtDocumentsToDebtSuccess(response.data));
    return response.data;
  } catch (err) {
    dispatch(debtFail(err));
  }
};

const addDebtDocumentsToDebtSuccess = (debtResponseBody) => ({
  type: ADD_DEBT_DOCUMENTS_TO_DEBT_SUCCESS,
  payload: debtResponseBody,
});

export const getDebts = (pageNumber, pageSize, searchTerm = '') => async (
  dispatch,
) => {
  dispatch(debtRequest());
  try {
    const response = await axios.get(
      `/debts?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
    );
    let paginationMetadata;
    if (response?.headers?.pagination) {
      paginationMetadata = JSON.parse(response.headers.pagination);
    }
    dispatch(getDebtsSuccess(response.data, paginationMetadata));
  } catch (err) {
    dispatch(debtFail(err));
  }
};

const getDebtsSuccess = (debts, paginationMetadata) => ({
  type: GET_DEBTS_SUCCESS,
  payload: debts,
  paginationMetadata: paginationMetadata,
});

export const getDebtById = (debtID) => async (dispatch) => {
  dispatch(debtRequest());
  try {
    const response = await axios.get(`/debts/${debtID}`);
    dispatch(getDebtSuccess(response.data));
  } catch (err) {
    dispatch(debtFail(err));
  }
};

const getDebtSuccess = (debt) => ({
  type: GET_DEBT_SUCCESS,
  payload: debt,
});

export const getDebtsByDebtorId = (debtorId) => async (dispatch) => {
  dispatch(debtRequest());
  try {
    const response = await axios.get(`/debts/by-debtor?debtor=${debtorId}`);
    dispatch(getDebtsSuccess(response.data));
  } catch (err) {
    dispatch(debtFail(err));
  }
};

export const getDebtsWithDebtor = (pageNumber, pageSize, searchTerm) => async (
  dispatch,
) => {
  dispatch(debtRequest());
  try {
    const response = await axios.get(
      `/debts/with-debtor?pageSize=${pageSize}&pageNumber=${pageNumber}&searchTerm=${searchTerm}`,
    );
    dispatch(getDebtsSuccess(response.data));
  } catch (err) {
    dispatch(debtFail(err));
  }
};

export const filterDebts = (search) => async (dispatch) => {
  dispatch(debtRequest());
  try {
    const response = await axios.get(`/debts?searchTerm=${search}`);
    dispatch(filterDebtsSuccess(response.data));
  } catch (err) {
    dispatch(debtFail(err));
  }
};

export const getDebtsToAssign = () => async (dispatch) => {
  dispatch(debtRequest());
  try {
    const response = await axios.get(`/debts/debts-bailiff-assign`);
    dispatch(getDebtsSuccess(response.data));
  } catch (err) {
    dispatch(debtFail(err));
  }
};

const filterDebtsSuccess = (debts) => ({
  type: FILTER_DEBTS_SUCCESS,
  payload: debts,
});

const initialState = {
  data: [],
  loading: false,
  error: null,
  current: null,
  paginationMetadata: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DEBT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DEBT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_DEBT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
        current: action.payload,
      };
    case ADD_DEBT_WITH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
        current: action.payload,
      };
    case ADD_DEBT_DOCUMENTS_TO_DEBT_SUCCESS:
      return {
        ...state,
        // data: [...state.data, action.payload],
        // loading: false,
        // current: action.payload,
      };
    case GET_DEBTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        paginationMetadata: action.paginationMetadata,
        loading: false,
      };
    case EDIT_DEBT_SUCCESS:
      return {
        ...state,
        data: state.data.map((debt) =>
          debt._id === action.payload._id ? action.payload : debt,
        ),
        loading: false,
      };
    case DELETE_DEBT_SUCCESS:
      return {
        ...state,
        data: state.data.filter((debt) => debt._id !== action.payload),
        loading: false,
      };
    case FILTER_DEBTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case SET_CURRENT_DEBT:
      return {
        ...state,
        current: state.data.find((debt) => debt._id === action.payload),
      };
    case GET_DEBT_SUCCESS:
      return {
        ...state,
        current: action.payload,
      };
    case ASSIGN_BAILIFF_SUCCESS:
      return {
        ...state,
        data: state.data.filter(({ _id }) => _id !== action.payload),
      };
    default:
      return state;
  }
};
