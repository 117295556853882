import React from "react";
import styled from "styled-components";

const Label = ({ htmlFor, children }) =>
    <StyledLabel htmlFor={htmlFor}>{children}</StyledLabel>


export default Label;


const StyledLabel = styled.label`
  margin-left: 2px;
  display: block;
  color: ${({theme}) => theme.black};
  font-size: 14px;
`;
