import React from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useDropzone } from "react-dropzone";

const DebtWalletImportView = ({ onChangeHandler, onClickHandler }) => {
  return (
    <ImportContainer>
      <input type="file" onChange={onChangeHandler} />
      <ImportButton type="button" onClick={onClickHandler}>
        IMPORT PORTFELA DŁUGÓW
      </ImportButton>
    </ImportContainer>
  );
};

export default DebtWalletImportView;

const ImportContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 300px;
  margin: 50px auto;
  padding: 50px;
`;

const ImportButton = styled(Button)`
  font-size: 17px;
  padding: 15px;
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;
