import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Editor } from '@tinymce/tinymce-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const AddDocumentTypeView = ({
  name,
  setName,
  addDocumentType,
  content,
  setContent,
  isEdited,
  editedDocument,
  editDocumentType,
  documentID,
  deleteDocumentType,
}) => {
  const history = useHistory();
  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  return (
    <Wrapper>
      <Title>
        {isEdited
          ? 'Edycja szablonu dokumentu'
          : 'Tworzenie szablonu dokumentu'}
      </Title>
      <TopHeader>
        <TextField
          id="standard-basic"
          label="Nazwa"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={name === ''}
            onClick={() =>
              isEdited
                ? editDocumentType(
                    {
                      name,
                      content,
                      isCourt: content.includes('_sąd'),
                    },
                    documentID,
                  )
                : addDocumentType({
                    name,
                    content,
                    isCourt: content.includes('_sąd'),
                  })
            }
          >
            Zapisz
          </Button>
          <Button
            variant="contained"
            color="danger"
            disabled={name === ''}
            onClick={() => {
              if (isEdited) {
                history.push('/documents');
                deleteDocumentType(documentID);
              } else {
                history.push('/documents');
              }
            }}
          >
            Usuń
          </Button>
        </div>
      </TopHeader>
      <Paper>
        <Editor
          initialValue={isEdited ? editedDocument.content : ''}
          init={{
            setup(editor) {
              editor.ui.registry.addMenuButton('customInsertButton', {
                text: 'Zmienne',
                fetch(callback) {
                  const items = [
                    {
                      type: 'nestedmenuitem',
                      text: 'Dłużnik',
                      icon: 'user',
                      getSubmenuItems() {
                        return [
                          {
                            type: 'menuitem',
                            text: 'Godność',
                            onAction() {
                              editor.insertContent('{{godność_dłużnik}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Ulica',
                            onAction() {
                              editor.insertContent('{{ulica_dłużnik}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Miasto',
                            onAction() {
                              editor.insertContent('{{miasto_dłużnik}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Kod pocztowy',
                            onAction() {
                              editor.insertContent('{{kod_pocztowy_dłużnik}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Kraj',
                            onAction() {
                              editor.insertContent('{{kraj_dłużnik}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Pesel',
                            onAction() {
                              editor.insertContent('{{pesel_dłużnik}}');
                            },
                          },
                        ];
                      },
                    },
                    {
                      type: 'nestedmenuitem',
                      text: 'Radca prawny',
                      icon: 'user',
                      getSubmenuItems() {
                        return [
                          {
                            type: 'menuitem',
                            text: 'Godność',
                            onAction() {
                              editor.insertContent('{{godność_radca}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Ulica',
                            onAction() {
                              editor.insertContent('{{ulica_radca}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Miasto',
                            onAction() {
                              editor.insertContent('{{miasto_radca}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Kod pocztowy',
                            onAction() {
                              editor.insertContent('{{kod_pocztowy_radca}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Kraj',
                            onAction() {
                              editor.insertContent('{{kraj_radca}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Numer konta',
                            onAction() {
                              editor.insertContent('{{numer_konta_radca}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'NIP',
                            onAction() {
                              editor.insertContent('{{NIP_radca}}');
                            },
                          },
                        ];
                      },
                    },
                    {
                      type: 'nestedmenuitem',
                      text: 'Sąd',
                      icon: 'home',
                      getSubmenuItems() {
                        return [
                          {
                            type: 'menuitem',
                            text: 'Nazwa',
                            onAction() {
                              editor.insertContent('{{nazwa_sąd}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Ulica',
                            onAction() {
                              editor.insertContent('{{ulica_sąd}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Kod pocztowy',
                            onAction() {
                              editor.insertContent('{{kod_pocztowy_sąd}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Numer kontaktowy',
                            onAction() {
                              editor.insertContent('{{numer_kontaktowy_sąd}}');
                            },
                          },
                          {
                            type: 'menuitem',
                            text: 'Fax',
                            onAction() {
                              editor.insertContent('{{fax_sąd}}');
                            },
                          },
                        ];
                      },
                    },
                    {
                      type: 'nestedmenuitem',
                      text: 'Dokument',
                      icon: 'document-properties',
                      getSubmenuItems() {
                        return [
                          {
                            type: 'menuitem',
                            text: 'Kod kreskowy',
                            onAction() {
                              editor.insertContent('{{kod_kreskowy}}');
                            },
                          },
                        ];
                      },
                    },
                  ];
                  callback(items);
                },
              });
            },
            height: 925,
            width: 680,
            menubar: true,
            language: 'pl',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            external_plugins: {
              variable: 'http://yourjavascript.com/14155170228/plugin.js',
            },
            content_style:
              '.tox-notifications-container { display: none } .variable { cursor: default;background-color: #65b9dd;color: #FFF;padding: 2px 8px;border-radius: 3px;font-weight: bold;font-style: normal;font-size: 10px;display: inline-block;line-height: 12px;}',
            toolbar:
              'customInsertButton | undo redo variable | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help',
          }}
          onEditorChange={handleEditorChange}
          onInit={() => {
            // addPlugin()
            setTimeout(() => {
              document.querySelector(
                '.tox-notifications-container',
              ).style.display = 'none';
            }, 1);
          }}
        />
      </Paper>
    </Wrapper>
  );
};

export default AddDocumentTypeView;

const Title = styled.h1`
  font-size: 24px;
`;

const TopHeader = styled.div`
  display: flex;
  margin: 15px 0;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  margin: 20px auto;
`;
