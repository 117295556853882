import React from 'react';
import Modal from '../../../shared/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import Input from '@material-ui/core/TextField';
import * as Yup from 'yup';
import SearchSelect from '../../../shared/Select';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';

const AddLawsuitModal = ({
  isAddLawsuitModal,
  setAddLawsuitModal,
  debts,
  bailiffs,
  addLawsuit,
  setDebtInputValue
}) => {
  const initialValues = {
    number: '',
    debts: [],
    date: '',
    bailiffId: '',
  };

  const AddLawsuitSchema = Yup.object().shape({
    number: Yup.number().required('Wartość jest wymagana'),
    date: Yup.date().required('Data jest wymagana'),
    debts: Yup.array().min(1, 'Wymagany jest minimum jeden dług'),
  });

  const bailiffsOptions = bailiffs.map(({ name, surname, _id }) => ({
    value: _id,
    label: `${name} ${surname}`,
  }));


  return (
    <Modal
      title="Dodawanie sprawy sądowej"
      show={isAddLawsuitModal}
      exitButton={true}
      onCancel={() => {
        setAddLawsuitModal(false);
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AddLawsuitSchema}
        onSubmit={async ({ debts, ...values }) => {
          const debtIds = debts.map(({data: { _id }}) => _id);
          await addLawsuit({ ...values, debtIds });
          setAddLawsuitModal(false);
        }}
      >
        {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <InputRow>
              <Input
                name="number"
                type="text"
                onChange={handleChange}
                value={values.number}
                error={errors.number}
                label="Numer sprawy"
              />
              <Input
                name="date"
                type="date"
                onChange={handleChange}
                value={values.date}
                error={errors.date}
                label="Data założenia"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </InputRow>
            <InputRow>
              <DebtsSelect
                label="Komornik"
                hideError={true}
                name="bailiff"
                placeholder="Wybierz komornika..."
                value={bailiffsOptions.find(
                  ({ value }) => value === values.bailiffId,
                )}
                options={bailiffsOptions}
                onChange={({ value }) => {
                  setFieldValue('bailiffId', value);
                }}
                noOptionsMessage={() => 'Nie ma takiego komornika'}
              />
            </InputRow>
            <InputRow>

            <DebtsSelect
                label="Dług"
                value={''}
                name="debts"
                placeholder="Dodaj dług z listy..."
                options={debts.filter(
                  (el) =>
                    values.debts.findIndex(({data: {_id}}) => _id === el._id) === -1,
                ).map((debt) => {
                  return { label: `${debt?.debtor?.firstName} ${debt?.debtor?.lastName}, ${debt?.debtor?.pesel}, ${debt?.description}`, value: debt?._id, data: debt };
                })}
                onChange={(debt) =>
                  setFieldValue('debts', [...values.debts, debt])
                }
                onInputChange={(value) => setDebtInputValue(value)}
                noOptionsMessage={() => 'Nie ma takiego długu'}
                error={errors.debts}
                resetOnBlur={true}
              />
            </InputRow>
            <DebtList>
              {console.log(values.debts)}
              {values.debts.map(
                ({
                  data: {_id,debtor,description,date}   
                }) => (
                  <DebtItem key={_id}>
                    <Top>
                      <span>{`${debtor?.firstName} ${debtor?.lastName} (${debtor?.pesel})`}</span>
                      <span>{moment(date).format('DD.MM.YYYY')}</span>
                    </Top>
                    <Bot>
                      {description}
                      <StyledCloseIcon
                        fontSize="small"
                        onClick={() =>
                          setFieldValue(
                            'debts',
                            values.debts.filter((el) => el.value !== _id),
                          )
                        }
                      />
                    </Bot>
                  </DebtItem>
                ),
              )}
            </DebtList>

            <CenterBox>
              <SubmitButton type="submit">Dodaj</SubmitButton>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddLawsuitModal;

const FullWidthInput = styled(Input)`
  width: 100%;
`;

const DebtsSelect = styled(SearchSelect)`
  width: 450px;
`;

const Form = styled.form`
  padding-top: 20px;
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  > div {
    width: 48%;
  }
`;

const SubmitButton = styled(Button)`
  color: #fff;
  background-color: ${({ theme }) => theme.primary};
  &:hover {
    background-color: #030b56;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
`;

const StyledInput = styled(Input)`
  width: 48%;
`;

const DebtItem = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  > span:last-child {
    font-weight: 600;
  }
`;

const Bot = styled.div`
  padding-top: 2px;
`;

const DebtList = styled.ul`
  width: 100%;
  margin: 0;
  > div {
    padding: 5px 0;
    border-bottom: 1px solid ${({ theme }) => theme.darkGrey};
    > div:last-child {
      position: relative;
      padding-right: 25px;
    }
  }
`;
