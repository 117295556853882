import React, { useState, useEffect } from 'react';
import PaymentsImportView from './PaymentsImportView';
import { connect } from 'react-redux';
import {
  importPayment,
  setImportedPayments,
} from '../../redux/modules/payments';
import { toast } from 'react-toastify';

const PaymentsImportContainer = ({
  importPayment,
  importedPayments,
  error,
  setImportedPayments,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDataSource, setSelectedDataSource] = useState(0);

  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (error) {
      toast('Wystąpił błąd podczas importu danych.', {
        type: 'error',
      });
      setImportedPayments(null);
    }
  }, [error]);

  useEffect(() => {
    if (importedPayments) {
      if (importedPayments.notClassifiedPayments > 0) {
        toast(
          `Zaimportowano płatności, niestety nie można było zweryfikować ${importedPayments.notClassifiedPayments}, należy je ręcznie przypisać do długów.`,
          {
            type: 'warning',
          },
        );
        setImportedPayments(null);
      } else {
        toast(
          `Zaimportowano ${
            importedPayments.notClassifiedPayments +
            importedPayments.classifiedPayments
          } płatności.`,
          {
            type: 'success',
          },
        );
        setImportedPayments(null);
      }
    }
  }, [importedPayments]);

  const onClickHandler = () => {
    if (selectedFile) {
      const data = new FormData();
      data.append('payments', selectedFile);
      importPayment(data, selectedDataSource);
    } else {
      toast('Nie dodano pliku do zaimportowania danych.', {
        type: 'error',
      });
    }
  };

  return (
    <PaymentsImportView
      onChangeHandler={onChangeHandler}
      onClickHandler={onClickHandler}
      selectedDataSource={selectedDataSource}
      setSelectedDataSource={setSelectedDataSource}
    />
  );
};

const mapStateToProps = (state) => ({
  importedPayments: state.payments.import,
  error: state.payments.error,
});

const mapDispatchToProps = (dispatch) => ({
  importPayment: (file, dataSource) =>
    dispatch(importPayment(file, dataSource)),
  setImportedPayments: (importedPayments) =>
    dispatch(setImportedPayments(importedPayments)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentsImportContainer);
