import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
// import Input from "../Shared/Input";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
// import loginMain from "../Shared/assets/login-main.svg";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Nieprawidłowy email!')
    .required('Email jest wymagany'),
  password: Yup.string()
    .min(2, 'Hasło za krótkie!')
    .max(50, 'Hasło za długie!')
    .required('Hasło jest wymagane'),
});

const LoginView = ({ authenticateUser }) => {
  return (
    <Wrapper>
      <Section>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={async (values, actions) => {
            try {
              await authenticateUser(values);
              actions.setSubmitting(false);
            } catch (error) {
              actions.setErrors({ password: error });
              actions.setSubmitting(false);
            }
          }}
          render={(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Title>Miło Cię widzieć!</Title>
              <TextField
                name="email"
                type="text"
                onChange={props.handleChange}
                value={props.values.email}
                error={props.errors.email}
                label="Email"
              />
              <TextField
                name="password"
                type="password"
                onChange={props.handleChange}
                value={props.values.password}
                error={props.errors.password}
                label="Hasło"
              />
              <Button type="submit" variant="primary">
                Zaloguj
              </Button>
            </Form>
          )}
        />
      </Section>
      <Section>{/* <HeaderImage src={loginMain} /> */}</Section>
    </Wrapper>
  );
};

export default LoginView;

const Section = styled.div`
  width: 50%;
  padding: 10px;
  &:first-of-type {
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 16px;
  }
  &:last-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.h1`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.primary};
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 300px;
`;
