import React, { useEffect, useState } from "react";
import BusinessPartnersView from "./BusinessPartnersView";
import { connect } from "react-redux";
import {
    getBusinessPartners,
    getBusinessPartnersWithPagination,
    addBusinessPartner,
    setCurrentBusinessPartner,
    editBusinessPartner
} from "../../redux/modules/businessPartners";


const BusinessPartnersContainer = ({
  getBusinessPartnersWithPagination,
  addBusinessPartner,
  businessPartners,
  setCurrentBusinessPartner,
  currentBusinessPartner,
  editBusinessPartner,
  paginationMetadata
}) => {
  const [isAddBusinessPartnerModal, setAddBusinessPartnerModal] = useState(false);
  const [isEditBusinessPartnerModal, setEditBusinessPartnerModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (businessPartners?.length === 0) {
      getBusinessPartnersWithPagination(page + 1, rowsPerPage, searchTerm)
    }
  }, []);

  useEffect(() => {
    if (paginationMetadata?.totalCount) {
      setCount(paginationMetadata.totalCount)
    }
  }, [paginationMetadata])

  useEffect(() => {
    getBusinessPartnersWithPagination(page + 1, rowsPerPage, searchTerm)
  }, [page, rowsPerPage, searchTerm])

  useEffect(() => {
    if (!businessPartners || businessPartners?.length === 0) {
      setPage(0)
      if (paginationMetadata?.totalCount) {
        setCount(paginationMetadata.totalCount)
      } else {
        setCount(0)
      }
    }
  }, [businessPartners])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (searchTermInput) => {
    setSearchTerm(searchTermInput);
  };

  return (
    <BusinessPartnersView
      isAddBusinessPartnerModal={isAddBusinessPartnerModal}
      setAddBusinessPartnerModal={setAddBusinessPartnerModal}
      isEditBusinessPartnerModal={isEditBusinessPartnerModal}
      setEditBusinessPartnerModal={setEditBusinessPartnerModal}
      businessPartners={businessPartners}
      addBusinessPartner={addBusinessPartner}
      setCurrentBusinessPartner={setCurrentBusinessPartner}
      currentBusinessPartner={currentBusinessPartner}
      editBusinessPartner={editBusinessPartner}
      handleSearchInputChange={handleSearchInputChange}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      count={count}
      rowsPerPage={rowsPerPage}
    />
  );
};

const mapStateToProps = (state) => ({
  businessPartners: state.businessPartners.data,
  currentBusinessPartner: state.businessPartners.current,
  paginationMetadata: state.businessPartners.paginationMetadata,
});

const mapDispatchToProps = (dispatch) => ({
//   editDebt: (debtBody, debtID) => dispatch(editDebt(debtBody, debtID)),
//   deleteDebt: (debtID) => dispatch(deleteDebt(debtID)),
//   addDebt: (debtBody) => dispatch(addDebt(debtBody)),
//   getDebts: () => dispatch(getDebts()),
//   filterDebts: (search) => dispatch(filterDebts(search)),
//   setCurrentDebt: (debtID) => dispatch(setCurrentDebt(debtID)),
  getBusinessPartners: () => dispatch(getBusinessPartners()),
  getBusinessPartnersWithPagination: (pageNumber, pageSize, searchTerm) =>
    dispatch(getBusinessPartnersWithPagination(pageNumber, pageSize, searchTerm)),
  addBusinessPartner: (businessPartner) => dispatch(addBusinessPartner(businessPartner)),
  setCurrentBusinessPartner: (businessPartnerId) => dispatch(setCurrentBusinessPartner(businessPartnerId)),
  editBusinessPartner: (businessPartnerBody, businessPartnerID) => dispatch(editBusinessPartner(businessPartnerBody, businessPartnerID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPartnersContainer);