import axios from 'axios';

const DOCUMENT_REQUEST = 'DOCUMENT_REQUEST';
const DOCUMENT_FAIL = 'DOCUMENT_FAIL';
const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
const EDIT_CURRENT_DOCUMENT_SUCCESS = 'EDIT_CURRENT_DOCUMENT_SUCCESS';
const SET_CURRENT_DOCUMENT = 'SET_CURRENT_DOCUMENT';
const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
const GET_DEBTOR_DOCUMENTS_SUCCESS = 'GET_DEBTOR_DOCUMENTS_SUCCESS';
const GET_COURTS_REQUEST = 'GET_COURTS_REQUEST';
const GET_COURTS_SUCCESS = 'GET_COURTS_SUCCESS';
const GET_COURTS_FAILED = 'GET_COURTS_FAILED';
const GET_COURT_BY_ID_REQUEST = 'GET_COURT_BY_ID_REQUEST';
const GET_COURT_BY_ID_SUCCESS = 'GET_COURT_BY_ID_SUCCESS';
const GET_COURT_BY_ID_FAILED = 'GET_COURT_BY_ID_FAILED';
const EDIT_DOCUMENT_STATUS_SUCCESS = 'EDIT_DOCUMENT_STATUS_SUCCESS';

const courtsRequest = () => ({
  type: GET_COURTS_REQUEST,
});

const courtsFailed = (err) => ({
  type: GET_COURTS_FAILED,
  payload: err,
});

const courtsSuccess = (courts) => ({
  type: GET_COURTS_SUCCESS,
  payload: courts,
});

export const getCourts = (pageNumber, pageSize, searchTerm) => async (
  dispatch,
) => {
  dispatch(courtsRequest);
  try {
    const response = await axios.get(
      `/courts?pageSize=${pageSize}&pageNumber=${pageNumber}&searchTerm=${searchTerm}`,
    );
    dispatch(courtsSuccess(response.data));
  } catch (err) {
    dispatch(courtsFailed(err));
  }
};

const getCourtByIDRequest = () => ({
  type: GET_COURT_BY_ID_REQUEST,
});

const getCourtByIDFailed = (err) => ({
  type: GET_COURT_BY_ID_FAILED,
  payload: err,
});

const getCourtByIDSuccess = (court) => ({
  type: GET_COURT_BY_ID_SUCCESS,
  payload: court,
});

export const getCourtByID = (ID) => async (dispatch) => {
  dispatch(getCourtByIDRequest());
  try {
    const response = await axios.get(`/courts/${ID}`);
    dispatch(getCourtByIDSuccess(response.data));
  } catch (err) {
    dispatch(getCourtByIDFailed(err));
  }
};

const documentRequest = () => ({
  type: DOCUMENT_REQUEST,
});

const documentFail = (err) => ({
  type: DOCUMENT_FAIL,
  payload: err,
});

export const getDocument = (id) => async (dispatch) => {
  dispatch(documentRequest());
  try {
    const response = await axios.get(`/documents/${id}`);
    dispatch(getDocumentSuccess(response.data));
  } catch (err) {
    dispatch(documentFail(err));
  }
};

const getDocumentSuccess = (document) => ({
  type: GET_DOCUMENT_SUCCESS,
  payload: document,
});

export const addDocument = (document) => async (dispatch) => {
  dispatch(documentRequest());
  try {
    const response = await axios.post(`/documents`, document);
    dispatch(addDocumentSuccess(response.data));
  } catch (err) {
    dispatch(documentFail(err));
  }
};

const addDocumentSuccess = (document) => ({
  type: ADD_DOCUMENT_SUCCESS,
  payload: document,
});

export const editCurrentDocument = (newDocument, documentId) => async (
  dispatch,
) => {
  dispatch(documentRequest());
  try {
    const response = await axios.patch(`/documents/${documentId}`, newDocument);
    dispatch(editCurrentDocumentSuccess(response.data));
  } catch (err) {
    dispatch(documentFail(err));
  }
};

const editCurrentDocumentSuccess = (document) => ({
  type: EDIT_CURRENT_DOCUMENT_SUCCESS,
  payload: document,
});

export const setCurrentDocument = (currentDocument) => ({
  type: SET_CURRENT_DOCUMENT,
  payload: currentDocument,
});

export const getDebtorDocuments = (debtorId) => async (dispatch) => {
  dispatch(documentRequest());
  try {
    const response = await axios.get(`/documents/debtors?debtor=${debtorId}`);
    dispatch(getDebtorDocumentsSuccess(response.data));
  } catch (err) {
    dispatch(documentFail(err));
  }
};

const getDebtorDocumentsSuccess = (documents) => ({
  type: GET_DEBTOR_DOCUMENTS_SUCCESS,
  payload: documents,
});

export const editDocumentStatusByBarcode = (statusBody, barcode) => async (
  dispatch,
) => {
  dispatch(documentRequest());
  try {
    const response = await axios.patch(
      `/documents/barcode/${barcode}`,
      statusBody,
    );
    dispatch(editDocumentStatusByBarcodeSuccess(response.data));
    return response.data;
  } catch (err) {
    dispatch(documentFail(err));
  }
};

const editDocumentStatusByBarcodeSuccess = (document) => ({
  type: EDIT_DOCUMENT_STATUS_SUCCESS,
  payload: document,
});

const initialState = {
  data: [],
  loading: false,
  error: null,
  current: null,
  courts: [],
  currentCourt: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case SET_CURRENT_DOCUMENT:
      return {
        ...state,
        current: action.payload,
      };
    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case EDIT_CURRENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case GET_DEBTOR_DOCUMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case GET_COURTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COURTS_SUCCESS:
      return {
        ...state,
        courts: action.payload,
        loading: false,
      };
    case GET_COURTS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_COURT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COURT_BY_ID_SUCCESS:
      return {
        ...state,
        currentCourt: action.payload,
      };
    case GET_COURT_BY_ID_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case EDIT_DOCUMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
