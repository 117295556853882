import React, { useEffect, useState } from 'react';
import SingleWalletView from './SingleWalletView';
import {
  getDebtWalletById,
  editDebtWallet,
  getDebtWalletDebts,
  getDebtWallets,
} from '../../redux/modules/debtWallets';
import { editDebts } from '../../redux/modules/debts';
import { getBusinessPartners } from '../../redux/modules/businessPartners';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { importDebtsToDebtWallet } from '../../redux/modules/debtWallets';
import { parseCsvToJson, parseCsvHeader } from '../../utils/parse-csv';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const SingleWalletContainer = ({
  getDebtWalletById,
  importDebtsToDebtWallet,
  editDebtWallet,
  currentWallet,
  getBusinessPartners,
  businessPartners,
  getDebtWalletDebts,
  walletDebts,
  getDebtWallets,
  debtWallets,
  editDebts,
  debtsPaginationMetadata,
  importWalletDebtsError,
  importedWalletDebts,
  match: {
    params: { id },
  },
}) => {
  const [isEditWalletModal, setEditWalletModal] = useState(false);
  const [isEditDebtModal, setEditDebtModal] = useState(false);
  const [editedDebt, setEditedDebt] = useState(false);
  const [selectedDebts, setDebtsSelected] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [debtsForImport, setDebtsForImport] = useState([]);
  const [isImportReady, setIsImportReady] = useState(false);
  const [isCombineColumnNamesModal, setIsCombineColumnNamesModal] = useState(
    false,
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const properHeaderNames = [
    'pesel',
    'firstName',
    'lastName',
    'principal',
    'date',
    'description',
  ];

  useEffect(() => {
    getDebtWalletById(id);
    getBusinessPartners();
    getDebtWallets();
    if (walletDebts?.length === 0) {
      getDebtWalletDebts(id, page + 1, rowsPerPage);
    }
  }, []);

  useEffect(() => {
    if (debtsPaginationMetadata?.totalCount) {
      setCount(debtsPaginationMetadata.totalCount);
    }
  }, [debtsPaginationMetadata]);

  useEffect(() => {
    getDebtWalletDebts(id, page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!walletDebts || walletDebts?.length === 0) {
      setPage(0);
      if (debtsPaginationMetadata?.totalCount) {
        setCount(debtsPaginationMetadata.totalCount);
      } else {
        setCount(0);
      }
    }
  }, [walletDebts]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!isEditDebtModal) {
      getDebtWalletDebts(id, page + 1, rowsPerPage);
    }
  }, [isEditDebtModal]);

  useEffect(() => {
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(selectedFile);
    }
  }, [selectedFile]);

  const handleFileRead = ({ target }) => {
    const { result } = target;
    const headerNames = parseCsvHeader(result);
    const properTableFormat = isProperTableFormat(headerNames);
    if (properTableFormat) {
      setIsImportReady(true);
      const debtsTable = parseCsvToJson(result);
      setDebtsForImport(debtsTable);
    } else {
      setIsImportReady(false);
      setIsCombineColumnNamesModal(true);
      const debtsTable = parseCsvToJson(result);
      setDebtsForImport(debtsTable);
    }
  };

  const isProperTableFormat = (headerNames) => {
    return Object.values(properHeaderNames).every((properHeaderName) =>
      headerNames.includes(properHeaderName),
    );
  };

  useEffect(() => {
    if (importedWalletDebts?.length > 0) {
      toast.success(
        `Import długów zakończył się sukcesem.\nLiczba zaimportowanych długów: ${importedWalletDebts.length}.`,
        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 10000 },
      );
    }
  }, [importedWalletDebts]);

  useEffect(() => {
    if (importWalletDebtsError !== null) {
      toast.error('Podczas importu długów wystąpił błąd.\nSpróbuj ponownie.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
  }, [importWalletDebtsError]);

  return (
    <SingleWalletView
      currentWallet={currentWallet}
      businessPartners={businessPartners}
      isEditWalletModal={isEditWalletModal}
      setEditWalletModal={setEditWalletModal}
      editDebtWallet={editDebtWallet}
      getDebtWalletById={getDebtWalletById}
      walletDebts={walletDebts}
      isEditDebtModal={isEditDebtModal}
      setEditDebtModal={setEditDebtModal}
      debtWallets={debtWallets}
      editedDebt={editedDebt}
      setEditedDebt={setEditedDebt}
      selectedDebts={selectedDebts}
      setDebtsSelected={setDebtsSelected}
      editDebts={editDebts}
      setSelectedFile={setSelectedFile}
      selectedFile={selectedFile}
      importDebtsToDebtWallet={importDebtsToDebtWallet}
      debtsForImport={debtsForImport}
      isImportReady={isImportReady}
      setIsImportReady={setIsImportReady}
      setIsCombineColumnNamesModal={setIsCombineColumnNamesModal}
      isCombineColumnNamesModal={isCombineColumnNamesModal}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      count={count}
      rowsPerPage={rowsPerPage}
      getDebtWalletDebts={getDebtWalletDebts}
      importWalletDebtsError={importWalletDebtsError}
      importedWalletDebts={importedWalletDebts}
    />
  );
};

const mapStateToProps = (state) => ({
  currentWallet: state.debtWallets.current,
  businessPartners: state.businessPartners.data,
  walletDebts: state.debtWallets.walletDebts,
  debtWallets: state.debtWallets.data,
  debtsPaginationMetadata: state.debtWallets.debtsPaginationMetadata,
  importWalletDebtsError: state.debtWallets.importWalletDebtsError,
  importedWalletDebts: state.debtWallets.importedWalletDebts,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getDebtWalletById: (walletID) => dispatch(getDebtWalletById(walletID)),
    getBusinessPartners: () => dispatch(getBusinessPartners()),
    editDebtWallet: (debtWallet, debtWalletID) =>
      dispatch(editDebtWallet(debtWallet, debtWalletID)),
    getDebtWalletDebts: (walletID, pageNumber, pageSize) =>
      dispatch(getDebtWalletDebts(walletID, pageNumber, pageSize)),
    getDebtWallets: () => dispatch(getDebtWallets()),
    editDebts: (debtsBody, debtWallet) =>
      dispatch(editDebts(debtsBody, debtWallet)),
    importDebtsToDebtWallet: (debtsFile, debtWalletId) =>
      dispatch(importDebtsToDebtWallet(debtsFile, debtWalletId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleWalletContainer),
);
