import React, { useEffect, useState } from 'react';
import AddPaymentView from './AddPaymentView';
import { addPayment, setCurrentPayment } from '../../redux/modules/payments';
import { getDebtsWithDebtor } from '../../redux/modules/debts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const AddDebtContainer = ({
  addPayment,
  debts,
  getDebtsWithDebtor,
  currentPayment,
  history,
  setCurrentPayment,
}) => {
  const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay], // Only re-call effect if value or delay changes
    );

    return debouncedValue;
  };

  const [debtInputValue, setDebtInputValue] = useState('');
  const debouncedInputValue = useDebounce(debtInputValue, 200);
  const [paymentReseted, setPaymentReseted] = useState(false);
  const [warningModal, setWarningModal] = useState({
    shown: false,
    question: null,
    onAccept: null,
    questionTitle: null,
  });

  useEffect(
    () => {
      getDebtsWithDebtor(1, 20, debouncedInputValue);
    },
    [debouncedInputValue], // Only call effect if debounced search term changes
  );

  useEffect(() => {
    setCurrentPayment(null);
    getDebtsWithDebtor(1, 20, debouncedInputValue);
    setPaymentReseted(true);
  }, []);

  useEffect(() => {
    if (currentPayment && paymentReseted) {
      history.push(`/payments/${currentPayment?._id}`);
    }
  }, [currentPayment]);
  
  return (
    <AddPaymentView
      addPayment={addPayment}
      debts={debts}
      setDebtInputValue={setDebtInputValue}
      warningModal={warningModal}
      setWarningModal={setWarningModal}
    />
  );
};

const mapStateToProps = (state) => ({
  debts: state.debts.data,
  currentPayment: state.payments.current,
});

const mapDispatchToProps = (dispatch) => ({
  addPayment: (paymentBody) => dispatch(addPayment(paymentBody)),
  getDebtsWithDebtor: (pageNumber, pageSize, searchTerm) =>
    dispatch(getDebtsWithDebtor(pageNumber, pageSize, searchTerm)),
  setCurrentPayment: (payment) => dispatch(setCurrentPayment(payment)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddDebtContainer),
);
