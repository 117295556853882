import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { authenticateUser } from '../../redux/modules/user';
import LoginView from './LoginView';

const LoginContainer = ({ authenticateUser, user, history }) => {
  useEffect(() => {
    if (user?.email) {
      history.push('/');
    }
  }, [user]);

  return <LoginView authenticateUser={authenticateUser} />;
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    authenticateUser: (user) => dispatch(authenticateUser(user)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginContainer),
);
