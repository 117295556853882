import React from "react";
import Modal from "../../../shared/Modal";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import Input from "@material-ui/core/TextField";
import * as Yup from "yup";

const AddDebtorModal = ({
    isAddDebtorModal,
    setAddDebtorModal,
    addDebtor
}) => {


    const initialValues = {
        name: "",
        surname: "",
        pesel: "",
    };

    const AddDebtorSchema = Yup.object().shape({
        name: Yup.string()
            .required("Imię jest wymagane"),
        surname: Yup.string()
            .required("Nazwisko jest wymagane"),
            pesel: Yup.string()
            .min(11, "Za krótka wartość")
            .max(11, "Za długa wartość")
            .required("Pesel jest wymagany")
            .test('test-compare a few values', 'Pesel jest niepoprawny ',
              function (value) {
                if(value){
                  let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
                  let sum = 0;
                  let controlNumber = parseInt(value.substring(10, 11));
                  for (let i = 0; i < weight.length; i++) {
                      sum += (parseInt(value.substring(i, i + 1)) * weight[i]);
                  }
                  sum = sum % 10;
                  return 10 - sum === controlNumber;
                } else {
                  return false
                }
            })
        });

    return (
        <Modal
            title="Dodawanie Dłużnika"
            show={isAddDebtorModal}
            exitButton={true}
            onCancel={() => {
                setAddDebtorModal(false);
            }}
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={AddDebtorSchema}
                onSubmit={async values => {
                    addDebtor(values.name, values.surname, values.pesel)
                    setAddDebtorModal(false);
                }}
            >
                {({
                    values,
                    errors,
                    setFieldValue,
                    setFieldError,
                    handleChange,
                    handleBlur,
                    handleSubmit
                }) => (
                        <Form onSubmit={handleSubmit}>
                            <InputRow>
                                <Input
                                    name="name"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.name}
                                    error={errors.name}
                                    label="Imię"
                                />
                                <Input
                                    name="surname"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.surname}
                                    error={errors.surname}
                                    label="Nazwisko"
                                />
                            </InputRow>
                            <Input
                                name="pesel"
                                type="text"
                                onChange={handleChange}
                                value={values.pesel}
                                error={errors.pesel}
                                label="PESEL"
                            />
                            <CenterBox>
                                <Button type="submit" variant="primary">
                                    Potwierdź
              </Button>
                            </CenterBox>
                        </Form>
                    )}
            </Formik>
        </Modal>
    );
};

export default AddDebtorModal;

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const AddingNewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin: 10px auto;
`;
const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    width: 48%;
  }
`;
const UserWrapper = styled.div`
     background-color: #c4c4c4;
    color: #fff;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    margin: 5px 0;
    `
const UserInfo = styled.p`
color: #fff;
margin: 0 5px;
`

const AddPatientNoOption = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const AddPatientButton = styled.button`
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  padding: 0.25em 0.65em;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.p`
cursor: pointer;
    color: red;
    font-size: 16px;
`