import React, { useState, useEffect } from 'react';
import SingleDebtorView from './SingleDebtorView';
import { connect } from 'react-redux';
import { getDebtorDocuments } from '../../redux/modules/document';
import { getDebtsByDebtorId } from '../../redux/modules/debts';
import {
  getDebtorById,
  addDebtorAddress,
  addDebtorPhone,
  addDebtorAsset,
  addBankAccount,
  deleteDebtorAddress,
  deleteDebtorPhone,
  deleteDebtorBankAccount,
  deleteDebtorAsset,
  editDebtorAddress,
  editDebtorPhone,
  editDebtorBankAccount,
  editDebtorAsset,
  changeDebtorSubdocument,
  addDebtorBalanceEvent,
  deleteDebtorBalanceEvent,
  editDebtorBalanceEvent,
  addDebtorLog,
  editDebtorLog,
  editDebtorAssignment,
  getDebtorLogs,
} from '../../redux/modules/debtors';
import { withRouter } from 'react-router-dom';
import { getPaymentsByDebtorId } from '../../redux/modules/payments';
import { getAllUsers } from '../../redux/modules/user';

const SingleDebtorContainer = ({
  match,
  currentDebtor,
  getDebtorById,
  addDebtorAddress,
  addDebtorPhone,
  addBankAccount,
  addDebtorAsset,
  deleteDebtorAddress,
  deleteDebtorPhone,
  deleteDebtorBankAccount,
  deleteDebtorAsset,
  editDebtorAddress,
  editDebtorPhone,
  editDebtorBankAccount,
  editDebtorAsset,
  changeDebtorSubdocument,
  addDebtorBalanceEvent,
  deleteDebtorBalanceEvent,
  editDebtorBalanceEvent,
  debtorDocuments,
  getDebtorDocuments,
  history,
  getDebtsByDebtorId,
  debtorDebts,
  debtorPayments,
  getPaymentsByDebtorId,
  users,
  userId,
  getAllUsers,
  addDebtorLog,
  editDebtorLog,
  editDebtorAssignment,
  getDebtorLogs,
}) => {
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  const [isAddressEditModalOpen, setAddressEditModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [isPhoneModalOpen, setPhoneModalOpen] = useState(false);
  const [isPhoneEditModalOpen, setPhoneEditModalOpen] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(null);

  const [isBankAccountModalOpen, setBankAccountModalOpen] = useState(false);
  const [isEditBankAccountModalOpen, setEditBankAccountModalOpen] = useState(
    false,
  );
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  const [isAssetModalOpen, setAssetModalOpen] = useState(false);
  const [isEditAssetModalOpen, setEditAssetModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const [isBalanceModalOpen, setBalanceModalOpen] = useState(false);
  const [isEditBalanceModalOpen, setEditBalanceModalOpen] = useState(false);
  const [selectedBalanceEvent, setSelectedBalanceEvent] = useState(null);

  const [rightTabsValue, setRightTabsValue] = useState(0);

  const [isAssignModalOpen, setAssignModalOpen] = useState(false);

  const [logModal, setLogModal] = useState({ isOpen: false, currentLog: null });
  const [logPage, setLogPage] = useState(0);

  const [warningModal, setWarningModal] = useState({
    shown: false,
    question: null,
    onAccept: null,
    questionTitle: null,
  });

  useEffect(() => {
    getDebtorById(match.params.id, true);
    getAllUsers();
    // if (!(currentDebtor?._id == match.params.id)) {
    //   getDebtorById(match.params.id)
    // }
  }, []);

  useEffect(() => {
    if (currentDebtor?._id) {
      getDebtorLogs(currentDebtor?._id, 4, logPage * 4);
    }
  }, [logPage]);

  // useEffect(() => {
  //   if (currentDebtor?._id) {
  //     getDebtorLogs(currentDebtor?._id, 4, logPage * 4);
  //   }
  // }, [currentDebtor?._id]);

  useEffect(() => {
    if (rightTabsValue === 1) {
      getDebtorDocuments(match.params.id);
    } else if (rightTabsValue === 2) {
      getDebtsByDebtorId(match.params.id);
    } else if (rightTabsValue === 3) {
      getPaymentsByDebtorId(match.params.id);
    }
  }, [rightTabsValue]);

  return (
    <SingleDebtorView
      debtor={currentDebtor}
      isAddressModalOpen={isAddressModalOpen}
      setAddressModalOpen={setAddressModalOpen}
      isPhoneModalOpen={isPhoneModalOpen}
      setPhoneModalOpen={setPhoneModalOpen}
      isBankAccountModalOpen={isBankAccountModalOpen}
      setBankAccountModalOpen={setBankAccountModalOpen}
      isAssetModalOpen={isAssetModalOpen}
      setAssetModalOpen={setAssetModalOpen}
      addDebtorAddress={addDebtorAddress}
      addDebtorPhone={addDebtorPhone}
      addBankAccount={addBankAccount}
      addDebtorAsset={addDebtorAsset}
      deleteDebtorAddress={deleteDebtorAddress}
      deleteDebtorPhone={deleteDebtorPhone}
      deleteDebtorBankAccount={deleteDebtorBankAccount}
      deleteDebtorAsset={deleteDebtorAsset}
      warningModal={warningModal}
      setWarningModal={setWarningModal}
      isAddressEditModalOpen={isAddressEditModalOpen}
      setAddressEditModalOpen={setAddressEditModalOpen}
      selectedAddress={selectedAddress}
      setSelectedAddress={setSelectedAddress}
      editDebtorAddress={editDebtorAddress}
      editDebtorPhone={editDebtorPhone}
      isPhoneEditModalOpen={isPhoneEditModalOpen}
      setPhoneEditModalOpen={setPhoneEditModalOpen}
      selectedPhone={selectedPhone}
      setSelectedPhone={setSelectedPhone}
      editDebtorBankAccount={editDebtorBankAccount}
      editDebtorAsset={editDebtorAsset}
      isEditBankAccountModalOpen={isEditBankAccountModalOpen}
      setEditBankAccountModalOpen={setEditBankAccountModalOpen}
      selectedBankAccount={selectedBankAccount}
      setSelectedBankAccount={setSelectedBankAccount}
      isEditAssetModalOpen={isEditAssetModalOpen}
      setEditAssetModalOpen={setEditAssetModalOpen}
      selectedAsset={selectedAsset}
      setSelectedAsset={setSelectedAsset}
      changeDebtorSubdocument={changeDebtorSubdocument}
      isBalanceModalOpen={isBalanceModalOpen}
      setBalanceModalOpen={setBalanceModalOpen}
      isEditBalanceModalOpen={isEditBalanceModalOpen}
      setEditBalanceModalOpen={setEditBalanceModalOpen}
      selectedBalanceEvent={selectedBalanceEvent}
      setSelectedBalanceEvent={setSelectedBalanceEvent}
      addDebtorBalanceEvent={addDebtorBalanceEvent}
      deleteDebtorBalanceEvent={deleteDebtorBalanceEvent}
      editDebtorBalanceEvent={editDebtorBalanceEvent}
      rightTabsValue={rightTabsValue}
      setRightTabsValue={setRightTabsValue}
      debtorDocuments={debtorDocuments}
      history={history}
      debtorDebts={debtorDebts}
      debtorPayments={debtorPayments}
      isAssignModalOpen={isAssignModalOpen}
      setAssignModalOpen={setAssignModalOpen}
      users={users}
      userId={userId}
      logModal={logModal}
      setLogModal={setLogModal}
      addDebtorLog={addDebtorLog}
      editDebtorLog={editDebtorLog}
      editDebtorAssignment={editDebtorAssignment}
      logPage={logPage}
      setLogPage={setLogPage}
    />
  );
};

const mapStateToProps = (state) => ({
  debtors: state.debtors.data,
  currentDebtor: state.debtors.current,
  debtorDocuments: state.documents.data,
  debtorDebts: state.debts.data,
  debtorPayments: state.payments.data,
  users: state.user.users,
  userId: state.user.user?._id,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: () => dispatch(getAllUsers()),
    getDebtorById: (debtorId, populateLogs) =>
      dispatch(getDebtorById(debtorId, populateLogs)),
    addDebtorAddress: (address, id) => dispatch(addDebtorAddress(address, id)),
    addDebtorPhone: (phoneNumber, id) =>
      dispatch(addDebtorPhone(phoneNumber, id)),
    addBankAccount: (bankAccountNumber, id) =>
      dispatch(addBankAccount(bankAccountNumber, id)),
    addDebtorAsset: (asset, id) => dispatch(addDebtorAsset(asset, id)),
    deleteDebtorAddress: (debtorId, addressId) =>
      dispatch(deleteDebtorAddress(debtorId, addressId)),
    deleteDebtorPhone: (debtorId, phoneId) =>
      dispatch(deleteDebtorPhone(debtorId, phoneId)),
    deleteDebtorBankAccount: (debtorId, bankAccountId) =>
      dispatch(deleteDebtorBankAccount(debtorId, bankAccountId)),
    deleteDebtorAsset: (debtorId, assetId) =>
      dispatch(deleteDebtorAsset(debtorId, assetId)),
    editDebtorAddress: (address, debtorId, addressId) =>
      dispatch(editDebtorAddress(address, debtorId, addressId)),
    editDebtorPhone: (phone, debtorId, phoneId) =>
      dispatch(editDebtorPhone(phone, debtorId, phoneId)),
    editDebtorBankAccount: (bankAccount, debtorId, bankAccountId) =>
      dispatch(editDebtorBankAccount(bankAccount, debtorId, bankAccountId)),
    editDebtorAsset: (asset, debtorId, assetId) =>
      dispatch(editDebtorAsset(asset, debtorId, assetId)),
    editDebtorBalanceEvent: (balanceEvent, debtorId, balanceEventId) =>
      dispatch(editDebtorBalanceEvent(balanceEvent, debtorId, balanceEventId)),
    changeDebtorSubdocument: (subDocType, debtorId, docId) =>
      dispatch(changeDebtorSubdocument(subDocType, debtorId, docId)),
    addDebtorBalanceEvent: (balanceEvent, debtorId) =>
      dispatch(addDebtorBalanceEvent(balanceEvent, debtorId)),
    deleteDebtorBalanceEvent: (balanceEvent, id) =>
      dispatch(deleteDebtorBalanceEvent(balanceEvent, id)),
    getDebtorDocuments: (debtorId) => dispatch(getDebtorDocuments(debtorId)),
    getDebtsByDebtorId: (debtorId) => dispatch(getDebtsByDebtorId(debtorId)),
    getPaymentsByDebtorId: (debtorId) =>
      dispatch(getPaymentsByDebtorId(debtorId)),
    addDebtorLog: (debtorId, logBody) =>
      dispatch(addDebtorLog(debtorId, logBody)),
    editDebtorLog: (logId, logBody) => dispatch(editDebtorLog(logId, logBody)),
    editDebtorAssignment: (debtorId, workerId) =>
      dispatch(editDebtorAssignment(debtorId, workerId)),
    getDebtorLogs: (debtorId, limit, skip) =>
      dispatch(getDebtorLogs(debtorId, limit, skip)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleDebtorContainer),
);
