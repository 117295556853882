import React from 'react';
import Modal from '../../../shared/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import Select from '../../../shared/Select';

const AssignDebtorModal = ({
  isAssignModalOpen,
  setAssignModalOpen,
  users,
  editDebtorAssignment,
  debtor = {},
}) => {
  const workersOptions = users
    ?.filter(({ role }) => role === 'Admin')
    ?.map(({ _id, name, surname }) => ({
      value: _id,
      label: `${name} ${surname}`,
    }));

  const initialValues = {
    workerId: debtor?.workerId ?? '',
  };

  return (
    <Modal
      title="Przypisanie dłużnika"
      show={isAssignModalOpen}
      exitButton={true}
      onCancel={async () => {
        setAssignModalOpen(false);
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async ({ workerId }) => {
          editDebtorAssignment(debtor?._id, workerId);
          setAssignModalOpen(false);
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <StyledSelect
              options={workersOptions}
              value={workersOptions.find(
                ({ value }) => value === values.workerId,
              )}
              onChange={({ value }) => setFieldValue('workerId', value)}
            />
            <CenterBox>
              <Button type="submit" variant="primary">
                Potwierdź
              </Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AssignDebtorModal;

const Form = styled.form`
  padding-top: 30px;
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 350px;
}`}
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
