import React from 'react';
import Modal from '../../../shared/Modal';

const WarningModal = ({
    onAccept,
    question,
    show,
    setModalState,
    questionTitle,
    preventClosing = false
}) => {
    return (
        <Modal
            type="SimpleQuestionModal"
            show={show}
            questionTitle={questionTitle}
            question={question}
            acceptText="Tak"
            cancelText="Nie"
            onAccept={() => {
                onAccept();
                if (!preventClosing) {
                    setModalState({ shown: false, question: null, questionTitle: null, onAccept: null });
                }
            }}
            onCancel={() => setModalState({ shown: false, question: null, questionTitle: null, onAccept: null })}
        />
    )
}

export default WarningModal;