import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AliorLogo from '../../shared/assets/aliorLogo.png';
import ExampleLogo from '../../shared/assets/chaseLogo.png';
import DnbLogo from '../../shared/assets/dnbLogo.jpg';

const PaymentsImportView = ({
  onChangeHandler,
  onClickHandler,
  selectedDataSource,
  setSelectedDataSource,
}) => {
  return (
    <>
      <ImportContainer>
        <SubTitle>Wybierz Dostawcę danych</SubTitle>
        <SelectDataSourceContainer>
          <DataSourceContainer
            isActive={selectedDataSource === 0 ? true : false}
            onClick={() => setSelectedDataSource(0)}
          >
            <DataSourceTitle>Alior Bank CSV</DataSourceTitle>
            <DataSourceRectangleLogo src={AliorLogo} />
          </DataSourceContainer>
          <DataSourceContainer
            isActive={selectedDataSource === 1 ? true : false}
            onClick={() => setSelectedDataSource(1)}
          >
            <DataSourceTitle>DNB MT940</DataSourceTitle>
            <DataSourceRectangleLogo src={DnbLogo} />
          </DataSourceContainer>
          <DataSourceContainer
            isActive={selectedDataSource === 2 ? true : false}
            onClick={() => setSelectedDataSource(2)}
          >
            <DataSourceTitle>Example Bank CSV</DataSourceTitle>
            <DataSourceRectangleLogo src={ExampleLogo} />
          </DataSourceContainer>
        </SelectDataSourceContainer>
        <input type="file" onChange={onChangeHandler} />
        <ImportButton type="button" onClick={onClickHandler}>
          IMPORT PŁATNOŚCI
        </ImportButton>
      </ImportContainer>
    </>
  );
};

export default PaymentsImportView;

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const DataSourceTitle = styled.h5`
  text-align: center;
  margin: 0;
`;

const DataSourceRectangleLogo = styled.img`
  width: 140px;
  height: 70px;
`;

const DataSourceContainer = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.4;
  cursor: pointer;
  margin-bottom: 20px;
  ${({ isActive }) =>
    isActive &&
    `
  opacity: 1;
  border: 2px solid #4c5bac;
  border-radius: 5px;`}
`;

const SelectDataSourceContainer = styled.div`
  display: flex;
`;
const SubTitle = styled.h2``;

const ImportContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 500px;
  margin: 50px auto;
  padding: 50px;
`;

const ImportButton = styled(Button)`
  font-size: 17px;
  padding: 15px;
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;
