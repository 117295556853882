import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import SearchSelect from "../../shared/Select";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
const AddWalletView = ({ addDebtWallet, debtors, businessPartners, businessPartnerFields, setBusinessPartnerFields, handleSearchInputChange }) => {
  const initialValues = {
    name: "",
    date: moment(new Date()).format("yyyy-MM-DD"),
    price: "",
    conditions: []
  };

  const AddWalletSchema = Yup.object().shape({
    name: Yup.string().required("Nazwa jest wymagana"),
    date: Yup.string().required("Data jest wymagana"),
    conditions: Yup.array().test('test1', 'Suma udziału kontrahentów powinna być równa 100%', function(){return this.parent.conditions.reduce((acc,b) => acc + parseInt(b.yield), 0) === 100 ? true : false})
  });

  return (
    <Wrapper>
      <Title>Dodawanie portfela</Title>
      <PaperWrapper>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={AddWalletSchema}
          onSubmit={async (values) => {
          addDebtWallet(values);
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            setFieldError,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <InputRow>
                <TextField
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                  error={errors.name}
                  label="Nazwa"
                />
                <TextField
                  name="date"
                  type="date"
                  onChange={handleChange}
                  value={values.date}
                  error={errors.date}
                  label="Data"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </InputRow>
              <InputRow>
                <TextField
                  name="price"
                  type="number"
                  onChange={handleChange}
                  value={values.price}
                  error={errors.price}
                  label="Wartość"
                />
              </InputRow>
              <BPInputRow>
                <NewDocumentSelect
                  label="Kontrahent"
                  value={{label: businessPartners.find(el => el._id === businessPartnerFields.businessPartner)?.name ,value:businessPartnerFields.businessPartner}}
                  name="conditions"
                  placeholder="Wybierz kontrahenta..."
                  options={businessPartners?.map((bp) => {
                    return {
                      label: `${bp.name}, ${
                        bp.PESEL !== "" ? bp.PESEL : bp.NIP
                      }`,
                      value: bp._id,
                    };
                  }).filter(el => !values.conditions.some(con => con.businessPartner === el.value))}
                  onChange={({ value }) => setBusinessPartnerFields({businessPartner: value, yield: businessPartnerFields.yield})}
                  onInputChange={( value ) => handleSearchInputChange(value)}
                  noOptionsMessage={() => "Nie ma takiego kontrahenta"}
                  error={errors.conditions}
                />
                <FormControl>
                  <PercentageInput
                    id="standard-adornment-weight"
                    type="number"
                    value={businessPartnerFields.yield}
                    onChange={e => setBusinessPartnerFields({businessPartner: businessPartnerFields.businessPartner, yield: e.target.value})}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                      "min": 0,
                      "max": 100
                    }}
                  />
                </FormControl>
                <IconButton aria-label="menu"
                    disabled={businessPartnerFields.businessPartner === ""}
                onClick={() => {
                    setFieldValue('conditions', [...values.conditions, businessPartnerFields]);
                    setBusinessPartnerFields({businessPartner: "", yield: 0})
                    }}>
                  <AddCircleOutlinedIcon />
                </IconButton>
              </BPInputRow>
              <List>
              {values.conditions.map((el) => <ListItem>
                  <ListItemAvatar>
                    {el.yield}%
                  </ListItemAvatar>
                  <ListItemText
                    primary={businessPartners.find(bp => bp._id ===el.businessPartner).name}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => setFieldValue('conditions',values.conditions.filter(cond => cond.businessPartner !== el.businessPartner))}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>)
        }
            </List>
              <CenterBox>
                <Button type="submit" variant="primary">
                  Potwierdź
                </Button>
              </CenterBox>
            </Form>
          )}
        </Formik>
      </PaperWrapper>
    </Wrapper>
  );
};

export default AddWalletView;

const Wrapper = styled.div`
  margin: 20px auto;
    height: calc(100vh - 40px);

`;

const PaperWrapper = styled(Paper)`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const NewDocumentSelect = styled(SearchSelect)`
  width: 100%;
  margin-right: 10px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  > div {
    width: 48%;
  }
`;

const PercentageInput = styled(Input)`
  width: 65px;
  margin-right: 10px;
`;

const BPInputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  align-items: center;
`;
