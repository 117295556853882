import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import styled, { css } from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditDebtModal from './modals/EditDebtModal';
import moment from 'moment';
import { countInterest } from '../../redux/utils';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import Button from '@material-ui/core/Button';
import Carousel, { Modal, ModalGateway } from 'react-images';
import {debtTypeDictionary} from '../../redux/utils'

const SingleDebtView = ({
  isEditDebtModal,
  setEditDebtModal,
  editDebt,
  currentDebt,
  getDebtById,
  checkIfImage,
  checkIfPdf,
  debtors,
  debtWallets,
  businessPartners,
  addDebtDocumentsToDebt,
}) => {
  const [modalShown, setModalShown] = useState(false);
  const [images, setImages] = useState([]);

  return (
    <Wrapper>
      <EditDebtModal
        isEditDebtModal={isEditDebtModal}
        setEditDebtModal={setEditDebtModal}
        editDebt={editDebt}
        currentDebt={currentDebt}
        getDebtById={getDebtById}
        debtors={debtors}
        businessPartners={businessPartners}
        debtWallets={debtWallets}
        addDebtDocumentsToDebt={addDebtDocumentsToDebt}
      />
      <EditIconButton>
        <StyledEditIcon
          onClick={() => {
            setEditDebtModal(true);
          }}
        />
      </EditIconButton>
      <MainContent>
        <StyledTable aria-label="simple table">
          <StyledTableBody>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledPersonOutlineOutlinedIcon />
                Dłużnik:
              </TableCellKey>
              <TableCellValue align="left">
                {currentDebt?.debtor}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledPersonPinOutlinedIcon />
                Właściciel długu:
              </TableCellKey>
              <TableCellValue align="left">
                {currentDebt?.debtOwner}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledAccountBalanceWalletOutlinedIcon />
                Portfel długu:
              </TableCellKey>
              <TableCellValue align="left">
                {currentDebt?.debtWallet}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledInfoOutlinedIcon />
                Numer pozwu:
              </TableCellKey>
              <TableCellValue align="left">
                {currentDebt?.lawsuitNumber}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledLayersOutlinedIcon />
                Typ:
              </TableCellKey>
              <TableCellValue align="left">
                {debtTypeDictionary(currentDebt?.type)}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledMonetizationOnOutlinedIcon />
                Kwota początkowa:
              </TableCellKey>
              <TableCellValue align="left">
                {`${currentDebt?.principal} ${currentDebt?.currency}`}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledMonetizationOnOutlinedIcon />
                Kwota pozostała:
              </TableCellKey>
              <TableCellValue align="left">
                {currentDebt
                  ? currentDebt?.startedRepayment
                    ? `${countInterest(
                        currentDebt?.principalLeft + currentDebt?.interestsLeft,
                        moment()
                          .startOf('day')
                          .diff(
                            currentDebt?.lastPaymentDate?.slice(0, 10),
                            'years',
                            true,
                          ),
                        currentDebt?.interestRate,
                      )} ${currentDebt.currency}`
                    : `${countInterest(
                        currentDebt?.principal,
                        moment()
                          .startOf('day')
                          .diff(currentDebt?.date?.slice(0, 10), 'years', true),
                        currentDebt?.interestRate,
                      )} ${currentDebt.currency}`
                  : null}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledAssignmentOutlinedIcon />
                Podstawa:
              </TableCellKey>
              <TableCellValue align="left">
                {currentDebt?.description}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledEventOutlinedIcon />
                Data:
              </TableCellKey>
              <TableCellValue align="left">
                {new Date(currentDebt?.date).toLocaleDateString()}
              </TableCellValue>
            </StyledTableRow>
          </StyledTableBody>
        </StyledTable>
        <StyledTable>
          <StyledTableRow key={'Dokumenty'} noBorder={true}>
            <TableCellKey scope="row">
              <StyledDescriptionOutlinedIcon />
              Dokumenty:
            </TableCellKey>
            <TableCellValue align="left">
              {currentDebt?.debtDocuments.map((debtDocument) => {
                if (checkIfImage(debtDocument.originalFilename)) {
                  return (
                    <div>
                      <Filename
                        onClick={() => {
                          setImages([
                            {
                              source: `${process.env.REACT_APP_API_URL}/media/debts/${currentDebt?._id}/debt-documents/${debtDocument.filename}`,
                            },
                          ]);
                          setModalShown(true);
                        }}
                      >
                        {debtDocument.originalFilename}
                      </Filename>
                      <Description>
                        {debtDocument.description
                          ? debtDocument.description
                          : 'Brak opisu.'}
                      </Description>
                    </div>
                  );
                } else if (checkIfPdf(debtDocument.originalFilename)) {
                  return (
                    <div>
                      <Filename
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`${process.env.REACT_APP_API_URL}/media/debts/${currentDebt?._id}/debt-documents/${debtDocument.filename}`}
                      >
                        {debtDocument.originalFilename}
                      </Filename>
                      <Description>
                        {debtDocument.description
                          ? debtDocument.description
                          : 'Brak opisu.'}
                      </Description>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <Filename disabled>
                        {debtDocument.originalFilename}
                      </Filename>
                      <Description>
                        {debtDocument.description
                          ? debtDocument.description
                          : 'Brak opisu.'}
                      </Description>
                    </div>
                  );
                }
              })}
            </TableCellValue>
            <ModalGateway>
              {modalShown && (
                <Modal
                  onClose={() => {
                    setModalShown(false);
                    setImages([]);
                  }}
                >
                  <Carousel
                    styles={{
                      view: (base) => ({
                        ...base,
                        maxHeight: '85vh',
                        maxWidth: '90vw',
                        margin: 'auto',
                        '>img': {
                          display: 'block',
                          maxWidth: '90vw',
                          maxHeight: '83vh',
                          width: 'auto',
                          height: 'auto',
                          margin: 'auto auto 0',
                        },
                      }),
                    }}
                    views={images}
                    setModalShown={setModalShown}
                  />
                </Modal>
              )}
            </ModalGateway>
          </StyledTableRow>
        </StyledTable>
      </MainContent>
    </Wrapper>
  );
};

export default SingleDebtView;

const Wrapper = styled(Paper)`
  width: 100%;
  margin: 20px;
  padding: 20px;
  position: relative;
  margin-left: 85px;
`;

const StyledTableBody = styled(TableBody)``;

const StyledTable = styled(Table)`
  width: calc(50% - 20px);
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 1px solid rgba(224, 224, 224);
  position: relative;
  ${({ noBorder }) => noBorder && `border-bottom: none;`}
`;

const TableCellKey = styled(TableCell)`
  width: 220px;
  border-bottom: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  margin: 0;
  padding-left: 0;
`;

const TableCellValue = styled(TableCell)`
  width: 9999px;
  border: none;
`;

const MainContent = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`;

const iconsStyles = css`
  margin-right: 5px;
`;

const StyledPersonOutlineOutlinedIcon = styled(PersonOutlineOutlinedIcon)`
  ${iconsStyles};
`;

const StyledPersonPinOutlinedIcon = styled(PersonPinOutlinedIcon)`
  ${iconsStyles};
`;

const StyledAccountBalanceWalletOutlinedIcon = styled(
  AccountBalanceWalletOutlinedIcon,
)`
  ${iconsStyles};
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  ${iconsStyles};
`;

const StyledLayersOutlinedIcon = styled(LayersOutlinedIcon)`
  ${iconsStyles};
`;

const StyledMonetizationOnOutlinedIcon = styled(MonetizationOnOutlinedIcon)`
  ${iconsStyles};
`;

const StyledDescriptionOutlinedIcon = styled(DescriptionOutlinedIcon)`
  ${iconsStyles};
`;

const StyledEventOutlinedIcon = styled(EventOutlinedIcon)`
  ${iconsStyles};
`;

const StyledAssignmentOutlinedIcon = styled(AssignmentOutlinedIcon)`
  ${iconsStyles};
`;

const StyledEditIcon = styled(EditOutlinedIcon)`
  color: #4c5bac;
`;

const EditIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Filename = styled(Button)`
  font-weight: 600;
`;

const Description = styled.div`
  margin: 8px 15px 20px;
`;
