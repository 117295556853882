import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import ExposureIcon from '@material-ui/icons/Exposure';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ImportExportIcon from '@material-ui/icons/SwapVerticalCircle';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GavelIcon from '@material-ui/icons/Gavel';

const NavigationView = ({ activeTab, user, logoutUser }) => {
  return (
    <>
      <NavigationContainer>
        <NavigationList>
          <NavigationItem>
            <StyledLink active={activeTab === 0 && true} to="/">
              <StyledDashboardIcon active={activeTab === 0 && true} />
            </StyledLink>
          </NavigationItem>
          {user?.role === 'Admin' && (
            <>
              <NavigationItem>
                <StyledLink active={activeTab === 1 && true} to="/debtors-db">
                  <StyledPeopleIcon active={activeTab === 1 && true} />
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink
                  active={activeTab === 2 && true}
                  to="/business-partners"
                >
                  <StyledBusinessIcon active={activeTab === 2 && true} />
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink active={activeTab === 3 && true} to="/documents">
                  <StyledDocumentTypes active={activeTab === 3 && true} />
                </StyledLink>
              </NavigationItem>
            </>
          )}
          <NavigationItem>
            <StyledLink active={activeTab === 4 && true} to="/add-document">
              <>
                <SmallAddIcon active={activeTab === 4 && true} />
                <StyledDescriptionOutlinedIcon
                  active={activeTab === 4 && true}
                />
              </>
            </StyledLink>
          </NavigationItem>
          <NavigationItem>
            <StyledLink active={activeTab === 5 && true} to="/documents-status">
              <StyledMarkunreadMailboxOutlinedIcon
                active={activeTab === 5 && true}
              />
            </StyledLink>
          </NavigationItem>
          {user?.role === 'Admin' && (
            <>
              <NavigationItem>
                <StyledLink active={activeTab === 6 && true} to="/debts">
                  <StyledExposureIcon active={activeTab === 6 && true} />
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink active={activeTab === 7 && true} to="/add-debt">
                  <>
                    <SmallAddIcon active={activeTab === 7 && true} />
                    <StyledExposureIcon active={activeTab === 7 && true} />
                  </>
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink active={activeTab === 8 && true} to="/wallets">
                  <StyledWalletIcon active={activeTab === 8 && true} />
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink active={activeTab === 9 && true} to="/add-wallet">
                  <>
                    <SmallAddIcon active={activeTab === 9 && true} />
                    <StyledWalletIcon active={activeTab === 9 && true} />
                  </>
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink
                  active={activeTab === 10 && true}
                  to="/debt-wallet-import"
                >
                  <>
                    <SmallImportIcon active={activeTab === 10 && true} />
                    <StyledWalletIcon active={activeTab === 10 && true} />
                  </>
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink active={activeTab === 11 && true} to="/payments">
                  <StyledPaymentOutlinedIcon
                    active={activeTab === 11 && true}
                  />
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink active={activeTab === 12 && true} to="/add-payment">
                  <>
                    <StyledPaymentOutlinedIcon
                      active={activeTab === 12 && true}
                    />
                    <SmallAddIcon active={activeTab === 12 && true} />
                  </>
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink
                  active={activeTab === 13 && true}
                  to="/payments-import"
                >
                  <>
                    <SmallImportIcon active={activeTab === 13 && true} />
                    <StyledPaymentOutlinedIcon
                      active={activeTab === 13 && true}
                    />
                  </>
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink
                  active={activeTab === 14 && true}
                  to="/bailiff-assign"
                >
                  <>
                    <StyledAccountBoxIcon active={activeTab === 14 && true} />
                    <SmallAddIcon active={activeTab === 14 && true} />
                  </>
                </StyledLink>
              </NavigationItem>
              <NavigationItem>
                <StyledLink active={activeTab === 15 && true} to="/lawsuits">
                  <>
                    <StyledGavelIcon active={activeTab === 15 && true} />
                  </>
                </StyledLink>
              </NavigationItem>
            </>
          )}
        </NavigationList>
        <NavigationItem logout onClick={() => logoutUser()}>
          <StyledExitToAppIcon />
        </NavigationItem>
      </NavigationContainer>
    </>
  );
};

export default withRouter(NavigationView);

const SmallImportIcon = styled(ImportExportIcon)`
  color: #4c5bac;
  width: 15px;
  height: 15px;
  opacity: 1;
  position: absolute;
  right: 38px;
  top: 36px;
  opacity: 0.6;
  ${({ active }) => active && 'opacity: 1;'}
`;

const SmallAddIcon = styled(AddCircleOutlinedIcon)`
  color: #4c5bac;
  width: 15px;
  height: 15px;
  opacity: 1;
  position: absolute;
  right: 38px;
  top: 36px;
  opacity: 0.6;
  ${({ active }) => active && 'opacity: 1;'}
`;

const iconsStyles = css`
  color: #4c5bac;
  width: 30px;
  height: 30px;
  opacity: 0.6;
  ${({ active }) => active && 'opacity: 1;'}
`;

const StyledDocumentTypes = styled(LibraryBooksIcon)`
  ${iconsStyles}
`;
const StyledBusinessIcon = styled(BusinessOutlinedIcon)`
  ${iconsStyles}
`;

const StyledWalletIcon = styled(AccountBalanceWalletOutlinedIcon)`
  ${iconsStyles};
`;

const StyledDashboardIcon = styled(HomeOutlinedIcon)`
  ${iconsStyles};
`;

const StyledPeopleIcon = styled(PeopleAltOutlinedIcon)`
  ${iconsStyles};
`;

const StyledExposureIcon = styled(ExposureIcon)`
  ${iconsStyles};
`;

const StyledDescriptionOutlinedIcon = styled(DescriptionOutlinedIcon)`
  ${iconsStyles};
`;

const StyledPaymentOutlinedIcon = styled(PaymentOutlinedIcon)`
  ${iconsStyles};
`;

const StyledExitToAppIcon = styled(ExitToAppIcon)`
  ${iconsStyles};
`;

const StyledAccountBoxIcon = styled(AccountBoxIcon)`
  ${iconsStyles};
`;

const StyledGavelIcon = styled(GavelIcon)`
  ${iconsStyles};
`;

const StyledMarkunreadMailboxOutlinedIcon = styled(
  MarkunreadMailboxOutlinedIcon,
)`
  ${iconsStyles};
`;

const NavigationContainer = styled.div`
  width: 65px;
  background-color: #fff;
  height: 100vh;
  position: fixed;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 17.5px;
  position: relative;
  transition: 0.2s;
  border-radius: 10px;
  ${({ active, theme }) =>
    active &&
    `
    background-color: #e4ebfe;
    
    span {
        color: #fff;
    }
    img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(18deg) brightness(109%) contrast(101%);
    }
    &:after {
        
        content: '';
    width: 5px;
    height: 65px;
    background-color: #4C5BAC;
    right: -50px;
    position: absolute;
    border-radius: 2px;
    }
    ${theme.mq.desktop} {
    &:after {
      left: 0px;
    }
    `}
`;
const NavigationList = styled.ul`
  width: 100%;
  margin-top: 20px;
`;

const NavigationItem = styled.li`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ logout }) =>
    logout &&
    `
  position: absolute;
    bottom: 10px;
    width: 100%;
  `}
`;
