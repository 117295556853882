import React, { useState, useCallback, useEffect } from 'react';
import { DropBox } from './DropBox';
import { Box } from './Box';
import update from 'immutability-helper';
import styled from 'styled-components';

const ItemTypes = {
  PESEL: 'pesel',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PRINCIPAL: 'principal',
  DATE: 'date',
  DESCRIPTION: 'description',
  EVERYTHING: 'everything',
};

export const DndContainer = ({
  debtsForImport,
  setIsDropboxesFilled,
  lastDroppedItems,
  setLastDroppedItems,
  properBoxNames,
}) => {
  const [boxes] = useState([
    { name: 'Imię', type: ItemTypes.EVERYTHING },
    { name: 'Nazwisko', type: ItemTypes.EVERYTHING },
    { name: 'Pesel', type: ItemTypes.EVERYTHING },
    { name: 'Kwota', type: ItemTypes.EVERYTHING },
    { name: 'Data', type: ItemTypes.EVERYTHING },
    { name: 'Opis', type: ItemTypes.EVERYTHING },
  ]);

  const [droppedBoxNames, setDroppedBoxNames] = useState([]);

  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }

  const handleDrop = (index, item) => {
    const { name } = item;

    const clonedArray = lastDroppedItems.slice(0);
    clonedArray[index] = name;
    setLastDroppedItems(clonedArray);

    setDroppedBoxNames(
      update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
    );
  };

  useEffect(() => {
    if (lastDroppedItems?.length > 0) {
      if (
        Object.values(properBoxNames).every((properBoxName) =>
          lastDroppedItems.includes(properBoxName),
        )
      ) {
        setIsDropboxesFilled(true);
      } else {
        setIsDropboxesFilled(false);
      }
    }
    console.log(lastDroppedItems);
  }, [lastDroppedItems]);

  return (
    <Container>
      <BoxesContainer>
        {boxes
          .filter((box) => !lastDroppedItems.includes(box.name))
          .map(({ name, type }, index) => (
            <Box
              name={name}
              type={type}
              isDropped={isDropped(name)}
              key={index}
            />
          ))}
      </BoxesContainer>
      <StyledTable>
        {debtsForImport?.length > 0 && (
          <tr>
            {Object.keys(debtsForImport[0]).map((propKey, index) => (
              <StyleTD>
                <DropBox
                  accept={[ItemTypes.EVERYTHING]}
                  lastDroppedItem={lastDroppedItems[index]}
                  onDrop={(item) => handleDrop(index, item)}
                  key={index}
                  index={index}
                  lastDroppedItems={lastDroppedItems}
                  setLastDroppedItems={setLastDroppedItems}
                />
              </StyleTD>
            ))}
          </tr>
        )}
        {debtsForImport?.length > 0 &&
          debtsForImport.slice(0, 5).map((debtForImport, index) => {
            return (
              <tr>
                {Object.keys(debtForImport).map((propKey) => (
                  <StyleTD>{debtForImport[propKey]}</StyleTD>
                ))}
              </tr>
            );
          })}
      </StyledTable>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const StyledTable = styled.table`
  border: 1px solid #ddd;
  margin-top: 10px;
  min-width: 500px;
  border-collapse: collapse;
`;

const StyleTD = styled.td`
  padding: 5px;
  border: 1px solid #ddd;
  text-align: center;
`;

const BoxesContainer = styled.div`
  min-height: 64px;
`;
