import axios from 'axios';

const DOCUMENT_TYPE_REQUEST = 'DOCUMENT_TYPE_REQUEST';
const DOCUMENT_TYPE_FAIL = 'DOCUMENT_TYPE_FAIL';
const ADD_DOCUMENT_TYPE_SUCCESS = 'ADD_DOCUMENT_TYPE_SUCCESS';
const EDIT_DOCUMENT_TYPE_SUCCESS = 'EDIT_DOCUMENT_TYPE_SUCCESS';
const DELETE_DOCUMENT_TYPE_SUCCESS = 'DELETE_DOCUMENT_TYPE_SUCCESS';
const GET_DOCUMENT_TYPES_SUCCESS = 'GET_DOCUMENT_TYPES_SUCCESS';

const documentTypeRequest = () => ({
  type: DOCUMENT_TYPE_REQUEST,
});

const documentTypeFail = (err) => ({
  type: DOCUMENT_TYPE_FAIL,
  payload: err,
});

export const editDocumentType = (documentType, documentId) => async (
  dispatch,
) => {
  dispatch(documentTypeRequest());
  try {
    const response = await axios.patch(
      `/document-patterns/${documentId}`,
      documentType,
    );
    dispatch(editDocumentTypeSuccess(response.data));
  } catch (err) {
    dispatch(documentTypeFail(err));
  }
};

const editDocumentTypeSuccess = (documentType) => ({
  type: EDIT_DOCUMENT_TYPE_SUCCESS,
  payload: documentType,
});

export const deleteDocumentType = (documentId) => async (dispatch) => {
  dispatch(documentTypeRequest());
  try {
    await axios.delete(`/document-patterns/${documentId}`);
    dispatch(deleteDocumentTypeSuccess(documentId));
  } catch (err) {
    dispatch(documentTypeFail(err));
  }
};

const deleteDocumentTypeSuccess = (documentId) => ({
  type: DELETE_DOCUMENT_TYPE_SUCCESS,
  payload: documentId,
});

export const addDocumentType = (documentType) => async (dispatch) => {
  dispatch(documentTypeRequest());
  try {
    const response = await axios.post(`/document-patterns`, documentType);
    console.log(response);
    dispatch(addDocumentTypeSuccess(response.data));
  } catch (err) {
    dispatch(documentTypeFail(err));
  }
};

const addDocumentTypeSuccess = (documentType) => ({
  type: ADD_DOCUMENT_TYPE_SUCCESS,
  payload: documentType,
});

export const getDocumentTypes = (
  pageNumber,
  pageSize,
  searchTerm = '',
) => async (dispatch) => {
  dispatch(documentTypeRequest());
  try {
    const response = await axios.get(
      `/document-patterns?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
    );
    let paginationMetadata;
    if (response?.headers?.pagination) {
      paginationMetadata = JSON.parse(response.headers.pagination);
    }
    dispatch(getDocumentTypesSuccess(response.data, paginationMetadata));
  } catch (err) {
    dispatch(documentTypeFail(err));
  }
};

const getDocumentTypesSuccess = (documentTypes, paginationMetadata) => ({
  type: GET_DOCUMENT_TYPES_SUCCESS,
  payload: documentTypes,
  paginationMetadata: paginationMetadata,
});

const initialState = {
  data: [],
  loading: false,
  error: null,
  current: null,
  paginationMetadata: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOCUMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
      };
    case GET_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        paginationMetadata: action.paginationMetadata,
        loading: false,
      };
    case EDIT_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        data: state.data.map((documentType) =>
          documentType._id === action.payload._id
            ? action.payload
            : documentType,
        ),
        loading: false,
      };
    case DELETE_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          (documentType) => documentType._id !== action.payload,
        ),
        loading: false,
      };
    default:
      return state;
  }
};
