import React from 'react';
import DashboardServiceCard from './DashboardServiceCard';
import styled from 'styled-components';

const DashboardPageView = ({ user }) => {
  return (
    <FlexContainer>
      {user.role === 'Admin' && (
        <CardCategory>
          <SubTitle>Dane Osobowe</SubTitle>
          <CardsContainer>
            <DashboardServiceCard
              title="Baza danych dłużników"
              subtitle="Zarządzaj"
              link="/debtors-db"
            />
            <DashboardServiceCard
              title="Baza danych kontrahentów"
              subtitle="Zarządzaj"
              link="/business-partners"
            />
          </CardsContainer>
        </CardCategory>
      )}
      <CardCategory>
        <SubTitle>Dokumenty</SubTitle>
        <CardsContainer>
          {user.role === 'Admin' && (
            <DashboardServiceCard
              title="Szablony Dokumentów"
              subtitle="Zarządzaj"
              link="/documents"
            />
          )}
          <DashboardServiceCard
            title="Dokument"
            subtitle="Dodaj Nowy"
            link="/add-document"
          />
          <DashboardServiceCard
            title="Dokument"
            subtitle="Zmiana statusu"
            link="/documents-status"
          />
        </CardsContainer>
      </CardCategory>
      {user.role === 'Admin' && (
        <CardCategory>
          <SubTitle>Długi</SubTitle>
          <CardsContainer>
            <DashboardServiceCard
              title="Długi"
              subtitle="Zarządzaj"
              link="/debts"
            />
            <DashboardServiceCard
              title="Długi"
              subtitle="Dodaj"
              link="/add-debt"
            />
          </CardsContainer>
        </CardCategory>
      )}
      {user.role === 'Admin' && (
        <CardCategory>
          <SubTitle>Portfele</SubTitle>
          <CardsContainer>
            <DashboardServiceCard
              title="Portfele wierzytelności"
              subtitle="Zarządzaj"
              link="/wallets"
            />
            <DashboardServiceCard
              title="Portfel wierzytelności"
              subtitle="Dodaj"
              link="/add-wallet"
            />
            <DashboardServiceCard
              title="Portfel wierzytelności"
              subtitle="Importuj"
              link="/debt-wallet-import"
            />
          </CardsContainer>
        </CardCategory>
      )}

      {user.role === 'Admin' && (
        <CardCategory>
          <SubTitle>Płatności</SubTitle>
          <CardsContainer>
            <DashboardServiceCard
              title="Płatności"
              subtitle="Zarządzaj"
              link="/payments"
            />
            <DashboardServiceCard
              title="Płatności"
              subtitle="Dodaj"
              link="/add-payment"
            />
            <DashboardServiceCard
              title="Płatności"
              subtitle="Importuj"
              link="/payments-import"
            />
          </CardsContainer>
        </CardCategory>
      )}

      {user.role === 'Admin' && (
        <CardCategory>
          <SubTitle>Sprawy sądowe</SubTitle>
          <CardsContainer>
            <DashboardServiceCard
              title="Sprawy sądowe"
              subtitle="Zarządzaj"
              link="/lawsuits"
            />
            <DashboardServiceCard
              title="Komornik"
              subtitle="Przydziel do sprawy"
              link="/bailiff-assign"
            />
          </CardsContainer>
        </CardCategory>
      )}

      {user.role === 'Admin' && (
        <CardCategory>
          <SubTitle>Funkcje admina</SubTitle>
          <CardsContainer>
            <DashboardServiceCard
              title="Użytkownicy"
              subtitle="Zarządzaj"
              link="/users-management"
            />
          </CardsContainer>
        </CardCategory>
      )}

      {user.role === 'Bailiff' && (
        <CardCategory>
          <SubTitle>Funkcje Komornika</SubTitle>
          <CardsContainer>
            <DashboardServiceCard
              title="Sprawy Sądowe"
              subtitle="Zarządzaj"
              link="/bailiff-debts"
            />
          </CardsContainer>
        </CardCategory>
      )}
    </FlexContainer>
  );
};

export default DashboardPageView;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const CardCategory = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled.h2``;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-wrap: wrap;
  margin-left: 65px;
`;
