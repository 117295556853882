import React, { useEffect, useState } from "react";
import AddWalletView from "./AddWalletView";
import { addDebtWallet } from "../../redux/modules/debtWallets";
import { getBusinessPartners, getBusinessPartnersWithPagination } from '../../redux/modules/businessPartners';
import { connect } from "react-redux";

const AddWalletContainer = ({ addDebtWallet, debtors, getBusinessPartners, businessPartners, getBusinessPartnersWithPagination }) => {
    const [businessPartnerFields, setBusinessPartnerFields] = useState({businessPartner: '', yield: 0})
    const [searchTerm, setSearchTerm] = useState("")
    const [walletReseted, setWalletReseted] = useState(false);

    useEffect(() => {
      if (debtors?.length === 0) {
        getBusinessPartnersWithPagination(1, 20, searchTerm);
      }
    }, []);

    useEffect(() => {
        getBusinessPartnersWithPagination(1, 20, searchTerm)
    }, [searchTerm])

    const handleSearchInputChange = (searchTermInput) => {
        setSearchTerm(searchTermInput)
    }

    // useEffect(() => {
    //   if (currentPayment && paymentReseted) {
    //     history.push(`/payments/${currentPayment?._id}`);
    //   }
    // }, [currentPayment]);

    return (
        <AddWalletView handleSearchInputChange={handleSearchInputChange} addDebtWallet={addDebtWallet} debtors={debtors} businessPartners={businessPartners} businessPartnerFields={businessPartnerFields} setBusinessPartnerFields={setBusinessPartnerFields}/>
    )
}

const mapStateToProps = (state) => ({
    debtors: state.debtors.data,
    businessPartners: state.businessPartners.data
  });
  
  const mapDispatchToProps = (dispatch) => ({
    addDebtWallet: (debtWallet) => dispatch(addDebtWallet(debtWallet)),
    getBusinessPartners: () => dispatch(getBusinessPartners()),
    getBusinessPartnersWithPagination: (pageNumber, pageSize, searchTerm ) =>
        dispatch(getBusinessPartnersWithPagination(pageNumber, pageSize, searchTerm ))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddWalletContainer);
  