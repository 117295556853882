import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { toast } from 'react-toastify';

const DocumentStatusView = ({
  status,
  setStatus,
  barcode,
  setBarcode,
  editDocumentStatusByBarcode,
}) => {
  return (
    <Wrapper>
      <Title>Zmiana statusu dokumentu</Title>
      <ContentWrapper>
        <TextField
          id="outlined-basic"
          label="Kod kreskowy"
          value={barcode}
          onChange={(event) => setBarcode(event.target.value)}
        />
        <TextField
          id="standard-select-currency"
          select
          label="Status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value={'CREATED'}>Utworzony</MenuItem>
          <MenuItem value={'SENT'}>Wysłany</MenuItem>
          <MenuItem value={'RECEIVED'}>Odebrany</MenuItem>
          <MenuItem value={'REJECTED'}>Odrzucony</MenuItem>
        </TextField>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            editDocumentStatusByBarcode({ status }, barcode).then((res) =>
              res
                ? toast(`Pomyślnie zmieniono status dokumentu.`, {
                    type: 'success',
                  })
                : toast(`Błąd! Nie zmieniono statusu dokumentu.`, {
                    type: 'error',
                  }),
            )
          }
        >
          Zmień status
        </Button>
      </ContentWrapper>
    </Wrapper>
  );
};

export default DocumentStatusView;

const Title = styled.h1`
  font-size: 24px;
`;

const Wrapper = styled.div`
  margin: 20px auto;
`;

const ContentWrapper = styled(Paper)`
  display: flex;
  align-items: center;
  min-width: 500px;
  justify-content: space-between;
  padding: 20px;
`;
