import React, { useState, useEffect } from 'react';
import NavigationView from './NavigationView';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/modules/user';

const NavigationContainer = ({ location, user, logoutUser }) => {
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const currentRoute = location.pathname.replace('/', '');
    if (currentRoute === '') {
      setActiveTab(0);
    } else if (currentRoute === 'debtors-db') {
      setActiveTab(1);
    } else if (currentRoute === 'business-partners') {
      setActiveTab(2);
    } else if (currentRoute === 'documents') {
      setActiveTab(3);
    } else if (currentRoute === 'add-document') {
      setActiveTab(4);
    } else if (currentRoute === 'documents-status') {
      setActiveTab(5);
    } else if (currentRoute === 'debts') {
      setActiveTab(6);
    } else if (currentRoute === 'add-debt') {
      setActiveTab(7);
    } else if (currentRoute === 'wallets') {
      setActiveTab(8);
    } else if (currentRoute === 'add-wallet') {
      setActiveTab(9);
    } else if (currentRoute === 'debt-wallet-import') {
      setActiveTab(10);
    } else if (currentRoute === 'payments') {
      setActiveTab(11);
    } else if (currentRoute === 'add-payment') {
      setActiveTab(12);
    } else if (currentRoute === 'payments-import') {
      setActiveTab(13);
    } else if (currentRoute === 'bailiff-assign') {
      setActiveTab(14);
    } else if (currentRoute === 'lawsuits') {
      setActiveTab(15);
    }
  }, [location]);

  return (
    <NavigationView activeTab={activeTab} user={user} logoutUser={logoutUser} />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(NavigationContainer),
);
