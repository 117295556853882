import React, { useState, useEffect } from 'react';
import Modal from '../../../shared/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import Input from '@material-ui/core/TextField';
import * as Yup from 'yup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import SearchSelect from '../../../shared/Select';

const EditPaymentModal = ({
  isEditPaymentModal,
  setEditPaymentModal,
  editPayment,
  debts,
  currentPayment,
  setDebtInputValue,
  setWarningModal,
}) => {
  const initialValues = {
    value: currentPayment?.value,
    currency: currentPayment?.currency,
    date: moment(currentPayment?.date).format('yyyy-MM-DD'),
    accountNumber: currentPayment?.accountNumber,
    description: currentPayment?.description,
    debt: currentPayment?.debt ? currentPayment.debt._id : '',
  };

  const AddPaymentSchema = Yup.object().shape({});

  return (
    <Modal
      title="Dodawanie Spłaty"
      show={isEditPaymentModal}
      exitButton={true}
      onCancel={() => {
        setEditPaymentModal(false);
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AddPaymentSchema}
        onSubmit={async (values) => {
          if (values.debt) {
            setWarningModal({
              shown: true,
              question:
                'Czy jesteś pewien wprowadzonych danych? Po zaksięgowaniu płatności nie będzie możliwości jej edycji.',
              onAccept: () => {
                editPayment(values, currentPayment._id);
              },
            });
          } else {
            delete values.debt;
            editPayment(values, currentPayment._id);
          }
          setEditPaymentModal(false);
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          setFieldError,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InputRow>
              <NewDocumentSelect
                label="Dług"
                value={debts
                  ?.filter((debt) => debt?._id === values.debt)
                  .map((debt) => {
                    return {
                      label: `${debt?.debtor?.firstName} ${debt?.debtor?.lastName}, ${debt?.debtor?.pesel}, ${debt?.description}`,
                      value: debt?._id,
                    };
                  })}
                name="debt"
                placeholder="Wybierz dług..."
                options={debts?.map((debt) => {
                  return {
                    label: `${debt?.debtor?.firstName} ${debt?.debtor?.lastName}, ${debt?.debtor?.pesel}, ${debt?.description}`,
                    value: debt?._id,
                  };
                })}
                onChange={({ value }) => setFieldValue('debt', value)}
                onInputChange={(value) => {
                  setDebtInputValue(value);
                }}
                noOptionsMessage={() => 'Nie ma takiego długu'}
                error={errors.debt}
              />
            </InputRow>
            <InputRow>
              <Input
                name="value"
                type="number"
                onChange={handleChange}
                value={values.value}
                error={errors.value}
                label="Wartość"
              />
              <FormControl>
                <InputLabel id="currency-label">Waluta</InputLabel>
                <Select
                  labelId="currency-label"
                  id="currency"
                  value={values.currency}
                  onChange={(e) => setFieldValue('currency', e.target.value)}
                >
                  <MenuItem value="PLN">PLN</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GPB">GPB</MenuItem>
                </Select>
              </FormControl>
              <Input
                name="date"
                type="date"
                onChange={handleChange}
                value={values.date}
                error={errors.date}
                label="Data"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </InputRow>
            <InputRow>
              <Input
                name="accountNumber"
                type="text"
                onChange={handleChange}
                value={values.accountNumber}
                error={errors.accountNumber}
                label="Numer Konta"
              />
            </InputRow>
            <InputRow>
              <Input
                name="description"
                type="text"
                onChange={handleChange}
                value={values.description}
                error={errors.description}
                label="Opis"
              />
            </InputRow>
            <CenterBox>
              <Button type="submit" variant="primary">
                Potwierdź
              </Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditPaymentModal;

const NewDocumentSelect = styled(SearchSelect)`
  width: 450px;
`;

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const AddingNewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin: 10px auto;
`;
const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  > div {
    width: 48%;
  }
  ${({ hidden }) => hidden && `display: none`}
`;
const UserWrapper = styled.div`
  background-color: #c4c4c4;
  color: #fff;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin: 5px 0;
`;
const UserInfo = styled.p`
  color: #fff;
  margin: 0 5px;
`;

const AddPatientNoOption = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const AddPatientButton = styled.button`
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  padding: 0.25em 0.65em;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.p`
  cursor: pointer;
  color: red;
  font-size: 16px;
`;
