import React from 'react';
import Modal from '../../../shared/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import Input from '@material-ui/core/TextField';
import * as Yup from 'yup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchSelect from '../../../shared/Select';
import moment from 'moment';
import FileUpload from '../../../shared/FileUpload';
import IconButton from '@material-ui/core/IconButton';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

const AddDebtModal = ({
  isAddDebtModal,
  setAddDebtModal,
  addDebt,
  debtors,
  addDebtWithDocuments,
  shapeRequestFormData,
  businessPartners,
  debtWallets,
}) => {
  const initialValues = {
    debtor: '',
    lawsuitNumber: '',
    principal: 0,
    currency: 'PLN',
    type: 'none',
    repaid: 'false',
    description: '',
    date: moment(new Date()).format('yyyy-MM-DD'),
    interestRate: 7.0,
    debtDocuments: [],
  };

  const AddDebtSchema = Yup.object().shape({
    lawsuitNumber: Yup.string(),
    principal: Yup.number().required('Wartość jest wymagana'),
    currency: Yup.string().required('Waluta jest wymagana'),
    type: Yup.string().required('Typ jest wymagany'),
    description: Yup.string(),
  });

  return (
    <Modal
      title="Dodawanie długu"
      show={isAddDebtModal}
      exitButton={true}
      onCancel={() => {
        setAddDebtModal(false);
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AddDebtSchema}
        onSubmit={async (values) => {
          if (values?.debtDocuments?.length > 0) {
            const debtWithDocumentsFormData = shapeRequestFormData(values);
            await addDebtWithDocuments(debtWithDocumentsFormData);
          } else {
            await addDebt(values);
          }
          setAddDebtModal(false);
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          setFieldError,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InputRow>
              <NewDocumentSelect
                label="Dłużnik"
                value={debtors
                  .filter((debtor) => debtor._id === values.debtor)
                  .map((debtor) => {
                    return {
                      label: `${debtor.firstName} ${debtor.lastName}, ${debtor.pesel}`,
                      value: debtor._id,
                    };
                  })}
                name="debtor"
                placeholder="Wybierz dłużnika..."
                options={debtors?.map((debtor) => {
                  return {
                    label: `${debtor.firstName} ${debtor.lastName}, ${debtor.pesel}`,
                    value: debtor._id,
                  };
                })}
                onChange={({ value }) => setFieldValue('debtor', value)}
                noOptionsMessage={() => 'Nie ma takiego dłużnika'}
                error={errors.debtor}
              />
            </InputRow>
            <InputRow>
              <NewDocumentSelect
                label="Właściciel długu"
                // value={}
                name="debtOwner"
                placeholder="Wybierz właściciela długu..."
                options={businessPartners?.map((bp) => {
                  return {
                    label: `${bp.name}, ${bp.PESEL !== '' ? bp.PESEL : bp.NIP}`,
                    value: bp._id,
                  };
                })}
                onChange={({ value }) => setFieldValue('debtOwner', value)}
                noOptionsMessage={() => 'Nie ma takiego właściciela'}
                error={errors.debtOwner}
              />
            </InputRow>
            <InputRow>
              <NewDocumentSelect
                label="Portfel długu"
                // value={}
                name="debtWallet"
                placeholder="Wybierz portfel długu..."
                options={debtWallets.map((dw) => {
                  return { value: dw._id, label: dw.name };
                })}
                onChange={({ value }) => setFieldValue('debtWallet', value)}
                noOptionsMessage={() => 'Nie ma takiego portfela'}
                error={errors.debtWallet}
              />
            </InputRow>
            <FullWidthInput
              name="description"
              type="text"
              onChange={handleChange}
              value={values.description}
              error={errors.description}
              label="Podstawa Wierzytelności"
            />
            <InputRow>
              <Input
                name="principal"
                type="text"
                onChange={handleChange}
                value={values.principal}
                error={errors.principal}
                label="Kwota"
              />
              <FormControl>
                <InputLabel id="currency-label">Waluta</InputLabel>
                <Select
                  labelId="currency-label"
                  id="currency"
                  value={values.currency}
                  onChange={(e) => setFieldValue('currency', e.target.value)}
                >
                  <MenuItem value="PLN">PLN</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GPB">GPB</MenuItem>
                </Select>
              </FormControl>
              <Input
                name="date"
                type="date"
                onChange={handleChange}
                value={values.date}
                error={errors.date}
                label="Data Wierzytelności"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </InputRow>
            <InputRow>
              <Input
                name="lawsuitNumber"
                type="text"
                onChange={handleChange}
                value={values.lawsuitNumber}
                error={errors.lawsuitNumber}
                label="Numer pozwu"
              />
                <FormControl>
                  <InputLabel id="type-label">Typ</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    value={values.type}
                    onChange={(e) => setFieldValue('type', e.target.value)}
                  >
                    <MenuItem value="none">Brak</MenuItem>
                    <MenuItem value="telecom">Usługi telekomunikacyjne</MenuItem>
                    <MenuItem value="sentence">Nakaz zapłaty/Wyrok</MenuItem>
                    <MenuItem value="rateAgreement">Umowa Ratalna</MenuItem>
                    <MenuItem value="creditAgreement">Umowa Kredytowa</MenuItem>
                    <MenuItem value="costOfProcess">Koszty procesu</MenuItem>
                    <MenuItem value="costOfBailiff">Koszty komornicze</MenuItem>
                  </Select>
                </FormControl>
              <Input
                name="interestRate"
                type="number"
                onChange={handleChange}
                value={values.interestRate}
                error={errors.interestRate}
                label="Odsetki %"
              />
            </InputRow>
            <FileUpload
              testId="tabs_dropfile"
              label="Dokumenty powiązane z długiem"
              onDrop={(files) => {
                const debtDocumentsBuffer = [];
                for (const i in files) {
                  const file = files[i];
                  const debtDocument = {
                    debtDocumentFile: file,
                    description: '',
                    originalFilename: file.name,
                  };
                  debtDocumentsBuffer.push(debtDocument);
                }
                setFieldValue('debtDocuments', [
                  ...values.debtDocuments,
                  ...debtDocumentsBuffer,
                ]);
              }}
            />
            {values?.debtDocuments?.map((debtDocumentFile, index) => (
              <FileRow>
                <ButtonWithFilename>
                  <StyledIconButton
                    type="button"
                    onClick={() => {
                      values.debtDocuments.splice(index, 1);
                      setFieldValue('debtDocuments', values.debtDocuments);
                    }}
                  >
                    <StyledClearOutlinedIcon />
                  </StyledIconButton>
                  <div>{debtDocumentFile.originalFilename}</div>
                </ButtonWithFilename>
                <StyledInput
                  name={`debtDocuments[${index}].description`}
                  type="text"
                  onChange={handleChange}
                  label="Opis dokumentu"
                  value={values.debtDocuments[index].description}
                />
              </FileRow>
            ))}
            <CenterBox>
              <Button type="submit" variant="primary">
                Potwierdź
              </Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddDebtModal;

const FullWidthInput = styled(Input)`
  width: 100%;
`;

const NewDocumentSelect = styled(SearchSelect)`
  width: 650px;
`;

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 650px;
}`}
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  > div {
    width: 32%;
  }
`;


const FileRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
`;

const ButtonWithFilename = styled.div`
  display: flex;
  align-items: center;
  width: 48%;
`;

const StyledIconButton = styled(IconButton)`
  position: relative;
  top: 2px;
`;

const StyledClearOutlinedIcon = styled(ClearOutlinedIcon)`
  color: #4c5bac;
`;

const StyledInput = styled(Input)`
  width: 48%;
`;
