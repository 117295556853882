import React, { useEffect, useState } from 'react';
import AddDocumentView from './AddDocumentView';
import { connect } from 'react-redux';
import { getDebtors } from '../../redux/modules/debtors';
import { getDocumentTypes } from '../../redux/modules/documentTypes';
import {
  addDocument,
  setCurrentDocument,
  getCourts,
} from '../../redux/modules/document';
import { withRouter } from 'react-router';
import { getBusinessPartnersWithPagination } from '../../redux/modules/businessPartners';

const AddDocumentContainer = ({
  getDocumentTypes,
  getDebtors,
  debtors,
  documentTypes,
  addDocument,
  history,
  currentDocument,
  setCurrentDocument,
  currentDebtor,
  getBusinessPartnersWithPagination,
  businessPartners,
  getCourts,
  courts,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [documentPatternsSearchTerm, setDocumentPatternsSearchTerm] = useState(
    '',
  );
  const [documentReseted, setDocumentReseted] = useState(false);
  const [searchTermBP, setSearchTermBP] = useState('');
  const [searchTermCourt, setSearchTermCourt] = useState('');

  useEffect(() => {
    setCurrentDocument(null);
    if (debtors.length === 0) {
      getDebtors(1, 20, searchTerm);
    }
    if (documentTypes.length === 0) {
      getDocumentTypes(1, 20, documentPatternsSearchTerm);
    }
    setDocumentReseted(true);
  }, []);

  useEffect(() => {
    getDebtors(1, 20, searchTerm);
  }, [searchTerm]);

  const handleSearchInputChange = (searchTermInput) => {
    setSearchTerm(searchTermInput);
  };
  const handleSearchBPInputChange = (searchTermInput) => {
    setSearchTermBP(searchTermInput);
  };
  const handleSearchCourtInputChange = (searchTermInput) => {
    setSearchTermCourt(searchTermInput);
  };
  useEffect(() => {
    getDocumentTypes(1, 20, documentPatternsSearchTerm);
  }, [documentPatternsSearchTerm]);

  const handleDocumentPatternsSearchInputChange = (searchTermInput) => {
    setDocumentPatternsSearchTerm(searchTermInput);
  };

  useEffect(() => {
    if (currentDocument && documentReseted) {
      history.push(`/document/${currentDocument?._id}`);
    }
  }, [currentDocument]);

  useEffect(() => {
    if (businessPartners?.length === 0) {
      getBusinessPartnersWithPagination(1, 10, searchTermBP);
    }
    if (courts.length === 0) {
      getCourts(1, 10, searchTermCourt);
    }
  }, []);

  useEffect(() => {
    getBusinessPartnersWithPagination(1, 10, searchTermBP);
  }, [searchTermBP]);

  useEffect(() => {
    getCourts(1, 10, searchTermCourt);
  }, [searchTermCourt]);

  return (
    <AddDocumentView
      debtors={debtors}
      documentTypes={documentTypes}
      addDocument={addDocument}
      currentDebtor={currentDebtor}
      handleSearchInputChange={handleSearchInputChange}
      businessPartners={businessPartners}
      handleSearchBPInputChange={handleSearchBPInputChange}
      handleSearchCourtInputChange={handleSearchCourtInputChange}
      handleDocumentPatternsSearchInputChange={
        handleDocumentPatternsSearchInputChange
      }
      courts={courts}
    />
  );
};

const mapStateToProps = (state) => ({
  debtors: state.debtors.data,
  documentTypes: state.documentTypes.data,
  currentDocument: state.documents.current,
  currentDebtor: state.debtors.current,
  businessPartners: state.businessPartners.data,
  courts: state.documents.courts,
});

const mapDispatchToProps = (dispatch) => ({
  getDebtors: (pageNumber, pageSize, searchTerm) =>
    dispatch(getDebtors(pageNumber, pageSize, searchTerm)),
  getDocumentTypes: (pageNumber, pageSize, searchTerm) =>
    dispatch(getDocumentTypes(pageNumber, pageSize, searchTerm)),
  addDocument: (document) => dispatch(addDocument(document)),
  setCurrentDocument: (document) => dispatch(setCurrentDocument(document)),
  getBusinessPartnersWithPagination: (pageNumber, pageSize, searchTerm) =>
    dispatch(
      getBusinessPartnersWithPagination(pageNumber, pageSize, searchTerm),
    ),
  getCourts: (pageNumber, pageSize, searchTerm) =>
    dispatch(getCourts(pageNumber, pageSize, searchTerm)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddDocumentContainer),
);
