import axios from 'axios';

const PAYMENT_REQUEST = 'PAYMENT_REQUEST';
const PAYMENT_FAIL = 'PAYMENT_FAIL';
const IMPORT_PAYMENT_SUCCESS = 'IMPORT_PAYMENT_SUCCESS';
const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS';
const SET_CURRENT_PAYMENT = 'SET_CURRENT_PAYMENT';
const EDIT_PAYMENT_SUCCESS = 'EDIT_PAYMENT_SUCCESS';
const GET_PAYMENT_BY_ID_SUCCESS = 'GET_PAYMENT_BY_ID_SUCCESS';

const paymentRequest = () => ({
  type: PAYMENT_REQUEST,
});

const paymentFail = (err) => ({
  type: PAYMENT_FAIL,
  payload: err,
});

export const importPayment = (paymentFile, dataSource) => async (dispatch) => {
  dispatch(paymentRequest());
  try {
    let dataImportEndpoint;
    if (dataSource === 0) {
      dataImportEndpoint = 'import';
    } else if (dataSource === 1) {
      dataImportEndpoint = 'dnb-bank-import';
    } else if (dataSource === 2) {
      dataImportEndpoint = 'exampleImport';
    }
    const response = await axios.post(
      `/payments/${dataImportEndpoint}`,
      paymentFile,
    );
    dispatch(importPaymentSuccess(response.data));
  } catch (err) {
    dispatch(paymentFail(err));
  }
};

const importPaymentSuccess = (debtBody) => ({
  type: IMPORT_PAYMENT_SUCCESS,
  payload: debtBody,
});

export const getPayments = () => async (dispatch) => {
  dispatch(paymentRequest());
  try {
    const response = await axios.get(`/payments`);
    dispatch(getPaymentsSuccess(response.data));
  } catch (err) {
    dispatch(paymentFail(err));
  }
};

export const getPaymentsWithDebtsAndDebtors = (
  pageNumber,
  pageSize,
  searchTerm = '',
) => async (dispatch) => {
  dispatch(paymentRequest());
  try {
    const response = await axios.get(
      `/payments?populateField=debtDebtor&pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
    );
    let paginationMetadata;
    if (response?.headers?.pagination) {
      paginationMetadata = JSON.parse(response.headers.pagination);
    }
    dispatch(getPaymentsSuccess(response.data, paginationMetadata));
  } catch (err) {
    dispatch(paymentFail(err));
  }
};

const getPaymentsSuccess = (payments, paginationMetadata) => ({
  type: GET_PAYMENTS_SUCCESS,
  payload: payments,
  paginationMetadata: paginationMetadata,
});

export const addPayment = (payment) => async (dispatch) => {
  dispatch(paymentRequest());
  try {
    const response = await axios.post(
      `/payments?populateField=debtDebtor`,
      payment,
    );
    dispatch(addPaymentSuccess(response.data));
  } catch (err) {
    dispatch(paymentFail(err));
  }
};

const addPaymentSuccess = (payment) => ({
  type: ADD_PAYMENT_SUCCESS,
  payload: payment,
});

export const getPaymentByIdWithDebtor = (id) => async (dispatch) => {
  dispatch(paymentRequest());
  try {
    const response = await axios.get(
      `/payments/${id}?populateField=debtDebtor`,
    );
    dispatch(getPaymentByIdSuccess(response.data));
  } catch (err) {
    dispatch(paymentFail(err));
  }
};

const getPaymentByIdSuccess = (payment) => ({
  type: GET_PAYMENT_BY_ID_SUCCESS,
  payload: payment,
});

export const getPaymentsByDebtorId = (debtorId) => async (dispatch) => {
  dispatch(paymentRequest());
  try {
    const response = await axios.get(`/payments/by-debtor?debtor=${debtorId}`);
    dispatch(getPaymentsSuccess(response.data));
  } catch (err) {
    dispatch(paymentFail(err));
  }
};

export const setCurrentPayment = (paymentId) => ({
  type: SET_CURRENT_PAYMENT,
  payload: paymentId,
});
const SET_IMPORTED_PAYMENTS = 'SET_IMPORTED_PAYMENTS';
export const setImportedPayments = (payments) => ({
  type: SET_IMPORTED_PAYMENTS,
  payload: payments,
});

export const editPayment = (payment, paymentID) => async (dispatch) => {
  dispatch(paymentRequest());
  try {
    const response = await axios.patch(
      `/payments/${paymentID}?populateField=debtDebtor`,
      payment,
    );
    dispatch(editPaymentSuccess(response.data));
  } catch (err) {
    dispatch(paymentFail(err));
  }
};

const editPaymentSuccess = (payment) => ({
  type: EDIT_PAYMENT_SUCCESS,
  payload: payment,
});

const initialState = {
  data: [],
  loading: false,
  error: null,
  paginationMetadata: null,
  import: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case IMPORT_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        import: action.payload,
      };
    case SET_IMPORTED_PAYMENTS:
      return {
        ...state,
        import: action.payload,
        error: null,
      };
    case GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        paginationMetadata: action.paginationMetadata,
        loading: false,
      };
    case ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
        current: action.payload,
      };
    case SET_CURRENT_PAYMENT:
      return {
        ...state,
        current: state.data.find((payment) => payment._id === action.payload),
      };
    case EDIT_PAYMENT_SUCCESS:
      return {
        ...state,
        data: state.data.map((payment) =>
          payment._id === action.payload._id ? action.payload : payment,
        ),
        loading: false,
      };
    case GET_PAYMENT_BY_ID_SUCCESS:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
