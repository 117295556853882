import React from 'react';
import styled from 'styled-components';
import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchSelect from '../../shared/Select';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import WarningModal from '../SingleDebtor/modals/WarningModal';

const AddDebtView = ({
  addPayment,
  debts,
  setDebtInputValue,
  warningModal,
  setWarningModal,
}) => {
  const initialValues = {
    value: '',
    currency: 'PLN',
    date: moment(new Date()).format('yyyy-MM-DD'),
    accountNumber: '',
    description: '',
    debt: '',
  };

  const AddPaymentSchema = Yup.object().shape({});

  return (
    <Wrapper>
      <WarningModal
        show={warningModal.shown}
        question={warningModal.question}
        onAccept={warningModal.onAccept}
        preventClosing={warningModal.preventClosing}
        setModalState={setWarningModal}
        questionTitle={warningModal.questionTitle}
      />
      <Title>Dodawanie Spłaty</Title>
      <PaperWrapper>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={AddPaymentSchema}
          onSubmit={async (values) => {
            if (values.debt) {
              setWarningModal({
                shown: true,
                question:
                  'Czy jesteś pewien wprowadzonych danych? Po zaksięgowaniu płatności nie będzie możliwości jej edycji.',
                onAccept: () => {
                  addPayment(values);
                },
              });
            } else {
              delete values.debt;
              addPayment(values);
            }
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            setFieldError,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <InputRow>
                <NewDocumentSelect
                  label="Dług"
                  value={debts
                    .filter((debt) => debt?._id === values.debt)
                    .map((debt) => {
                      return {
                        label: `${debt?.debtor?.firstName} ${debt?.debtor?.lastName}, ${debt?.debtor?.pesel}, ${debt?.description}`,
                        value: debt?._id,
                      };
                    })}
                  name="debt"
                  placeholder="Wybierz dług..."
                  options={debts?.map((debt) => {
                    return {
                      label: `${debt?.debtor?.firstName} ${debt?.debtor?.lastName}, ${debt?.debtor?.pesel}, ${debt?.description}`,
                      value: debt?._id,
                    };
                  })}
                  onChange={({ value }) => setFieldValue('debt', value)}
                  onInputChange={(value) => setDebtInputValue(value)}
                  noOptionsMessage={() => 'Nie ma takiego długu'}
                  error={errors.debt}
                />
              </InputRow>
              <InputRow>
                <Input
                  name="value"
                  type="number"
                  onChange={handleChange}
                  value={values.value}
                  error={errors.value}
                  label="Wartość"
                />
                <FormControl>
                  <InputLabel id="currency-label">Waluta</InputLabel>
                  <Select
                    labelId="currency-label"
                    id="currency"
                    value={values.currency}
                    onChange={(e) => setFieldValue('currency', e.target.value)}
                  >
                    <MenuItem value="PLN">PLN</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="GPB">GPB</MenuItem>
                  </Select>
                </FormControl>
                <Input
                  name="date"
                  type="date"
                  onChange={handleChange}
                  value={values.date}
                  error={errors.date}
                  label="Data"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </InputRow>
              <InputRow>
                <Input
                  name="accountNumber"
                  type="text"
                  onChange={handleChange}
                  value={values.accountNumber}
                  error={errors.accountNumber}
                  label="Numer Konta"
                />
              </InputRow>
              <InputRow>
                <Input
                  name="description"
                  type="text"
                  onChange={handleChange}
                  value={values.description}
                  error={errors.description}
                  label="Opis"
                />
              </InputRow>
              <CenterBox>
                <Button type="submit" variant="primary">
                  Potwierdź
                </Button>
              </CenterBox>
            </Form>
          )}
        </Formik>
      </PaperWrapper>
    </Wrapper>
  );
};

export default AddDebtView;

const Wrapper = styled.div`
  margin: 20px auto;
  height: calc(100vh - 40px);
`;

const PaperWrapper = styled(Paper)`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const NewDocumentSelect = styled(SearchSelect)`
  width: 450px;
`;

const AddingNewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin: 10px auto;
`;
const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  > div {
    width: 48%;
  }
`;
const UserWrapper = styled.div`
  background-color: #c4c4c4;
  color: #fff;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin: 5px 0;
`;
const UserInfo = styled.p`
  color: #fff;
  margin: 0 5px;
`;

const AddPatientNoOption = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const AddPatientButton = styled.button`
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  padding: 0.25em 0.65em;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.p`
  cursor: pointer;
  color: red;
  font-size: 16px;
`;
