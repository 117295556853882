import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import AddUserModal from './modal/AddUserModal';
import EditUserModal from './modal/EditUserModal';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const UsersManagementView = ({
  count,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  users,
  isAddUserModal,
  setAddUserModal,
  addUser,
  updateAuthenticatedUser,
  isEditUserModal,
  setEditUserModal,
}) => {
  return (
    <Wrapper>
      <AddUserModal
        isAddUserModal={isAddUserModal}
        setAddUserModal={setAddUserModal}
        addUser={addUser}
      />
      <EditUserModal
        isEditUserModal={isEditUserModal}
        setEditUserModal={setEditUserModal}
        updateAuthenticatedUser={updateAuthenticatedUser}
      />
      <DebtorsList>
        <PageSubTitle>Użytkownicy</PageSubTitle>

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <HeadTableCell>Imię i Nazwisko</HeadTableCell>
                <HeadTableCell align="right">Rola</HeadTableCell>
                <HeadTableCell align="right">Email</HeadTableCell>
                <HeadTableCell align="right">Utworzony</HeadTableCell>
                <HeadTableCell align="right">
                  <AddDebtorBtn
                    startIcon={<AddIcon />}
                    onClick={() => setAddUserModal(true)}
                  >
                    Dodaj
                  </AddDebtorBtn>
                </HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length > 0 &&
                users?.map((user) => {
                  return (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {user.name} {user.surname}
                      </TableCell>
                      <TableCell align="right">{user.role}</TableCell>
                      <TableCell align="right">{user.email}</TableCell>
                      <TableCell align="right">
                        {user.createdDate?.split('', 10).join('')}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() =>
                            setEditUserModal({ isOpen: true, user: user })
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </DebtorsList>
    </Wrapper>
  );
};

export default UsersManagementView;

const AddDebtorBtn = styled(Button)`
  /* position: absolute;
    right: 20px;
    top: 20px; */
  /* margin-top: 10px; */
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;

const HeadTableCell = styled(TableCell)`
  font-weight: 600;
`;
const PageSubTitle = styled.h2`
  margin: 0;
  margin-bottom: 20px;
`;

const DebtorsList = styled.div`
  padding: 20px;
  position: relative;
`;

const Wrapper = styled(Paper)`
  width: 100%;
  margin: 20px;
  padding: 20px;
  position: relative;
  margin-left: 85px;
`;
