import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled, { css } from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditLawsuitModal from './modals/EditLawsuitModal';
// import AddActivityModal from './modals/AddActivityModal';
import Button from '@material-ui/core/Button';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import TableHead from '@material-ui/core/TableHead';
import { Link } from 'react-router-dom';
import moment from 'moment';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import TablePagination from '@material-ui/core/TablePagination';
import LogModal from './modals/LogModal';

const SingleLawsuitView = ({
  currentLawsuit,
  debts,
  bailiffs,
  updateLawsuit,
  isAddActivityModal,
  setAddActivityModal,
  isEditLawsuitModal,
  setEditLawsuitModal,
  setDebtInputValue,
  logPage,
  setLogPage,
  logModal,
  setLogModal,
  userId,
  addLawsuitLog,
  editLawsuitLog
}) => {
  const { name = 'Brak', surname = '' } =
    bailiffs.find((el) => el._id === currentLawsuit?.bailiffId) ?? {};
  const bailiffName = `${name} ${surname}`;
  return (
    <Wrapper>
            <LogModal
        logModal={logModal}
        setLogModal={setLogModal}
        addLawsuitLog={addLawsuitLog}
        editLawsuitLog={editLawsuitLog}
        lawsuitId={currentLawsuit?._id}
        userId={userId}
      />
      {/* <AddActivityModal
        isAddActivityModal={isAddActivityModal}
        setAddActivityModal={setAddActivityModal}
        currentLawsuit={currentLawsuit}
        updateLawsuit={updateLawsuit}
      /> */}
      <EditLawsuitModal
        isEditLawsuitModal={isEditLawsuitModal}
        setEditLawsuitModal={setEditLawsuitModal}
        currentLawsuit={currentLawsuit}
        debts={debts}
        bailiffs={bailiffs}
        updateLawsuit={updateLawsuit}
        setDebtInputValue={setDebtInputValue}
      />
      <EditIconButton>
        <StyledEditIcon
          onClick={() => {
            setEditLawsuitModal(true);
          }}
        />
      </EditIconButton>

      <MainContent>
        <StyledTable aria-label="simple table">
          <StyledTableBody>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledInfoOutlinedIcon />
                Numer pozwu:
              </TableCellKey>
              <TableCellValue align="left">
                {currentLawsuit?.number}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledAccountBoxIcon />
                Komornik:
              </TableCellKey>
              <TableCellValue align="left">{bailiffName}</TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledEventOutlinedIcon />
                Data:
              </TableCellKey>
              <TableCellValue align="left">
                {new Date(currentLawsuit?.date).toLocaleDateString()}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledEventOutlinedIcon />
                Powiązane długi:
              </TableCellKey>
              <TableCellValue align="left">
                {currentLawsuit?.debtIds.map((debt) => {
                    return (
                      <DebtContainer>
                        <p>
                          {debt?.debtor?.firstName} {debt?.debtor?.lastName} ({debt?.debtor?.pesel}), {debt?.description}
                          <StyledLink to={`/debtors-db/${debt?.debtor?._id}`}>
                            Przejdź
                          </StyledLink>
                        </p>
                        <p>
                          Numer pozwu: {debt?.lawsuitNumber} Opis:{' '}
                          {debt?.description}
                        </p>{' '}
                        <p>
                          Początkowy: {debt?.principal}
                          {debt?.currency}, Pozostało:{' '}
                          {debt?.principalLeft}
                          {debt?.currency}
                        </p>
                      </DebtContainer>
                    );
                  })}
              </TableCellValue>
            </StyledTableRow>
          </StyledTableBody>
        </StyledTable>
        {/* <ActivityContainer>
          <AcitivityHeader>
            <TableHeader>Aktywność</TableHeader>

          </AcitivityHeader>

          <ActivityTable aria-label="simple table">
            <TableHead>
              <TableRow>
                <HeadTableCell align="left">Data</HeadTableCell>
                <HeadTableCell align="right">Status</HeadTableCell>
                <HeadTableCell align="right">Opis</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentLawsuit?.log?.map((log) => {
                return (
                  <TableRow>
                    <TableCell align="left">
                      {new Date(log?.logdate).toLocaleTimeString()}{' '}
                      {new Date(log?.logdate).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">
                      {log?.status === 'info'
                        ? 'Info'
                        : log?.status === 'action-required'
                        ? 'Wymagana akcja'
                        : 'Sukces'}
                    </TableCell>
                    <TableCell align="right">{log?.text}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </ActivityTable>
        </ActivityContainer> */}
  <ActivityContainer>
  <Activity>
            <SectionHeader>Aktywność</SectionHeader>
            {/* <AddActivityBtn
              startIcon={<AddIcon />}
              onClick={() => setAddActivityModal(true)}
            >
              Dodaj aktywność
            </AddActivityBtn> */}
            <ActivityTable aria-label="simple table">
              <TableHead>
                <TableCell>Data</TableCell>
                <TableCell>Treść</TableCell>
                <TableCell>
                  <AddItemButton
                    onClick={() =>
                      setLogModal({ isOpen: true, currentLog: null })
                    }
                  >
                    <StyledPlusIcon />
                  </AddItemButton>
                </TableCell>
              </TableHead>
              {currentLawsuit?.logs?.length > 0 ? (
                <StyledTableBody>
                  {currentLawsuit?.logs
                    .filter(({ _id }) => _id)
                    .map(({ _id, text, status, date }) => (
                      <ActivityRow key={_id} status={status}>
                        <TableCellValue align="left">
                          {moment(date).format('DD.MM.YYYY')}
                        </TableCellValue>
                        <TableCellValue align="left">{text}</TableCellValue>
                        <ButtonTableCell>
                          <AddItemButton
                            padding={false}
                            onClick={() =>
                              setLogModal({
                                isOpen: true,
                                currentLog: { _id, text, status, date },
                              })
                            }
                          >
                            <StyledEditIcon />
                          </AddItemButton>
                        </ButtonTableCell>
                      </ActivityRow>
                    ))}
                </StyledTableBody>
              ) : (
                <NoItems colSpan={3}>Brak aktywności</NoItems>
              )}
            </ActivityTable>
            <StyledTablePagination
              component="div"
              page={logPage}
              onChangePage={(e, newPage) => setLogPage(newPage)}
              disableNext={currentLawsuit?.logs.length === 0}
            />
          </Activity>
  </ActivityContainer>

      </MainContent>
    </Wrapper>
  );
};

export default SingleLawsuitView;

const Wrapper = styled(Paper)`
  width: 100%;
  margin: 20px;
  padding: 20px;
  position: relative;
  margin-left: 85px;
`;

const StyledTableBody = styled(TableBody)``;

const StyledTable = styled(Table)`
  width: calc(50% - 20px);
`;

const ActivityTable = styled(Table)`
width: 100%;
`

const StyledTableRow = styled(TableRow)`
  border-bottom: 1px solid rgba(224, 224, 224);
  position: relative;
  ${({ noBorder }) => noBorder && `border-bottom: none;`}
`;

const TableCellKey = styled(TableCell)`
  width: 220px;
  border-bottom: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  margin: 0;
  padding-left: 0;
`;

const TableCellValue = styled(TableCell)`
  width: 9999px;
  border: none;
`;

const MainContent = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`;

const iconsStyles = css`
  margin-right: 5px;
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  ${iconsStyles};
`;

const StyledAccountBoxIcon = styled(AccountBoxIcon)`
  ${iconsStyles};
`;

const StyledEventOutlinedIcon = styled(EventOutlinedIcon)`
  ${iconsStyles};
`;

const StyledEditIcon = styled(EditOutlinedIcon)`
  color: #4c5bac;
`;

const EditIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const HeadTableCell = styled(TableCell)`
  font-weight: 600;
`;

const TableHeader = styled.h3``;

// const ActivityContainer = styled.div`
//   width: 50%;
// `;

const AddActivityBtn = styled(Button)`
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;

const AcitivityHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 10px;
`;

const DebtContainer = styled.div`
  &:not(:first-child) {
    border-top: 1px solid #e0e0e0;
    margin-top: 5px;
    padding-top: 5px;
  }
`;

const Activity = styled.div`
`;

const ActivityContainer = styled.div`
  width: calc(50% - 20px);
`

const SectionHeader = styled.h3`
  margin: 30px 0 10px;
`;

const ActivityRow = styled(TableRow)`
  vertical-align: top;
  td {
    color: inherit;
    &:nth-child(2) {
      width: 70%;
    }
    &:last-child {
      display: flex;
      justify-content: center;
    }
  }
  ${({ status, theme }) =>
    status === 'INFO'
      ? `
      color:${theme.info};
    `
      : status === 'DANGER'
      ? `
      color:${theme.danger};
    `
      : status === 'WARNING'
      ? `
      color:${theme.warning};
    `
      : status === 'SUCCESS'
      ? `
      color:${theme.green};
    `
      : ``}
`;

const AddItemButton = styled(IconButton)`
  padding: 5px;
  ${({ padding }) => padding === false && `padding: 0`}
`;

const StyledPlusIcon = styled(AddCircleOutlineOutlinedIcon)`
  color: rgb(69, 193, 91);
`;

const ButtonTableCell = styled(TableCell)`
  display: flex;
  border: none;
`;

const NoItems = styled(TableCellValue)`
  text-align: center;
  padding: 30px 0;
`;

const StyledTablePagination = styled(TablePagination)`
  > div {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    p {
      display: none;
    }
    > div:not(:last-child) {
      display: none;
    }
    > div:last-child button:last-child {
      ${({ disableNext }) =>
        disableNext &&
        `
      color: rgba(0, 0, 0, 0.26);
      `}
    }
  }
`;