import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled, { css } from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditPaymentModal from './modals/EditPaymentModal';
import WarningModal from '../SingleDebtor/modals/WarningModal';

const SingleDebtView = ({
  currentPayment,
  setEditPaymentModal,
  isEditPaymentModal,
  editPayment,
  setDebtInputValue,
  useDebounce,
  debts,
  getDebtsWithDebtor,
  warningModal,
  setWarningModal,
}) => {
  return (
    <Wrapper>
      <EditPaymentModal
        isEditPaymentModal={isEditPaymentModal}
        setEditPaymentModal={setEditPaymentModal}
        editPayment={editPayment}
        currentPayment={currentPayment}
        debts={debts}
        useDebounce={useDebounce}
        getDebtsWithDebtor={getDebtsWithDebtor}
        setDebtInputValue={setDebtInputValue}
        warningModal={warningModal}
        setWarningModal={setWarningModal}
      />
      <WarningModal
        show={warningModal.shown}
        question={warningModal.question}
        onAccept={warningModal.onAccept}
        preventClosing={warningModal.preventClosing}
        setModalState={setWarningModal}
        questionTitle={warningModal.questionTitle}
      />
      {/* <BackButton component={Link} to="/debts">
        <ArrowBackIcon />
      </BackButton> */}
      <EditIconButton disabled={currentPayment?.debt}>
        <StyledEditIcon
          disabled={currentPayment?.debt}
          onClick={() => {
            setEditPaymentModal(true);
          }}
        />
      </EditIconButton>
      <MainContent>
        <StyledTable aria-label="simple table">
          <StyledTableBody>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledPersonOutlineOutlinedIcon />
                Dłużnik:
              </TableCellKey>
              <TableCellValue align="left">
                {currentPayment?.debt
                  ? `${currentPayment?.debt?.debtor?.firstName} ${currentPayment?.debt?.debtor?.lastName}, PESEL: ${currentPayment?.debt?.debtor?.pesel}`
                  : 'Brak przypisanego dłużnika'}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledAccountBalanceWalletOutlinedIcon />
                Portfel długu:
              </TableCellKey>
              <TableCellValue align="left">
                {currentPayment?.debt
                  ? currentPayment?.debt?.debtWallet
                  : 'Brak przypisanego portfela'}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledLayersOutlinedIcon />
                Dług:
              </TableCellKey>
              <TableCellValue align="left">
                {currentPayment?.debt
                  ? currentPayment?.debt?.description
                  : 'Brak Przypisanego długu'}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledMonetizationOnOutlinedIcon />
                Wartość:
              </TableCellKey>
              <TableCellValue align="left">
                {`${currentPayment?.value} ${currentPayment?.currency}`}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledDescriptionOutlinedIcon />
                Opis:
              </TableCellKey>
              <TableCellValue align="left">
                {currentPayment?.description}
              </TableCellValue>
            </StyledTableRow>
            <StyledTableRow key={'Adresy'}>
              <TableCellKey scope="row">
                <StyledEventOutlinedIcon />
                Data:
              </TableCellKey>
              <TableCellValue align="left">
                {new Date(currentPayment?.date).toLocaleDateString()}
              </TableCellValue>
            </StyledTableRow>
          </StyledTableBody>
        </StyledTable>
      </MainContent>
    </Wrapper>
  );
};

export default SingleDebtView;

const Wrapper = styled(Paper)`
  width: 100%;
  margin: 20px;
  padding: 20px;
  position: relative;
  margin-left: 85px;
`;

const BackButton = styled(IconButton)`
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 10px;
`;

const StyledTableBody = styled(TableBody)``;

const StyledTable = styled(Table)`
  width: calc(50% - 20px);
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 1px solid rgba(224, 224, 224);
  position: relative;
`;

const TableCellKey = styled(TableCell)`
  width: 220px;
  border-bottom: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  margin: 0;
  padding-left: 0;
`;

const TableCellValue = styled(TableCell)`
  width: 9999px;
  border: none;
`;

const MainContent = styled.div`
  margin-top: 25px;
`;

const iconsStyles = css`
  margin-right: 5px;
`;

const StyledHomeIcon = styled(HomeOutlinedIcon)`
  ${iconsStyles};
`;

const StyledPersonOutlineOutlinedIcon = styled(PersonOutlineOutlinedIcon)`
  ${iconsStyles};
`;

const StyledPersonPinOutlinedIcon = styled(PersonPinOutlinedIcon)`
  ${iconsStyles};
`;

const StyledAccountBalanceWalletOutlinedIcon = styled(
  AccountBalanceWalletOutlinedIcon,
)`
  ${iconsStyles};
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  ${iconsStyles};
`;

const StyledLayersOutlinedIcon = styled(LayersOutlinedIcon)`
  ${iconsStyles};
`;

const StyledMonetizationOnOutlinedIcon = styled(MonetizationOnOutlinedIcon)`
  ${iconsStyles};
`;

const StyledDescriptionOutlinedIcon = styled(DescriptionOutlinedIcon)`
  ${iconsStyles};
`;

const StyledEventOutlinedIcon = styled(EventOutlinedIcon)`
  ${iconsStyles};
`;

const StyledEditIcon = styled(EditOutlinedIcon)`
  color: #4c5bac;
  ${({ disabled }) => disabled && 'color: rgba(0, 0, 0, 0.26);'}
`;

const EditIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
