import React, { useEffect, useState } from 'react';
import SingleLawsuitView from './SingleLawsuitView';
import {
  getDebtsWithDebtor,
  editDebt,
  addDebtDocumentsToDebt,
} from '../../redux/modules/debts';
import { getBusinessPartners } from '../../redux/modules/businessPartners';
import { getDebtWallets } from '../../redux/modules/debtWallets';
import { getDebtors } from '../../redux/modules/debtors';
import { getLawsuitById, updateLawsuit, getLawsuitLogs,addLawsuitLog,editLawsuitLog } from '../../redux/modules/lawsuits';
import { getBailiffs } from '../../redux/modules/user';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const SingleDebtContainer = ({
  currentLawsuit,
  getLawsuitById,
  editDebt,
  debtors,
  businessPartners,
  debtWallets,
  addDebtDocumentsToDebt,
  getDebtsWithDebtor,
  debts,
  getBailiffs,
  bailiffs,
  updateLawsuit,
  match: {
    params: { id },
  },
  getLawsuitLogs,
  userId,
  addLawsuitLog,
  editLawsuitLog
}) => {
  const [isEditLawsuitModal, setEditLawsuitModal] = useState(false);
  const [isAddActivityModal, setAddActivityModal] = useState(false);
  const [logPage, setLogPage] = useState(0);
  const [logModal, setLogModal] = useState({ isOpen: false, currentLog: null });

  const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
  
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
  
    return debouncedValue;
  }

  const [debtInputValue,setDebtInputValue] = useState('')
  const debouncedInputValue = useDebounce(debtInputValue, 200);

  useEffect(
    () => {
      getDebtsWithDebtor(1, 20, debouncedInputValue)
    },
    [debouncedInputValue] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    getLawsuitById(id);
    getBailiffs();
    if (debts?.length === 0) {
      getDebtsWithDebtor(1, 25, '');
    }
  }, []);

  useEffect(() => {
    if (currentLawsuit?._id) {
      getLawsuitLogs(currentLawsuit?._id, 4, logPage * 4);
    }
  }, [logPage]);

  return (
    <SingleLawsuitView
      isEditLawsuitModal={isEditLawsuitModal}
      setEditLawsuitModal={setEditLawsuitModal}
      currentLawsuit={currentLawsuit}
      isAddActivityModal={isAddActivityModal}
      setAddActivityModal={setAddActivityModal}
      getLawsuitById={getLawsuitById}
      debtors={debtors}
      businessPartners={businessPartners}
      debtWallets={debtWallets}
      addDebtDocumentsToDebt={addDebtDocumentsToDebt}
      debts={debts}
      bailiffs={bailiffs}
      updateLawsuit={updateLawsuit}
      setDebtInputValue={setDebtInputValue}
      logPage={logPage}
      setLogPage={setLogPage}
      logModal={logModal}
      setLogModal={setLogModal}
      userId={userId}
      addLawsuitLog={addLawsuitLog}
      editLawsuitLog={editLawsuitLog}
    />
  );
};

const mapStateToProps = (state) => ({
  currentLawsuit: state.lawsuits.current,
  debtors: state.debtors.data,
  businessPartners: state.businessPartners.data,
  debtWallets: state.debtWallets.data,
  debts: state.debts.data,
  bailiffs: state.user.bailiffs,
  userId: state.user.user?._id,
});

const mapDispatchToProps = (dispatch) => {
  return {
    editDebt: (debtBody, debtID) => dispatch(editDebt(debtBody, debtID)),
    getLawsuitById: (debtID) => dispatch(getLawsuitById(debtID)),
    getDebtors: () => dispatch(getDebtors()),
    getBusinessPartners: () => dispatch(getBusinessPartners()),
    getDebtWallets: () => dispatch(getDebtWallets()),
    addDebtDocumentsToDebt: (debtId, debtDocuments) =>
      dispatch(addDebtDocumentsToDebt(debtId, debtDocuments)),
    getDebtsWithDebtor: (pageNumber, pageSize, searchTerm) =>
      dispatch(getDebtsWithDebtor(pageNumber, pageSize, searchTerm)),
    getBailiffs: () => dispatch(getBailiffs()),
    updateLawsuit: (lawsuitId, lawsuitBody) =>
      dispatch(updateLawsuit(lawsuitId, lawsuitBody)),
      getLawsuitLogs:(lawsuitId, limit, skip) =>
      dispatch(getLawsuitLogs(lawsuitId, limit, skip)),
    addLawsuitLog: (lawsuitId, logBody) =>
      dispatch(addLawsuitLog(lawsuitId, logBody)),
    editLawsuitLog: (logId, logBody) => dispatch(editLawsuitLog(logId, logBody)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleDebtContainer),
);
