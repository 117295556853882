import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Link } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import { countInterest } from '../../redux/utils';
import moment from 'moment';

import BailiffModal from './modals/BailiffModal';

const BailiffAssignView = ({
  debts,
  handleSearchInputChange,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  count,
  bailiffs,
  bailiffModal,
  setBailiffModal,
  updateLawsuit,
  lawsuits,
}) => {
  return (
    <>
      <BailiffModal
        modalState={bailiffModal}
        setModalState={setBailiffModal}
        updateLawsuit={updateLawsuit}
        bailiffs={bailiffs}
      />
      <DebtorsDbContainer>
        {/* <FiltersContainer>
          <TextField
            id="outlined-basic"
            label="Wyszukaj"
            onChange={(event) => handleSearchInputChange(event.target.value)}
          />
        </FiltersContainer> */}
        <DebtorsList>
          <PageSubTitle>Przydziel komornika</PageSubTitle>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <HeadTableCell align="right">Data założenia</HeadTableCell>
                  <HeadTableCell align="right">Numer</HeadTableCell>
                  <HeadTableCell align="right">Komornik</HeadTableCell>
                  <HeadTableCell align="right" colSpan={4}>
                    Powiązane długi
                  </HeadTableCell>
                  <HeadTableCell align="right"></HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lawsuits.length > 0 ? (
                  lawsuits?.map(({ _id, date, number, bailiffId, debtIds }) => {
                    const { name, surname } = bailiffs.find(
                      ({ _id }) => _id === bailiffId,
                    ) ?? { name: 'Brak', surname: '' };
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {moment(date).format('DD.MM.YYYY')}
                        </TableCell>
                        <TableCell align="right">{number}</TableCell>
                        <TableCell align="right">{`${name} ${surname}`}</TableCell>
                        <TableCell align="right" colSpan={4}>
                          {debts.length > 0 &&
                            debtIds.map((debt) => {
                              const { debtor } = debts.find(
                                ({ _id }) => _id === debt,
                              );
                              return (
                                <p>
                                  {debtor.firstName} {debtor.lastName} (
                                  {debtor.pesel})
                                </p>
                              );
                            })}
                        </TableCell>
                        <TableCell align="right">
                          <AssignBtn
                            onClick={() =>
                              setBailiffModal({
                                isOpen: true,
                                lawsuit: { _id, date, number, debtIds },
                              })
                            }
                          >
                            Przydziel
                          </AssignBtn>
                          <IconButton
                            aria-label="link"
                            component={Link}
                            to={`/lawsuits/${_id}`}
                          >
                            <ArrowForwardIosRoundedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <NoItemsMsg
                      component="th"
                      scope="row"
                      colSpan={5}
                      align="center"
                    >
                      Brak spraw do przypisania.
                    </NoItemsMsg>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </DebtorsList>
      </DebtorsDbContainer>
    </>
  );
};

export default BailiffAssignView;

const AssignBtn = styled(Button)`
  color: white;
  background-color: #4c5bac;
  margin-right: 5px;
  &:hover {
    background-color: #030b56;
  }
`;

const HeadTableCell = styled(TableCell)`
  font-weight: 600;
`;
const PageSubTitle = styled.h2`
  margin: 0;
  margin-bottom: 20px;
`;

const DebtorsDbContainer = styled(Paper)`
  width: calc(100vw - 105px);
  height: calc(100vh - 40px);
  margin: 20px auto;
  margin-left: 85px;
`;

const FiltersContainer = styled.div`
  padding: 20px;
`;

const DebtorsList = styled.div`
  padding: 20px;
  position: relative;
`;

const NoItemsMsg = styled(TableCell)`
  padding: 30px 0;
  font-size: 16px;
`;
