import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddDebtorModal from './modals/AddDebtorModal';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Link } from 'react-router-dom';
import { countBalanceSum } from '../../redux/utils';
import { Tabs, Tab, AppBar } from '@material-ui/core';

const DebtorsDbView = ({
  debtors,
  addDebtor,
  addDebtorModalOpen,
  setAddDebtorModalOpen,
  handleSearchInputChange,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  count,
  displaySwitch,
  setDisplaySwitch,
}) => {
  return (
    <>
      <AddDebtorModal
        isAddDebtorModal={addDebtorModalOpen}
        setAddDebtorModal={setAddDebtorModalOpen}
        addDebtor={addDebtor}
      />
      <DebtorsDbContainer>
        <FiltersContainer>
          <TextField
            id="outlined-basic"
            label="Wyszukaj"
            onChange={(event) => handleSearchInputChange(event.target.value)}
          />
          <DisplaySwitchContainer>
            <AppBar position="static" color="primary">
              <Tabs value={displaySwitch} aria-label="display switch">
                <Tab label="Wszyscy" onClick={() => setDisplaySwitch(0)} />
                <Tab
                  label="Przypisani do Ciebie"
                  onClick={() => setDisplaySwitch(1)}
                />
              </Tabs>
            </AppBar>
          </DisplaySwitchContainer>
        </FiltersContainer>
        <DebtorsList>
          <PageSubTitle>Baza Danych</PageSubTitle>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <HeadTableCell>Imię i Nazwisko</HeadTableCell>
                  <HeadTableCell align="right">PESEL</HeadTableCell>
                  <HeadTableCell align="right">Adresy</HeadTableCell>
                  <HeadTableCell align="right">Telefony</HeadTableCell>
                  <HeadTableCell align="right">Konta Bankowe</HeadTableCell>
                  <HeadTableCell align="right">
                    Ostatnie zadanie
                  </HeadTableCell>
                  <HeadTableCell align="right">Saldo</HeadTableCell>
                  <HeadTableCell align="right">
                    <AddDebtorBtn
                      startIcon={<AddIcon />}
                      onClick={() => setAddDebtorModalOpen(true)}
                    >
                      Dodaj
                    </AddDebtorBtn>
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {debtors.length > 0 &&
                  debtors?.map((debtor) => {
                    const defaultAddress = debtor.addresses.find(
                      (address) => address.defaultAddress,
                    );
                    const defaultPhone = debtor.phones.find(
                      (phone) => phone.defaultPhoneNumber,
                    );
                    const defaultBankAccount = debtor.bankAccounts.find(
                      (bankAccount) => bankAccount.defaultBankAccount,
                    );
                    const newestLog =
                      debtor?.logs?.length > 0
                        ? debtor.logs[0]
                        : { text: 'Brak zadań' };
                    return (
                      <TableRow key={debtor._id}>
                        <TableCell component="th" scope="row">
                          {debtor.firstName + ' ' + debtor.lastName}
                        </TableCell>
                        <TableCell align="right">{debtor.pesel}</TableCell>
                        <TableCell align="right">
                          {debtor.addresses[0]
                            ? defaultAddress
                              ? `${defaultAddress.street}, ${defaultAddress.city}, ${defaultAddress.postalCode}`
                              : debtor.addresses[0].street +
                                ', ' +
                                debtor.addresses[0].city +
                                ', ' +
                                debtor.addresses[0].postalCode
                            : 'Brak adresu'}
                        </TableCell>
                        <TableCell align="right">
                          {debtor.phones[0]
                            ? defaultPhone
                              ? defaultPhone.phoneNumber
                              : debtor.phones[0].phoneNumber
                            : 'Brak telefonu'}
                        </TableCell>
                        <TableCell align="right">
                          {debtor.bankAccounts[0]
                            ? defaultBankAccount
                              ? defaultBankAccount.accountNumber
                              : debtor.bankAccounts[0].accountNumber
                            : 'Brak numeru konta'}
                        </TableCell>
                        <LogTableCell align="right" status={newestLog.status}>
                          {newestLog.text}
                        </LogTableCell>
                        <TableCell align="right">
                          {debtor.balance[0]
                            ? `${countBalanceSum(debtor.balance)} PLN`
                            : 'Brak wpisów salda'}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="link"
                            component={Link}
                            to={`/debtors-db/${debtor._id}`}
                          >
                            <ArrowForwardIosRoundedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </DebtorsList>
      </DebtorsDbContainer>
    </>
  );
};

export default DebtorsDbView;

const AddDebtorBtn = styled(Button)`
  /* position: absolute;
    right: 20px;
    top: 20px; */
  /* margin-top: 10px; */
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;

const HeadTableCell = styled(TableCell)`
  font-weight: 600;
`;
const PageSubTitle = styled.h2`
  margin: 0;
  margin-bottom: 20px;
`;

const DebtorsDbContainer = styled(Paper)`
  width: calc(100vw - 105px);
  height: calc(100vh - 40px);
  margin: 20px auto;
  margin-left: 85px;
`;

const FiltersContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const DebtorsList = styled.div`
  padding: 20px;
  position: relative;
`;

const DisplaySwitchContainer = styled.div`
  header {
    box-shadow: none;
    span.MuiTabs-indicator {
      background-color: ${({ theme }) => theme.primary};
    }
  }
  .MuiTabs-root {
    background-color: #fff;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    min-height: 32px;
    .MuiTab-root {
      min-height: inherit;
      padding: 3px 8px;
    }
    .MuiTab-wrapper {
      text-transform: none;
    }
  }
`;

const LogTableCell = styled(TableCell)`
  max-width: 250px;
  ${({ status, theme }) =>
    status === 'INFO'
      ? `
      color:${theme.info};
    `
      : status === 'DANGER'
      ? `
      color:${theme.danger};
    `
      : status === 'WARNING'
      ? `
      color:${theme.warning};
    `
      : status === 'SUCCESS'
      ? `
      color:${theme.green};
    `
      : ``}
`;
