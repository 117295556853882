import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Editor } from '@tinymce/tinymce-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

const DocumentView = ({
  currentDocument,
  name,
  setName,
  setContent,
  content,
  editCurrentDocument,
  status,
  setStatus,
  handleEditorChange,
}) => {
  return (
    <Wrapper>
      <Title>Edycja dokumentu</Title>
      <TopHeader>
        <TextField
          id="standard-basic"
          label="Nazwa"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          id="standard-select-currency"
          select
          label="Status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          // helperText="Please select your currency"
        >
          <MenuItem value={'CREATED'}>Utworzony</MenuItem>
          <MenuItem value={'SENT'}>Wysłany</MenuItem>
          <MenuItem value={'RECEIVED'}>Odebrany</MenuItem>
          <MenuItem value={'REJECTED'}>Odrzucony</MenuItem>
        </TextField>
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={name === ''}
            onClick={() =>
              //editDocument()
              editCurrentDocument(
                {
                  ...currentDocument,
                  content: content,
                  name: name,
                  status: status,
                },
                currentDocument?._id,
              )
            }
          >
            Zapisz
          </Button>
          <Button
            variant="contained"
            color="danger"
            disabled={name === ''}
            onClick={() => {
              console.log('delete document');
            }}
          >
            Usuń
          </Button>
        </div>
      </TopHeader>
      <Paper>
        <Editor
          initialValue={content}
          init={{
            valid_elements: '*[*]',
            height: 925,
            width: 680,
            menubar: true,
            language: 'pl',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            content_style: '.tox-notifications-container { display: none }',
            toolbar:
              // eslint-disable-next-line no-multi-str
              'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help',
          }}
          onEditorChange={handleEditorChange}
          onInit={() => {
            setTimeout(() => {
              document.querySelector(
                '.tox-notifications-container',
              ).style.display = 'none';
            }, 1);
          }}
        />
      </Paper>
    </Wrapper>
  );
};

export default DocumentView;

const Title = styled.h1`
  font-size: 24px;
`;

const TopHeader = styled.div`
  display: flex;
  margin: 15px 0;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  margin: 20px auto;
`;
