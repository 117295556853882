import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddDebtModal from './modals/AddDebtModal';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Link } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import { countInterest } from '../../redux/utils';
import moment from 'moment';
import {debtTypeDictionary} from '../../redux/utils'

const DebtsView = ({
  debts,
  addDebt,
  isAddDebtModal,
  setAddDebtModal,
  filterDebts,
  debtors,
  handleSearchInputChange,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  count,
  addDebtWithDocuments,
  shapeRequestFormData,
  businessPartners,
  debtWallets,
}) => {
  return (
    <>
      <AddDebtModal
        isAddDebtModal={isAddDebtModal}
        setAddDebtModal={setAddDebtModal}
        addDebt={addDebt}
        addDebtWithDocuments={addDebtWithDocuments}
        debtors={debtors}
        shapeRequestFormData={shapeRequestFormData}
        businessPartners={businessPartners}
        debtWallets={debtWallets}
      />

      <DebtorsDbContainer>
        <FiltersContainer>
          <TextField
            id="outlined-basic"
            label="Wyszukaj"
            onChange={(event) => handleSearchInputChange(event.target.value)}
          />
        </FiltersContainer>
        <DebtorsList>
          <PageSubTitle>Baza długów</PageSubTitle>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <HeadTableCell align="right">
                    Data Wierzytelności
                  </HeadTableCell>
                  <HeadTableCell align="right">Typ</HeadTableCell>
                  <HeadTableCell align="right">Podstawa</HeadTableCell>
                  <HeadTableCell align="right">Kwota Pierwotna</HeadTableCell>
                  <HeadTableCell align="right">
                    Kwota do spłacenia
                  </HeadTableCell>
                  <HeadTableCell align="right">
                    Data Ostatniej spłaty
                  </HeadTableCell>
                  <HeadTableCell align="right">Numer Pozwu</HeadTableCell>
                  <HeadTableCell align="right">Spłacony</HeadTableCell>
                  <HeadTableCell align="right">
                    <AddDebtBtn
                      startIcon={<AddIcon />}
                      onClick={() => setAddDebtModal(true)}
                    >
                      Dodaj
                    </AddDebtBtn>
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {debts.length > 0 &&
                  debts?.map((debt) => {
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {new Date(debt.date).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="right">
                        {debtTypeDictionary(debt.type)}
                        </TableCell>
                        <TableCell align="right">{debt.description}</TableCell>
                        <TableCell align="right">
                          {debt.principal} {debt.currency}
                        </TableCell>
                        <TableCell align="right">
                          {debt.startedRepayment ? 
                            countInterest(debt.principalLeft + debt.interestsLeft,moment()
                            .startOf('day')
                            .diff(debt?.lastPaymentDate?.slice(0, 10), 'years', true),
                          debt.interestRate,
                        )
                          :
                          countInterest(
                            debt.principal,
                            moment()
                              .startOf('day')
                              .diff(debt?.date?.slice(0, 10), 'years', true),
                            debt.interestRate,
                          )}{' '}
                          {debt.currency}
                        </TableCell>
                        <TableCell align="right">
                          {debt.startedRepayment
                            ? new Date(
                                debt?.lastPaymentDate,
                              ).toLocaleDateString()
                            : 'Brak Spłat'}
                        </TableCell>
                        <TableCell align="right">
                          {debt.lawsuitNumber}
                        </TableCell>
                        <TableCell align="right">
                          {debt.repaid ? 'Tak' : 'Nie'}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="link"
                            component={Link}
                            to={`/debts/${debt._id}`}
                          >
                            <ArrowForwardIosRoundedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </DebtorsList>
      </DebtorsDbContainer>
    </>
  );
};

export default DebtsView;

const AddDebtBtn = styled(Button)`
  /* position: absolute;
    right: 20px;
    top: 20px; */
  /* margin-top: 10px; */
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;

const HeadTableCell = styled(TableCell)`
  font-weight: 600;
`;
const PageSubTitle = styled.h2`
  margin: 0;
  margin-bottom: 20px;
`;

const DebtorsDbContainer = styled(Paper)`
  width: calc(100vw - 105px);
  height: calc(100vh - 40px);
  margin: 20px auto;
  margin-left: 85px;
`;

const FiltersContainer = styled.div`
  padding: 20px;
`;

const DebtorsList = styled.div`
  padding: 20px;
  position: relative;
`;
