import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled, { css } from 'styled-components';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditWalletModal from './modals/EditWalletModal';
import EditDebtModal from './modals/EditDebtModal';
import CombineColumnNamesModal from './modals/CombineColumnNamesModal';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { countInterest } from '../../redux/utils';
import moment from 'moment';
import FileUpload from '../../shared/FileUpload';
import TablePagination from '@material-ui/core/TablePagination';

const SingleWalletView = ({
  isEditWalletModal,
  setEditWalletModal,
  editDebtWallet,
  currentWallet,
  businessPartners,
  getDebtWalletById,
  walletDebts,
  isEditDebtModal,
  setEditDebtModal,
  debtWallets,
  editedDebt,
  setEditedDebt,
  selectedDebts,
  setDebtsSelected,
  editDebts,
  setSelectedFile,
  selectedFile,
  importDebtsToDebtWallet,
  debtsForImport,
  isImportReady,
  setIsImportReady,
  setIsCombineColumnNamesModal,
  isCombineColumnNamesModal,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  count,
  getDebtWalletDebts,
  importWalletDebtsError,
  importedWalletDebts,
}) => {
  return (
    <Wrapper>
      <EditWalletModal
        isEditWalletModal={isEditWalletModal}
        setEditWalletModal={setEditWalletModal}
        editDebtWallet={editDebtWallet}
        currentWallet={currentWallet}
        getDebtWalletById={getDebtWalletById}
        businessPartners={businessPartners}
      />
      <EditDebtModal
        isEditDebtModal={isEditDebtModal}
        setEditDebtModal={setEditDebtModal}
        debtWallets={debtWallets}
        currentWallet={currentWallet}
        editedDebt={editedDebt}
        selectedDebts={selectedDebts}
        editDebts={editDebts}
      />
      <CombineColumnNamesModal
        isImportReady={isImportReady}
        debtsForImport={debtsForImport}
        setIsImportReady={setIsImportReady}
        selectedFile={selectedFile}
        importDebtsToDebtWallet={importDebtsToDebtWallet}
        currentWallet={currentWallet}
        setIsCombineColumnNamesModal={setIsCombineColumnNamesModal}
        isCombineColumnNamesModal={isCombineColumnNamesModal}
        importWalletDebtsError={importWalletDebtsError}
        importedWalletDebts={importedWalletDebts}
        getDebtWalletDebts={getDebtWalletDebts}
        page={page}
        rowsPerPage={rowsPerPage}
      />
      <EditIconButton>
        <StyledEditIcon
          onClick={() => {
            setEditWalletModal(true);
          }}
        />
      </EditIconButton>
      <MainContent>
        <MainLeftSide>
          <StyledTable aria-label="simple table">
            <StyledTableBody>
              <StyledTableRow key={'Nazwa'}>
                <TableCellKey scope="row">
                  <StyledAccountBalanceWalletOutlinedIcon />
                  Nazwa:
                </TableCellKey>
                <TableCellValue align="left">
                  {currentWallet?.name}
                </TableCellValue>
              </StyledTableRow>
              <StyledTableRow key={'Data'}>
                <TableCellKey scope="row">
                  <StyledEventOutlinedIcon />
                  Data:
                </TableCellKey>
                <TableCellValue align="left">
                  {new Date(currentWallet?.date).toLocaleDateString()}
                </TableCellValue>
              </StyledTableRow>
              <StyledTableRow key={'Wartość'}>
                <TableCellKey scope="row">
                  <StyledMonetizationOnOutlinedIcon />
                  Wartość:
                </TableCellKey>
                <TableCellValue align="left">
                  {currentWallet?.price}
                </TableCellValue>
              </StyledTableRow>
              <StyledTableRow key={'Kontrahenci'}>
                <TableCellKey scope="row">
                  <StyledInfoOutlinedIcon />
                  Kontrahenci:
                </TableCellKey>
                <TableCellValue align="left">
                  {currentWallet?.conditions.map((con) => (
                    <p>
                      {
                        businessPartners.find(
                          (bp) => bp._id === con.businessPartner,
                        )?.name
                      }
                      , {con.yield}%
                    </p>
                  ))}
                </TableCellValue>
              </StyledTableRow>
            </StyledTableBody>
          </StyledTable>
          <ImportContainer>
            <DebtsTitle>Import długów</DebtsTitle>
            <StyledFileUpload
              testId="tabs_dropfile"
              onDrop={(files) => setSelectedFile(files[0])}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={selectedFile && isImportReady ? false : true}
              onClick={async () => {
                if (selectedFile) {
                  const formData = new FormData();
                  formData.append('debts', selectedFile);
                  await importDebtsToDebtWallet(formData, currentWallet._id);
                  await getDebtWalletDebts(
                    currentWallet._id,
                    page + 1,
                    rowsPerPage,
                  );
                }
              }}
            >
              ZAIMPORTUJ
            </Button>
          </ImportContainer>
        </MainLeftSide>
        <DebtsWrapper>
          <DebtsHeader>
            <DebtsTitle>Długi przypisane do tego portfela</DebtsTitle>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedDebts.length === 0}
              onClick={() => {
                setEditedDebt(selectedDebts);
                setEditDebtModal(true);
              }}
            >
              Przenieś {selectedDebts.length}{' '}
              {selectedDebts.length === 1
                ? `dług`
                : selectedDebts.length < 5 && selectedDebts.length > 0
                ? `długi`
                : 'długów'}
            </Button>
          </DebtsHeader>
          {walletDebts.length > 0 ? (
            <>
              <TableContainer>
                <Table>
                  <TableBody>
                    {walletDebts?.map((debt, i) => (
                      <StyledTableRow key={debt._id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedDebts.find((el) => el._id === debt._id)
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              e.target.checked
                                ? setDebtsSelected([...selectedDebts, debt])
                                : setDebtsSelected(
                                    selectedDebts.filter(
                                      (el, index) => el._id !== debt._id,
                                    ),
                                  )
                            }
                            inputProps={{ 'aria-label': 'select all desserts' }}
                          />
                        </TableCell>
                        <TableCell>
                          {debt
                            ? debt?.startedRepayment
                              ? `${countInterest(
                                  debt?.principalLeft + debt?.interestsLeft,
                                  moment()
                                    .startOf('day')
                                    .diff(
                                      debt?.lastPaymentDate?.slice(0, 10),
                                      'years',
                                      true,
                                    ),
                                  debt?.interestRate,
                                )} ${debt.currency}`
                              : `${countInterest(
                                  debt?.principal,
                                  moment()
                                    .startOf('day')
                                    .diff(
                                      debt?.date?.slice(0, 10),
                                      'years',
                                      true,
                                    ),
                                  debt?.interestRate,
                                )} ${debt.currency}`
                            : null}
                        </TableCell>
                        <TableCell>{debt.description}</TableCell>
                        <TableCell>{debt?.date?.slice(0, 10)}</TableCell>
                        <TableCell>{debt?.status}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          ) : (
            'Brak długów'
          )}
        </DebtsWrapper>
      </MainContent>
    </Wrapper>
  );
};

export default SingleWalletView;

const FileInputContainer = styled.div`
  height: 100px;
`;

const Wrapper = styled(Paper)`
  width: calc(100vw - 105px);
  height: calc(100vh - 40px);
  margin: 20px auto 20px 85px;
  padding: 20px;
  position: relative;
`;

const StyledTableBody = styled(TableBody)``;

const StyledTable = styled(Table)`
  width: calc(50% - 20px);
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 1px solid rgba(224, 224, 224);
  position: relative;
`;

const TableCellKey = styled(TableCell)`
  width: 220px;
  border-bottom: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  margin: 0;
  padding-left: 0;
`;

const TableCellValue = styled(TableCell)`
  width: 9999px;
  border: none;
`;

const MainContent = styled.div`
  margin-top: 25px;
  display: flex;
`;

const iconsStyles = css`
  margin-right: 5px;
`;

const StyledAccountBalanceWalletOutlinedIcon = styled(
  AccountBalanceWalletOutlinedIcon,
)`
  ${iconsStyles};
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  ${iconsStyles};
`;

const StyledMonetizationOnOutlinedIcon = styled(MonetizationOnOutlinedIcon)`
  ${iconsStyles};
`;

const StyledEventOutlinedIcon = styled(EventOutlinedIcon)`
  ${iconsStyles};
`;

const StyledEditIcon = styled(EditOutlinedIcon)`
  color: #4c5bac;
`;

const EditIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const DebtsWrapper = styled.div`
  min-width: 600px;
`;

const DebtsTitle = styled.h3``;

const DebtsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImportContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
`;

const MainLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 600px;
`;

const StyledFileUpload = styled(FileUpload)`
  min-width: 250px;
`;
