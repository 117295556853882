const parseCsvToJson = (csv) => {
  const lines = csv.split('\n');
  const headers = lines[0].split(',');
  const jsonArray = [];
  for (let i = 1; i < lines.length; i++) {
    const line = lines[i];
    if (line === '') continue;
    const jsonObject = {};
    const tuple = line.split(',');
    for (let j = 0; j < headers.length; j++) {
      const header = headers[j];
      jsonObject[header] = tuple[j];
    }
    jsonArray.push(jsonObject);
  }
  return jsonArray;
};

const parseCsvHeader = (csv) => {
  const lines = csv.split('\n');
  const headers = lines[0].split(',');
  return headers;
};

module.exports = {
  parseCsvToJson,
  parseCsvHeader,
};
