import React, { useEffect, useState } from 'react';
import BailiffDebtsView from './BailiffDebtsView';
import { connect } from 'react-redux';
import {
  getLawsuitsByBailiff,
  updateLawsuit,
} from '../../redux/modules/lawsuits';

const BailiffDebtsContainer = ({
  deleteDebt,
  addDebt,
  addDebtWithDocuments,
  debtors,
  paginationMetadata,
  businessPartners,
  debtWallets,
  updateLawsuit,
  getLawsuitsByBailiff,
  lawsuits,
  user,
}) => {
  const [bailiffModal, setBailiffModal] = useState({
    isOpen: false,
    lawsuit: null,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (user) {
      getLawsuitsByBailiff(user._id);
    }
  }, [user]);

  useEffect(() => {
    if (paginationMetadata?.totalCount) {
      setCount(paginationMetadata.totalCount);
    }
  }, [paginationMetadata]);

  // useEffect(() => {
  //   getDebtsToAssign(page + 1, rowsPerPage, searchTerm);
  // }, [page, rowsPerPage, searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (searchTermInput) => {
    setSearchTerm(searchTermInput);
  };

  const shapeRequestFormData = (values) => {
    let debtWithDocumentsFormData = new FormData();
    const debtDocumentFiles = values?.debtDocuments?.map(
      (debtDocument) => debtDocument.debtDocumentFile,
    );
    debtDocumentFiles.forEach((debtDocumentFile) =>
      debtWithDocumentsFormData.append('debt-documents', debtDocumentFile),
    );
    const debt = values;
    debt.debtDocuments.forEach(
      (debtDocument) => delete debtDocument.debtDocumentFile,
    );
    debtWithDocumentsFormData.append('debt', JSON.stringify(debt));
    return debtWithDocumentsFormData;
  };

  return (
    <BailiffDebtsView
      deleteDebt={deleteDebt}
      addDebt={addDebt}
      addDebtWithDocuments={addDebtWithDocuments}
      debtors={debtors}
      handleSearchInputChange={handleSearchInputChange}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      count={count}
      rowsPerPage={rowsPerPage}
      shapeRequestFormData={shapeRequestFormData}
      businessPartners={businessPartners}
      debtWallets={debtWallets}
      bailiffModal={bailiffModal}
      setBailiffModal={setBailiffModal}
      updateLawsuit={updateLawsuit}
      lawsuits={lawsuits}
    />
  );
};

const mapStateToProps = (state) => ({
  paginationMetadata: state.debts.paginationMetadata,
  debtors: state.debtors.data,
  businessPartners: state.businessPartners.data,
  debtWallets: state.debtWallets.data,
  lawsuits: state.lawsuits.data,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateLawsuit: (lawsuitId, lawsuitBody, removeFromStateAfterUpdate) =>
    dispatch(updateLawsuit(lawsuitId, lawsuitBody, removeFromStateAfterUpdate)),
  getLawsuitsByBailiff: (bailiffID) =>
    dispatch(getLawsuitsByBailiff(bailiffID)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BailiffDebtsContainer);
