import React, { useState } from 'react';
import Modal from '../../../shared/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/TextField';
import * as Yup from 'yup';

const AddUserModal = ({ isAddUserModal, setAddUserModal, addUser }) => {
  const [role, setRole] = useState('intern');
  const initialValues = {
    name: '',
    surname: '',
    email: '',
    password: '',
  };

  const AddDebtorSchema = Yup.object().shape({
    name: Yup.string().required('Imię jest wymagane'),
    surname: Yup.string().required('Nazwisko jest wymagane'),
    email: Yup.string().email().required('Email jest wymagany'),
    password: Yup.string().required('Hasło jest wymagane'),
  });

  return (
    <Modal
      title="Dodawanie użytkownika"
      show={isAddUserModal}
      exitButton={true}
      onCancel={() => {
        setAddUserModal(false);
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AddDebtorSchema}
        onSubmit={async (values) => {
          addUser(values, role);
          setAddUserModal(false);
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          setFieldError,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InputRow>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel
                  value="intern"
                  control={<Radio />}
                  label="Intern"
                />
                <FormControlLabel
                  value="bailiff"
                  control={<Radio />}
                  label="Komornik"
                />
              </RadioGroup>
            </InputRow>
            <InputRow>
              <Input
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                error={errors.name}
                label="Imię"
              />
              <Input
                name="surname"
                type="text"
                onChange={handleChange}
                value={values.surname}
                error={errors.surname}
                label="Nazwisko"
              />
            </InputRow>
            <InputRow>
              <Input
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                error={errors.email}
                label="Email"
              />
              <Input
                name="password"
                type="password"
                onChange={handleChange}
                value={values.password}
                error={errors.password}
                label="Hasło"
              />
            </InputRow>
            <CenterBox>
              <Button type="submit" variant="primary">
                Potwierdź
              </Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUserModal;

const Form = styled.form`
  padding-top: 20px;

  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop} {
  width: 450px;
}`}
`;

const AddingNewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin: 10px auto;
`;
const CenterBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    width: 48%;
  }
`;
const UserWrapper = styled.div`
  background-color: #c4c4c4;
  color: #fff;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin: 5px 0;
`;
const UserInfo = styled.p`
  color: #fff;
  margin: 0 5px;
`;

const AddPatientNoOption = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const AddPatientButton = styled.button`
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  padding: 0.25em 0.65em;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.p`
  cursor: pointer;
  color: red;
  font-size: 16px;
`;
