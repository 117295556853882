import axios from 'axios';
//ACTION TYPES---------------------------------
const AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST';
const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
const AUTHENTICATE_USER_FAIL = 'AUTHENTICATE_USER_FAIL';

const TOKEN_AUTH_FAILED = 'TOKEN_AUTH_FAILED';
const TOKEN_AUTH_REQUEST = 'TOKEN_AUTH_REQUEST';
const TOKEN_AUTH_SUCCESS = 'TOKEN_AUTH_SUCCESS';

const LOGOUT_USER = 'LOGOUT_USER';

const UPDATE_AUTHENTICATED_USER_FAILED = 'UPDATE_AUTHENTICATED_USER_FAILED';
const UPDATE_AUTHENTICATED_USER_REQUEST = 'UPDATE_AUTHENTICATED_USER_REQUEST';
const UPDATE_AUTHENTICATED_USER_SUCCESS = 'UPDATE_AUTHENTICATED_USER_SUCCESS';

const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';

const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
const ADD_USER_FAILED = 'ADD_USER_FAILED';
//ACTIONS-------------------------------------

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const checkToken = (token) => async (dispatch) => {
  dispatch(tokenAuthRequest());
  if (!token || token === '') {
    //if there is no token, dont bother
    dispatch(tokenAuthFailed());
  }
  try {
    const userResponse = await axios.get('/user/token', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    dispatch(tokenAuthSuccess(userResponse.data));
  } catch (error) {
    dispatch(tokenAuthFailed(error));
  }
};

const tokenAuthFailed = (error) => ({
  type: TOKEN_AUTH_FAILED,
  payload: error,
});

const tokenAuthRequest = () => ({
  type: TOKEN_AUTH_REQUEST,
});

const tokenAuthSuccess = (user) => ({
  type: TOKEN_AUTH_SUCCESS,
  payload: user,
});

export const authenticateUser = (user) => async (dispatch) => {
  dispatch(requestAuthentication());
  try {
    const response = await axios.post('/user/login', user);

    sessionStorage.setItem('jwtToken', response.data.token);
    // Init axios default config
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${response.data.token}`;
    dispatch(authenticatedSuccess(response.data.user));
    dispatch(response.data.user._id);
  } catch (error) {
    dispatch(authenticationFail(error));
    throw error.response.data.message;
  }
};

const requestAuthentication = () => ({
  type: AUTHENTICATE_USER_REQUEST,
});

export const authenticatedSuccess = (user) => ({
  type: AUTHENTICATE_USER_SUCCESS,
  payload: user,
});

const authenticationFail = (error) => ({
  type: AUTHENTICATE_USER_FAIL,
  payload: error,
});

export const updateAuthenticatedUser = (userData, id) => async (dispatch) => {
  dispatch(updateAuthenticatedUserRequest());
  try {
    const response = await axios.patch(`/user/${id}`, userData);
    const { data } = response;
    dispatch(updateAuthenticatedUserSuccess(data));
  } catch (error) {
    dispatch(updateAuthenticatedUserFail(error.response.data.message));
    throw error.response.data.message;
  }
};

const updateAuthenticatedUserRequest = () => ({
  type: UPDATE_AUTHENTICATED_USER_REQUEST,
});

const updateAuthenticatedUserSuccess = (user) => ({
  type: UPDATE_AUTHENTICATED_USER_SUCCESS,
  payload: user,
});

const updateAuthenticatedUserFail = (error) => ({
  type: UPDATE_AUTHENTICATED_USER_FAILED,
  payload: error,
});

const getAllUsersRequest = () => ({
  type: GET_ALL_USERS_REQUEST,
});

const getAllUsersSuccess = (users) => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: users,
});

const getAllUsersFailed = (error) => ({
  type: GET_ALL_USERS_FAILED,
  payload: error,
});

export const getBailiffs = () => async (dispatch) => {
  dispatch(userRequest());
  try {
    const response = await axios.get(`/user/bailiffs`);
    const { data } = response;
    dispatch(getBailiffsSuccess(data));
  } catch (error) {
    dispatch(userFail(error));
  }
};

const GET_BAILIFFS_SUCCESS = 'GET_BAILIFFS_SUCCESS';
const GET_INTERNS_SUCCESS = 'GET_INTERNS_SUCCESS';
const USER_REQUEST = 'USER_REQUEST';
const USER_FAIL = 'USER_FAIL';

const getBailiffsSuccess = (bailiffs) => ({
  type: GET_BAILIFFS_SUCCESS,
  payload: bailiffs,
});

const userRequest = () => ({
  type: USER_REQUEST,
});

const userFail = (err) => ({
  type: USER_FAIL,
  payload: err,
});

export const getInterns = () => async (dispatch) => {
  dispatch(userRequest());
  try {
    const response = await axios.get(`/user/interns`);
    const { data } = response;
    dispatch(getInternsSuccess(data));
  } catch (error) {
    dispatch(userFail(error));
  }
};

const getInternsSuccess = (interns) => ({
  type: GET_INTERNS_SUCCESS,
  payload: interns,
});

export const getAllUsers = () => async (dispatch) => {
  dispatch(getAllUsersRequest());
  try {
    const response = await axios.get(`/user/`);
    const { data } = response;
    dispatch(getAllUsersSuccess(data));
  } catch (error) {
    dispatch(getAllUsersFailed(error));
    throw error.response.data.message;
  }
};

const addUserRequest = () => ({
  type: ADD_USER_REQUEST,
});

const addUserSuccess = (user) => ({
  type: ADD_USER_SUCCESS,
  payload: user,
});

const addUserFailed = (error) => ({
  type: ADD_USER_FAILED,
  payload: error,
});

export const addUser = (userBody, role) => async (dispatch) => {
  dispatch(addUserRequest());
  try {
    const response = await axios.post(`/user/register/${role}`, userBody);
    const { data } = response;
    dispatch(addUserSuccess(data.data));
  } catch (error) {
    dispatch(addUserFailed(error));
    throw error.response.data.message;
  }
};

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: true,
  error: null,
  users: [],
  bailiffs: [],
  interns: [],
};

//AUTH REDUCER--------------------------------------------------
export default (state = initialState, action) => {
  switch (action.type) {
    //authenticate user call
    case AUTHENTICATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
      };
    case AUTHENTICATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    //get user from token call
    case TOKEN_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TOKEN_AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
        error: null,
      };
    case TOKEN_AUTH_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isAuthenticated: false,
        user: {
          activeRole: null,
        },
      };
    case LOGOUT_USER:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isAuthenticated: false,
        user: {
          activeRole: null,
        },
        userRole: null,
      };
    case UPDATE_AUTHENTICATED_USER_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case UPDATE_AUTHENTICATED_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === action.payload._id ? action.payload : user,
        ),
        loading: false,
      };
    case UPDATE_AUTHENTICATED_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        // loading: false,
        users: action.payload,
      };
    case GET_ALL_USERS_FAILED:
      return {
        ...state,
        // loading: false,
        error: action.payload,
      };
    case ADD_USER_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, action.payload],
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_REQUEST:
      return {
        ...state,
        // loading:true
      };
    case USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_BAILIFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        bailiffs: action.payload,
      };
    case GET_INTERNS_SUCCESS:
      return {
        ...state,
        loading: false,
        interns: action.payload,
      };
    default:
      return state;
  }
};
