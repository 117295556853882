import React, { useEffect, useState } from "react";
import PaymentsPageView from "./PaymentsPageView";
import { connect } from "react-redux";
import { getPayments, addPayment, setCurrentPayment, editPayment, getPaymentsWithDebtsAndDebtors } from "../../redux/modules/payments";
import { getDebtsWithDebtor } from "../../redux/modules/debts";

const PaymentsPageContainer = ({
    addPayment,
    setCurrentPayment,
    editPayment,
    payments,
    currentPayment,
    debts,
    getDebtsWithDebtor,
    getPaymentsWithDebtsAndDebtors,
    paginationMetadata
}) => {
  const [isAddPaymentModal, setAddPaymentModal] = useState(false);
  const [isEditPaymentModal, setEditPaymentModal] = useState(false);
  const [selectDebts, setSelectDebts] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
  
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
  
    return debouncedValue;
  }

  const [debtInputValue,setDebtInputValue] = useState('')
  const debouncedInputValue = useDebounce(debtInputValue, 200);

  useEffect(
    () => {
      getDebtsWithDebtor(1, 20, debouncedInputValue)
    },
    [debouncedInputValue] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (payments?.length === 0) {
      getPaymentsWithDebtsAndDebtors(page + 1, rowsPerPage, searchTerm)
    }
    getDebtsWithDebtor(1, 20, "")
  }, [])

  useEffect(() => {
    if (paginationMetadata?.totalCount) {
      setCount(paginationMetadata.totalCount)
    }
  }, [paginationMetadata])

  useEffect(() => {
    getPaymentsWithDebtsAndDebtors(page + 1, rowsPerPage, searchTerm)
  }, [page, rowsPerPage, searchTerm])

  useEffect(() => {
    if (!payments || payments?.length === 0) {
      setPage(0)
      if (paginationMetadata?.totalCount) {
        setCount(paginationMetadata.totalCount)
      } else {
        setCount(0)
      }
    }
  }, [payments])

  useEffect(() => {
    const mergedArrays = [...debts, ...payments.filter((payment, index, payArray) => index === payArray.findIndex(el => el?.debt?._id == payment?.debt?._id)).map(elem => elem.debt)]
    mergedArrays.filter((debt, index, debtArray) => index === debtArray.findIndex(el => el?._id == debt?._id))
    setSelectDebts(mergedArrays.filter((debt, index, debtArray) => index === debtArray.findIndex(el => el?._id == debt?._id)))
  }, [debts, payments]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (searchTermInput) => {
    setSearchTerm(searchTermInput);
  };

  return (
    <PaymentsPageView
      isAddPaymentModal={isAddPaymentModal}
      setAddPaymentModal={setAddPaymentModal}
      isEditPaymentModal={isEditPaymentModal}
      setEditPaymentModal={setEditPaymentModal}
      payments={payments}
      addPayment={addPayment}
      setCurrentPayment={setCurrentPayment}
      currentPayment={currentPayment}
      editPayment={editPayment}
      debts={selectDebts}
      useDebounce={useDebounce}
      getDebtsWithDebtor={getDebtsWithDebtor}
      debtInputValue={debtInputValue}
      setDebtInputValue={setDebtInputValue}
      handleSearchInputChange={handleSearchInputChange}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      count={count}
      rowsPerPage={rowsPerPage}
    />
  );
};

const mapStateToProps = (state) => ({
    payments: state.payments.data,
    currentPayment: state.payments.current,
    paginationMetadata: state.payments.paginationMetadata,
    debts: state.debts.data
});

const mapDispatchToProps = (dispatch) => ({
    getPayments: () => dispatch(getPayments()),
    addPayment: (payment) => dispatch(addPayment(payment)),
    setCurrentPayment: (paymentId) => dispatch(setCurrentPayment(paymentId)),
    editPayment: (paymentBody, paymentId) => dispatch(editPayment(paymentBody, paymentId)),
    getDebtsWithDebtor: (pageNumber, pageSize, searchTerm) =>
      dispatch(getDebtsWithDebtor(pageNumber, pageSize, searchTerm)),
    getPaymentsWithDebtsAndDebtors: (pageNumber, pageSize, searchTerm) =>
      dispatch(getPaymentsWithDebtsAndDebtors(pageNumber, pageSize, searchTerm))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPageContainer);