import React from "react";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Link } from "react-router-dom";
import TablePagination from '@material-ui/core/TablePagination';

 
const DebtWalletsView = ({ debtWallets,handleSearchInputChange,count, page, rowsPerPage, setPage, setRowsPerPage, handleChangePage, handleChangeRowsPerPage, businessPartners, history }) => {
  return <DebtorsDbContainer>
  <FiltersContainer>
    <TextField
      id="outlined-basic"
      label="Wyszukaj"
      onChange={(event) => handleSearchInputChange(event.target.value)}
    />
  </FiltersContainer>
  <DebtorsList>
    <PageSubTitle>Baza portfeli</PageSubTitle>

    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <HeadTableCell>Nazwa</HeadTableCell>
            <HeadTableCell align="right">Data</HeadTableCell>
            <HeadTableCell align="right">Wartość</HeadTableCell>
            <HeadTableCell align="right">Kontrahenci</HeadTableCell>
            <HeadTableCell align="right">
              <AddWalletBtn
                startIcon={<AddIcon />}
                onClick={() => history.push('/add-wallet')}
              >
                Dodaj
              </AddWalletBtn>
            </HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {debtWallets.length > 0 &&
            debtWallets?.map((debtsWallet) => {
              return (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {debtsWallet.name}
                  </TableCell>
                  <TableCell align="right">
                    {new Date(debtsWallet.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">{debtsWallet.price}</TableCell>
                  <TableCell align="right">
                    {debtsWallet.conditions.map(
                      (con) => <p>{businessPartners.find(bp => bp._id === con.businessPartner)?.name}, {con.yield}%</p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="link"
                      component={Link}
                      to={`/wallets/${debtsWallet._id}`}
                    >
                      <ArrowForwardIosRoundedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 20]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  </DebtorsList>
</DebtorsDbContainer>;
};

export default DebtWalletsView;

const AddWalletBtn = styled(Button)`
  /* position: absolute;
    right: 20px;
    top: 20px; */
  /* margin-top: 10px; */
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;

const HeadTableCell = styled(TableCell)`
  font-weight: 600;
`;
const PageSubTitle = styled.h2`
  margin: 0;
  margin-bottom: 20px;
`;

const DebtorsDbContainer = styled(Paper)`
    width: calc(100vw - 105px);
    height: calc(100vh - 40px);
    margin: 20px auto;
    margin-left: 85px;
`;

const FiltersContainer = styled.div`
  padding: 20px;
`;

const DebtorsList = styled.div`
  padding: 20px;
  position: relative;
`;
