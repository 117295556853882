import React from "react";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import TablePagination from '@material-ui/core/TablePagination';
import AddBusinessPartnerModal from './modals/AddBusinessPartnerModal'
import EditBusinessPartnerModal from './modals/EditBusinessPartnerModal'

const BusinessPartnersView = ({
  addBusinessPartner,
  isAddBusinessPartnerModal,
  setAddBusinessPartnerModal,
  isEditBusinessPartnerModal, 
  setEditBusinessPartnerModal,
  businessPartners,
  currentBusinessPartner,
  setCurrentBusinessPartner,
  editBusinessPartner,
  handleSearchInputChange,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  count,
}) => {
  return (
    <>
      <AddBusinessPartnerModal
        isAddBusinessPartnerModal={isAddBusinessPartnerModal}
        setAddBusinessPartnerModal={setAddBusinessPartnerModal}
        addBusinessPartner={addBusinessPartner}
      />
      <EditBusinessPartnerModal 
      isEditBusinessPartnerModal={isEditBusinessPartnerModal}
      setEditBusinessPartnerModal={setEditBusinessPartnerModal}
      editBusinessPartner={editBusinessPartner}
      currentBusinessPartner={currentBusinessPartner}
      />
      <DebtorsDbContainer>
        <FiltersContainer>
          <TextField
            id="outlined-basic"
            label="Wyszukaj"
            onChange={(event) => handleSearchInputChange(event.target.value)}
          />
        </FiltersContainer>
        <DebtorsList>
          <PageSubTitle>Baza Kontrahentów</PageSubTitle>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <HeadTableCell>Nazwa</HeadTableCell>
                  <HeadTableCell align="right">NIP</HeadTableCell>
                  <HeadTableCell align="right">REGON</HeadTableCell>
                  <HeadTableCell align="right">KRS</HeadTableCell>
                  <HeadTableCell align="right">PESEL</HeadTableCell>
                  <HeadTableCell align="right">Adres</HeadTableCell>
                  <HeadTableCell align="right">Konto Bankowe</HeadTableCell>
                  <HeadTableCell align="right">
                    <AddDebtBtn
                      startIcon={<AddIcon />}
                      onClick={() => setAddBusinessPartnerModal(true)}
                    >
                      Dodaj
                    </AddDebtBtn>
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {businessPartners.length > 0 &&
                  businessPartners?.map((businessPartner) => {
                    //   const defaultAddress = debt.addresses.find(
                    //     (address) => address.defaultAddress
                    //   );
                    //   const defaultPhone = debt.phones.find(
                    //     (phone) => phone.defaultPhoneNumber
                    //   );
                    //   const defaultBankAccount = debt.bankAccounts.find(
                    //     (bankAccount) => bankAccount.defaultBankAccount
                    //   );
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {businessPartner.name}
                        </TableCell>
                        <TableCell align="right">{businessPartner.NIP}</TableCell>
                        <TableCell align="right">{businessPartner.REGON}</TableCell>
                        <TableCell align="right">{businessPartner.KRS}</TableCell>
                        <TableCell align="right">{businessPartner.PESEL}</TableCell>
                        <TableCell align="right">{`${businessPartner?.address?.street}, ${businessPartner?.address?.city}, ${businessPartner?.address?.postalCode}`}</TableCell>
                        <TableCell align="right">{businessPartner.accountNumber}</TableCell>
                        <TableCell align="right">
                          <IconButton>
                            <StyledEditIcon 
                             onClick={() => {
                                setCurrentBusinessPartner(businessPartner._id);
                                setEditBusinessPartnerModal(true)
                            }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </DebtorsList>
      </DebtorsDbContainer>
    </>
  );
};

export default BusinessPartnersView;

const AddDebtBtn = styled(Button)`
  /* position: absolute;
    right: 20px;
    top: 20px; */
  /* margin-top: 10px; */
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;

const HeadTableCell = styled(TableCell)`
  font-weight: 600;
`;
const PageSubTitle = styled.h2`
  margin: 0;
  margin-bottom: 20px;
`;

const DebtorsDbContainer = styled(Paper)`
    width: calc(100vw - 105px);
    height: calc(100vh - 40px);
    margin: 20px auto;
    margin-left: 85px;
`;

const FiltersContainer = styled.div`
  padding: 20px;
`;

const DebtorsList = styled.div`
  padding: 20px;
  position: relative;
`;

const StyledEditIcon = styled(EditOutlinedIcon)`
  color: #4c5bac;
`;
