import React, { useEffect, useState } from 'react';
import SinglePaymentView from './SinglePaymentView';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDebtsWithDebtor } from '../../redux/modules/debts';
import {
  editPayment,
  getPaymentByIdWithDebtor,
} from '../../redux/modules/payments';

const SingleDebtContainer = ({
  currentPayment,
  getPaymentByIdWithDebtor,
  editPayment,
  debts,
  getDebtsWithDebtor,
  match: {
    params: { id },
  },
}) => {
  const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay], // Only re-call effect if value or delay changes
    );

    return debouncedValue;
  };

  const [debtInputValue, setDebtInputValue] = useState('');
  const debouncedInputValue = useDebounce(debtInputValue, 200);
  const [warningModal, setWarningModal] = useState({
    shown: false,
    question: null,
    onAccept: null,
    questionTitle: null,
  });
  useEffect(
    () => {
      getDebtsWithDebtor(1, 20, debouncedInputValue);
    },
    [debouncedInputValue], // Only call effect if debounced search term changes
  );
  const [isEditPaymentModal, setEditPaymentModal] = useState(false);

  useEffect(() => {
    getPaymentByIdWithDebtor(id);
    getDebtsWithDebtor(1, 20, '');
  }, []);

  return (
    <SinglePaymentView
      currentPayment={currentPayment}
      isEditPaymentModal={isEditPaymentModal}
      setEditPaymentModal={setEditPaymentModal}
      editPayment={editPayment}
      setDebtInputValue={setDebtInputValue}
      useDebounce={useDebounce}
      debts={debts}
      getDebtsWithDebtor={getDebtsWithDebtor}
      warningModal={warningModal}
      setWarningModal={setWarningModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currentPayment: state.payments.current,
  debts: state.debts.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    editPayment: (paymentBody, paymentID) =>
      dispatch(editPayment(paymentBody, paymentID)),
    getPaymentByIdWithDebtor: (paymentId) =>
      dispatch(getPaymentByIdWithDebtor(paymentId)),
    getDebtsWithDebtor: (pageNumber, pageSize, searchTerm) =>
      dispatch(getDebtsWithDebtor(pageNumber, pageSize, searchTerm)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleDebtContainer),
);
