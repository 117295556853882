import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styled, { css } from 'styled-components';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ExposureIcon from '@material-ui/icons/Exposure';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ImportExportIcon from '@material-ui/icons/SwapVerticalCircle';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import GavelIcon from '@material-ui/icons/Gavel';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const DashboardServiceCard = ({ title, subtitle, link }) => {
  console.log('to jest link:', link);
  return (
    <ServiceCardContainer>
      <div>
        <CardTitle>{title}</CardTitle>
        <CardSubTitle>{subtitle}</CardSubTitle>
      </div>
      <div>
        <IconContainer>
          {link === '/debtors-db' ? (
            <StyledPeopleIcon />
          ) : link === '/documents' ? (
            <StyledDocumentIcon />
          ) : link === '/debt-wallet-import' ? (
            <>
              <StyledWalletIcon />
              <SmallImportIcon />
            </>
          ) : link === '/add-document' ? (
            <>
              <StyledPersonalDocument />
              <SmallAddIcon />
            </>
          ) : link === '/debts' ? (
            <StyledExposureIcon />
          ) : link === '/business-partners' ? (
            <StyledBusinessIcon />
          ) : link === '/add-debt' ? (
            <>
              <StyledExposureIcon />
              <SmallAddIcon />
            </>
          ) : link === '/add-wallet' ? (
            <>
              <StyledWalletIcon />
              <SmallAddIcon />
            </>
          ) : link === '/payments' ? (
            <StyledPaymentIcon />
          ) : link === '/add-payment' ? (
            <>
              <StyledPaymentIcon />
              <SmallAddIcon />
            </>
          ) : link === '/payments-import' ? (
            <>
              <StyledPaymentOutlinedIcon />
              <SmallImportIcon />
            </>
          ) : link === '/wallets' ? (
            <StyledWalletIcon />
          ) : link === '/lawsuits' ? (
            <StyledGavelIcon />
          ) : link === '/bailiff-assign' ? (
            <>
              <StyledAccountBoxIcon />
              <SmallAddIcon />
            </>
          ) : link === '/documents-status' ? (
            <StyledMarkunreadMailboxOutlinedIcon />
          ) : null}
        </IconContainer>
        <IconContainer>
          <LinkButton component={Link} to={link} variant="contained">
            Przejdź
          </LinkButton>
        </IconContainer>
      </div>
    </ServiceCardContainer>
  );
};

export default DashboardServiceCard;

const LinkButton = styled(Button)`
  margin-top: 10px;
  color: white;
  background-color: #4c5bac;
  &:hover {
    background-color: #030b56;
  }
`;

const ServiceCardContainer = styled(Paper)`
  margin: 20px;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
`;

const CardTitle = styled.h2`
  font-size: 22px;
  /* margin-left: 20px; */
  /* text-align: center; */
  margin: 0;
  margin-bottom: 5px;
`;

const CardSubTitle = styled.h3`
  /* text-align: center; */
  font-size: 16px;
  color: #898989;
  margin: 0;
  /* margin-left: 20px; */
`;

const iconsStyles = css`
  color: #949dcd;
  width: 80px;
  height: 80px;
  opacity: 1;
`;

const SmallImportIcon = styled(ImportExportIcon)`
  color: #949dcd;
  width: 30px;
  height: 30px;
  opacity: 1;
  position: absolute;
  right: 57px;
  top: 55px;
`;

const SmallAddIcon = styled(AddCircleOutlinedIcon)`
  color: #949dcd;
  width: 30px;
  height: 30px;
  opacity: 1;
  position: absolute;
  right: 57px;
  top: 55px;
`;

const StyledPaymentIcon = styled(PaymentIcon)`
  ${iconsStyles}
`;
const StyledBusinessIcon = styled(BusinessOutlinedIcon)`
  ${iconsStyles};
`;

const StyledPersonalDocument = styled(DescriptionOutlinedIcon)`
  ${iconsStyles};
`;

const StyledWalletIcon = styled(AccountBalanceWalletOutlinedIcon)`
  ${iconsStyles};
`;

const StyledPeopleIcon = styled(PeopleAltOutlinedIcon)`
  ${iconsStyles};
`;

const StyledDocumentIcon = styled(LibraryBooksIcon)`
  ${iconsStyles};
`;

const StyledExposureIcon = styled(ExposureIcon)`
  ${iconsStyles};
`;
const StyledAddBoxIcon = styled(AddBoxIcon)`
  ${iconsStyles};
`;
const StyledPostAddOutlinedIcon = styled(PostAddOutlinedIcon)`
  ${iconsStyles};
`;
const StyledPaymentOutlinedIcon = styled(PaymentOutlinedIcon)`
  ${iconsStyles}
`;
const StyledFeaturedPlayListOutlinedIcon = styled(FeaturedPlayListOutlinedIcon)`
  ${iconsStyles};
`;
const StyledGavelIcon = styled(GavelIcon)`
  ${iconsStyles};
`;
const StyledAccountBoxIcon = styled(AccountBoxIcon)`
  ${iconsStyles};
`;
const StyledMarkunreadMailboxOutlinedIcon = styled(
  MarkunreadMailboxOutlinedIcon,
)`
  ${iconsStyles};
`;

const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;
