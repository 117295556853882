import React, { useEffect, useState } from 'react';
import AddDocumentTypeView from './AddDocumentTypeView';
import { connect } from 'react-redux';
import {
  addDocumentType,
  editDocumentType,
  deleteDocumentType,
  getDocumentTypes,
} from '../../redux/modules/documentTypes';

const AddDocumentTypeContainer = ({
  addDocumentType,
  deleteDocumentType,
  location,
  match: {
    params: { id },
  },
  documents,
  editDocumentType,
  getDocumentTypes,
}) => {
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [isEdited, setEdited] = useState(false);

  useEffect(() => {
    if (!documents || documents.length === 0) {
      getDocumentTypes();
    } else {
      if (location.pathname.includes('new')) {
        setEdited(false);
      } else {
        const editedDoc = documents.find((doc) => doc._id === id);
        setEdited(true);
        setName(editedDoc.name);
        setContent(editedDoc.content);
      }
    }
  }, [documents]);
  return (
    <AddDocumentTypeView
      name={name}
      setName={setName}
      addDocumentType={addDocumentType}
      content={content}
      setContent={setContent}
      location={location}
      isEdited={isEdited}
      editedDocument={documents.find((doc) => doc?._id === id)}
      editDocumentType={editDocumentType}
      documentID={id}
      deleteDocumentType={deleteDocumentType}
    />
  );
};

const mapStateToProps = (state) => ({
  documents: state.documentTypes.data,
});

const mapDispatchToProps = (dispatch) => ({
  getDocumentTypes: () => dispatch(getDocumentTypes()),
  addDocumentType: (documentType) => dispatch(addDocumentType(documentType)),
  editDocumentType: (documentType, documentId) =>
    dispatch(editDocumentType(documentType, documentId)),
  deleteDocumentType: (documentId) => dispatch(deleteDocumentType(documentId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddDocumentTypeContainer);
